import {
  AddAppointmentDialogData,
  AddAppointmentsDialogComponent,
} from '../_dialogs/add-appointments-dialog/add-appointments-dialog.component';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from '../confirm-dialog/confirm-dialog.component';

import { ApiService } from '../api.service';
import { AuthenticationService } from '../_services';
import { ImportAppointmentsDialogComponent } from '../_dialogs/import-appointments-dialog/import-appointments-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import PermissionSlugs from '../Constants/PermissionSlugs';
import { PermissionSlugsService } from '../Services/PermissionSlugsService';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { TawkToService } from '../_services/TawkToService';
import { User } from '../_models';
import { defineFullCalendarElement } from '@fullcalendar/web-component';

const v2Class = 'citamed-v2-cyan',
  v1Class = 'citamed-v1-green';
export const RUN_TUTORIAL_SESSION_KEY = 'RunTutorialOnLoad';

defineFullCalendarElement();
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app-component.scss'],
})
export class AppComponent implements AfterViewInit {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private tawkToService: TawkToService,
    private swUpdate: SwUpdate,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private permissionSlugsService: PermissionSlugsService,
    private API: ApiService
  ) {
    if (window.sessionStorage['hideSpecialBanner']) {
      this.showBanner = false;
    }
    this.screenWidth = window.innerWidth;

    window.addEventListener('resize', () => {
      this.screenWidth = window.innerWidth;
    });
  }
  public currentUser: User;
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;
  public showDWL = false;
  public hideAppointmentManagement = false;
  public showVirtualAssistant = false;
  public showCampaigns = false;
  public showTransitionalCare = false;
  public patientManagement = false;
  public isPrinter = false;
  public showBanner = true;
  public hasBookingPermission = false;
  public isCitamedV2 = false;
  public screenWidth: number;
  isExpanded = false;
  public menuExpanded: boolean = false;
  public isV2ToggleBannerVisible = true;
  public showV2Banner: boolean = false;
  public isSwitchingThemes: boolean = false;

  private getIsV2Enabled = () => {
    const v2FlagInStorage =
      localStorage.getItem('v2flag') === 'true' ? true : false;
    return v2FlagInStorage;
  };

  private setBodyClass = (isV2: boolean) => {
    if (isV2) {
      document.body.classList.add(v2Class);
      document.body.classList.remove(v1Class);
    } else {
      document.body.classList.remove(v2Class);
      document.body.classList.add(v1Class);
    }
  };
  public setV2Theme() {
    const v2Flag = !this.getIsV2Enabled();
    localStorage.setItem('v2flag', String(v2Flag));
    this.isSwitchingThemes = true;
    if (v2Flag) window.location.assign('?showNewVersionBanner=true');
    else window.location.assign('/');
  }

  openLink(link) {
    window.open(link, '_blank');
  }
  ngAfterViewInit() {
    this.isCitamedV2 = this.getIsV2Enabled();

    this.setBodyClass(this.isCitamedV2);

    this.authenticationService.currentUser.subscribe((user) => {
      const widget = (window as any)?.fcWidget;
      if (widget && widget.setExternalId) {
        widget.setExternalId(user.userId);
        if (!widget.user) return;
        widget.user.setFirstName(user.username);
        widget.user.setEmail(user.emailAddress);
        widget.user.setLastName(user.clinicId + ' ' + user.clinicName);
        widget.user.setProperties({
          clinicName: `${user.clinicId} ${user.clinicName}`,
        });
      }
    });
    if (window.location.href.includes('showNewVersionBanner')) {
      this.showV2Banner = true;
      setTimeout(() => {
        window.location.assign(window.location.origin);
        window.sessionStorage.setItem(RUN_TUTORIAL_SESSION_KEY, String(true));
      }, 7 * 800);
      return;
    } else {
      setTimeout(() => {
        document
          .getElementsByClassName('v2-disabled')[0]
          .classList?.add('displayed');
        this.dialog
          .open(ConfirmDialogComponent, {
            maxWidth: '600px',
            data: new ConfirmDialogModel(
              'Prueba la nueva versión de citamed',
              '¡Citamed tiene una nueva imagen! más amigable y fácil de usar, adaptable a laptops, tablets y móviles.',
              'Probar la nueva versión',
              'No',
              null,
              null
            ),
          })
          .afterClosed()
          .subscribe((confirmado: Boolean) => {
            if (confirmado) {
              this.setV2Theme();
            }
          });
      }, 5 * 1000);
    }
    this.authenticationService.currentUser.subscribe((currentUserValue) => {
      if (!currentUserValue?.clinicId) {
        this.currentUser = null;
        return;
      }
      this.currentUser = currentUserValue;
      if (this.currentUser?.userId) {
        this.permissionSlugsService.fetch();
      }
      this.tawkToService.StartChat(this.currentUser);
      if (this.currentUser.isAdmin) {
        this.isPrinter = this.currentUser.isAdmin;
      } else {
        this.API.IsUserPrinter(this.currentUser.clinicId).subscribe(
          () => {
            this.isPrinter = true;
          },
          (error) => {
            console.error(error);
          }
        );
      }
    });
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        if (
          confirm(
            'Hay una nueva actualizacion. Acepte para actualizar a citamed'
          )
        ) {
          window.location.reload();
        }
      });
    }

    this.permissionSlugsService.permissionsListener.subscribe((r) => {
      this.showDWL = r.includes(PermissionSlugs.DIGITAL_WAITING_LIST);
      this.hideAppointmentManagement = r.includes(
        PermissionSlugs.BLOCK_APPOINTMENT_MANAGEMENT
      );
      this.showVirtualAssistant = r.includes(PermissionSlugs.VIRTUAL_ASSISTANT);
      this.showCampaigns = r.includes(PermissionSlugs.CAMPAIGNS);
      this.showTransitionalCare = r.includes(PermissionSlugs.TRANSITIONAL_CARE);
      this.patientManagement = r.includes(PermissionSlugs.PATIENT_MANAGEMENT);
      this.hasBookingPermission = r.includes(PermissionSlugs.BOOKINGS);
    });

    // Transitional Care Management Users
    if (
      this.hideAppointmentManagement === true &&
      this.showTransitionalCare === true
    ) {
      this.router.navigate(['/Transitional-Care-admisions-and-discharges']);
    } else if (
      this.hideAppointmentManagement === true &&
      this.showCampaigns === true
    ) {
      this.router.navigate(['/campañas/crear']);
    } else if (
      this.hideAppointmentManagement === true &&
      this.showVirtualAssistant === true &&
      this.showTransitionalCare === false
    ) {
      this.router.navigate(['/llamadasPerdidas']);
    }
  }
  navigateTo(route: string) {
    this.router.navigate([route]);
  }
  public hidebanner() {
    window.sessionStorage['hideSpecialBanner'] = true;
    this.showBanner = false;
  }

  installPwa(): void {}
  OpenAppointmentAddDialog() {
    const data: AddAppointmentDialogData = {
      clinicID: this.currentUser.clinicId,
      UserId: this.currentUser.userId,
      ClinicName: this.currentUser.clinicName,
    };
    this.dialog
      .open(AddAppointmentsDialogComponent, {
        data,
        width: '35%',
        disableClose: true,
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        const result = dialogResult as boolean;
        if (result) {
          this._snackBar.open(
            '¡Listo! La cita ha sido añadida correctamente.',
            'ok ',
            {
              duration: 8000,
              horizontalPosition: 'left',
              verticalPosition: 'bottom',
              panelClass: 'success-dialog',
            }
          );
        }
      });
  }
  logout() {
    this.authenticationService.logout();
    this.tawkToService.endChat();
    this.router.navigate(['/login']);
  }
  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
  OpenImportAppointmentDialog() {
    this.dialog
      .open(ImportAppointmentsDialogComponent, {
        width: '50%',
        disableClose: false,
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        const result = dialogResult as boolean;
        if (result) {
          this._snackBar.open(
            '¡Listo! Las citas han sido importadas correctamente.',
            'ok ',
            {
              duration: 8000,
              horizontalPosition: 'left',
              verticalPosition: 'bottom',
              panelClass: 'success-dialog',
            }
          );
        }
      });
  }

  onSidenavClose() {
    this.sidenav.toggle();
  }
  SidenavClose() {
    if (this.screenWidth < 840) this.sidenav.close();
  }
}
