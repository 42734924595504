export class EHRGetDTO {
  electronicCalendarName: string;
  electronicCalendarId: number;
  integrationType?: any;
  manufacturer?: any;
  twoWayIntegration: boolean;
  createDate: Date;
  description?: any;
}

export interface ElectronicCalendar {
  electronicCalendarId: number;
  electronicCalendarName: string;
  description: string;
  manufacturer: string;
  createDate: string | null;
  twoWayIntegration: boolean | null;
  integrationType: string;
  printerEhr?: number;
}

export interface EHRList {
  totalCount: number;
  pageSize: number;
  pageIndex: number;
  items: ElectronicCalendar[];
}

export class AddElectronicCalendar {
  electronicCalendarId: number;
  electronicCalendarName: string;
  description: string;
  manufacturer: string;
  createDate: string | null;
  twoWayIntegration: boolean | null;
  integrationType: string;
  printerEhr?: number;
}
