import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppRoutesInfo, appRoutesNames } from 'src/app/app.routes';
import { Component, OnInit } from '@angular/core';
import { filter, map, startWith } from 'rxjs/operators';
import { TawkToService } from 'src/app/_services/TawkToService';

import { AuthenticationService } from 'src/app/_services';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { User } from 'src/app/_models';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
})
export class NavigationBarComponent implements OnInit {
  constructor(
    public router: Router,
    private location: Location,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private titleService: Title,
    private tawkToService: TawkToService
  ) {}
  public User: User;
  public routeInfo: AppRoutesInfo;
  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe((u) => {
      this.User = u;
    });

    this.router.events
      .pipe(
        // identify navigation end
        filter((event) => event instanceof NavigationEnd),
        startWith(this.router),
        // now query the activated route
        map(() => this.rootRoute(this.route)),
        filter((route: ActivatedRoute) => route.outlet === 'primary')
      )
      .subscribe((route: ActivatedRoute) => {
        this.setRouteInfoFromPath(route.snapshot.routeConfig.path);
      });
  }

  private rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  public goBack() {
    this.location.back();
  }
  logout() {
    this.authenticationService.logout();
    this.tawkToService.endChat();
    this.router.navigate(['/login']);
  }
  private setRouteInfoFromPath(path: string) {
    const info = appRoutesNames.find((r) => r.path === path);
    this.routeInfo = info;
    if (info.routeName)
      this.titleService.setTitle(info.routeName + ' - Citamed');
    else {
      this.titleService.setTitle('Citamed');
    }
  }
}
