export class ReminderScheduleGet {
  checked: boolean;
  clinicId: number;
  reminderScheduleId: number;
  reminderSetId: number;
  doctorId: number;
  reminderSetName: string;
  doctorName: string;
  callTypeId: number;
  callTypeName: string;
  callTimingSetId: number;
  callTimingSetName: string;
  description: string;
  callAttempts: number;
  daysAhead: number;
  sqlWeekDay: number;
  callTimingSprocId: number;
  callTimingSprocDescription?: string;
  forAppointmentOn: string;
  callOn: string;
  _isBeingEdited: boolean;
  deleteDate: Date;
}
export class ReminderScheduleGet_GroupByDoctor {
  doctorId: number;
  doctorName: string;
  reminder_schedules: ReminderScheduleGet[];
}
