import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { ApiService } from 'src/app/api.service';
import { CalltimingSet } from 'src/app/DTOS/CalltimingSet/CallTimingSetGetDTO';
import { User } from '../../_models/user';
import { AuthenticationService } from '../../_services/authentication.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from '../../confirm-dialog/confirm-dialog.component';
import { AdminViewAsClinicDTO } from '../../dtos/AdminViewAsClinicDTO';
import { SqlWeekDay } from '../../DTOS/CallTiming/CallTiming';
import { CallType } from '../../DTOS/CallType/CallType';
import { Doctor } from '../../dtos/Doctors/DoctorDTO';
import {
  ReminderScheduleGet,
  ReminderScheduleGet_GroupByDoctor,
} from '../../DTOS/ReminderSchedule/ReminderScheduleGet';
import { ReminderSetDto } from '../../dtos/ReminderSets/ReminderSetDto';
import {
  LoadingDialogComponent,
  LoadingDialogModel,
} from '../../loading-dialog/loading-dialog.component';

@Component({
  selector: 'app-reminder-schedule',
  templateUrl: './reminder-schedule.component.html',
  styleUrls: ['./reminder-schedule.component.scss'],
})
/** reminder-schedule component*/
export class ReminderScheduleComponent implements AfterViewInit {
  /** Reminders ctor */
  constructor(
    private API: ApiService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog
  ) {}

  public User: User;
  httpClient: HttpClient;
  loadingRef: MatDialogRef<LoadingDialogComponent>;
  public ViewAs: AdminViewAsClinicDTO;
  baseUrl: string;
  isLoading: boolean;
  reminderSchedules: ReminderScheduleGet[] = [];
  public sortByKey = 'doctorId';
  tableBatchUpdateFiller: ReminderScheduleGet = new ReminderScheduleGet();
  reminderSchedules_doctors: ReminderScheduleGet_GroupByDoctor[];
  doctors: Doctor[];
  callTypes: CallType[];
  public attempts: [1, 2, 3];
  ReminderSets: ReminderSetDto[];
  table_view = true;
  callTimingSets: CalltimingSet[];

  weekDays: SqlWeekDay[] = [
    { Day: 'Sunday', DayValue: 1 },
    { Day: 'Monday', DayValue: 2 },
    { Day: 'Tuesday', DayValue: 3 },
    { Day: 'Wednesday', DayValue: 4 },
    { Day: 'Thursday', DayValue: 5 },
    { Day: 'Friday', DayValue: 6 },
    { Day: 'Saturday', DayValue: 7 },
  ];

  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
    this.LoadAll();
  }

  LoadAll() {
    this.FetchReminderSets();
    this.LoadReminderSchedules();
    this.FetchDoctors();
    this.LoadCallTimings();
  }
  public LoadCallTimings() {
    const ClinicID = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    this.API.GetAllCalltimings(ClinicID).subscribe((r: CalltimingSet[]) => {
      this.callTimingSets = r;
    });
  }
  ngAfterViewInit() {
    this.User = this.authenticationService.currentUserValue;
    this.LoadAll();
    this.FetchCallTypes();
  }

  public tConvert(_time) {
    // Check correct time format and split into components
    let time = _time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [_time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  public getCallTimings(callTimingSetId: number) {
    return this.callTimingSets.find(
      (c) => c.callTimingSetId === callTimingSetId
    )?.timings;
  }
  public GetSelectedRemschedules() {
    return this.reminderSchedules.filter((a) => a.checked).length;
  }

  public clearFiller() {
    this.tableBatchUpdateFiller = new ReminderScheduleGet();
  }

  public unselectAll() {
    this.reminderSchedules = this.reminderSchedules.map((r) => ({
      ...r,
      checked: false,
    }));
  }
  public DuplicateSelected() {
    const selecteds = this.reminderSchedules
      .filter((a) => a.checked)
      .map((a) => ({
        ...a,
        checked: true,
        _isBeingEdited: true,
        reminderScheduleId: 0,
      }));

    this.reminderSchedules = [
      ..._.cloneDeep(selecteds),
      ...this.reminderSchedules,
    ];

    this.reminderSchedules.forEach((e) => {
      if (e.checked) {
        e.checked = false;
      }
    });
  }

  public batchUpdate() {
    const val = this.GetSelectedRemschedules();
    const message = `¿Estas seguro de querer guardar estos cambios para ${val} registros?`;

    const dialogData = new ConfirmDialogModel(
      'Confirmación',
      message,
      'Si',
      'Cancelar'
    );

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        const toUpdateRemschedules = this.reminderSchedules.filter(
          (a) => a.checked
        );

        toUpdateRemschedules.forEach((e) => {
          e.doctorId = this.tableBatchUpdateFiller.doctorId || e.doctorId;
          e.callTypeId = this.tableBatchUpdateFiller.callTypeId || e.callTypeId;
          e.reminderSetId =
            this.tableBatchUpdateFiller.reminderSetId || e.reminderSetId;
          e.forAppointmentOn =
            this.tableBatchUpdateFiller.forAppointmentOn || e.forAppointmentOn;
          e.callOn = this.tableBatchUpdateFiller.callOn || e.callOn;

          const onSuccess = () => {};
          const onError = (error) => {
            this.showError(error);
          };

          if (e.reminderScheduleId) {
            this.API.UpdateReminderSchedule(e).subscribe(onSuccess, onError);
          } else {
            this.API.AddReminderSchedule(e).subscribe(onSuccess, onError);
          }
        });
      }
    });
  }

  public DeleteReminderSchedule(reminderScheduleId: number) {
    const message = `¿Estas seguro de querer eliminar el ReminderSchedule #${reminderScheduleId}?`;

    const dialogData = new ConfirmDialogModel(
      'Confirmación',
      message,
      'Si',
      'Cancelar'
    );

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.isLoading = true;

        this.API.DeleteReminderSchedule(reminderScheduleId).subscribe(
          () => {
            this.isLoading = false;
            this.LoadReminderSchedules();
          },
          () => {
            alert('Error');
            this.isLoading = false;
          }
        );
      }
    });
  }

  public DeleteInBatch() {
    const selected = this.GetSelectedRemschedules();
    const message = `¿Estas seguro de querer eliminar ${selected} ReminderSchedules?`;

    const dialogData = new ConfirmDialogModel(
      'Confirmación',
      message,
      'Si',
      'Cancelar'
    );

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        const elements = this.reminderSchedules.filter((e) => e.checked);

        elements.forEach((rem) => {
          this.isLoading = true;
          this.API.DeleteReminderSchedule(rem.reminderScheduleId).subscribe(
            () => {
              this.isLoading = false;
              this.LoadReminderSchedules();
            },
            (error) => {
              console.error(error);
              alert('Error');
              this.isLoading = false;
            }
          );
        });
      }
    });
  }

  public addNewRemscheduleEmpty(group: ReminderScheduleGet_GroupByDoctor) {
    const val = new ReminderScheduleGet();
    val._isBeingEdited = true;
    val.clinicId = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    val.callAttempts = 1;
    group.reminder_schedules.push(val);
  }

  public addNewRemscheduleEmptyToList() {
    const val = new ReminderScheduleGet();
    val._isBeingEdited = true;
    val.clinicId = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    val.callAttempts = 1;
    this.reminderSchedules = [val].concat(this.reminderSchedules);
  }

  public FetchReminderSets() {
    this.isLoading = true;
    const ClinicID = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;

    this.API.GetAllReminderSet(ClinicID).subscribe(
      (result) => {
        const res = result as ReminderSetDto[];
        this.ReminderSets = res;
        this.isLoading = false;
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
      }
    );
  }

  public FetchCallTypes() {
    this.isLoading = true;

    this.API.GetAllCallTypes().subscribe(
      (result) => {
        const res = result as CallType[];
        this.callTypes = res;
        this.isLoading = false;
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
      }
    );
  }

  public FetchDoctors() {
    this.isLoading = true;
    const ClinicID = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;

    this.API.FetchDoctors(ClinicID).subscribe(
      (result) => {
        const res = result as Doctor[];
        this.doctors = res;
        this.isLoading = false;
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
      }
    );
  }

  public LoadReminderSchedules() {
    this.API.GetReminderSchedules(
      this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId
    ).subscribe(
      (result) => {
        const res = result as ReminderScheduleGet[];

        const doctorsIDs = Array.from(new Set(res.map((a) => a.doctorId)));

        const doctors = doctorsIDs.map((doctorIdValue) => {
          return {
            doctorId: doctorIdValue,
            doctorName: res
              .filter((n) => n.doctorId === doctorIdValue)
              .map((n) => n.doctorName)[0],
          };
        });

        this.reminderSchedules_doctors = doctors.map((d) => {
          const mapped: ReminderScheduleGet_GroupByDoctor =
            new ReminderScheduleGet_GroupByDoctor();

          mapped.doctorId = d.doctorId;
          mapped.doctorName = d.doctorName;

          mapped.reminder_schedules = result.filter(
            (a) => a.doctorId === d.doctorId
          );

          return mapped;
        });

        this.reminderSchedules = _.sortBy(res, ['doctorId', 'daysAhead']);
        this.isLoading = false;
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
      }
    );
  }

  public ShowLoading() {
    const loading_dialog = this.dialog.open(LoadingDialogComponent, {
      maxWidth: '400px',
      data: new LoadingDialogModel('Cargando', '', false),
    });
    loading_dialog.disableClose = true;
    this.loadingRef = loading_dialog;
  }

  public hideLoading() {
    if (this.loadingRef) {
      this.loadingRef.close();
    }
  }

  private showError(error: any) {
    console.error('CITAMED ERROR', error);
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: new ConfirmDialogModel(
        'Ha ocurrido un error',
        'No hemos podido cancelar las llamadas.',
        'Okay',
        null
      ),
    });
  }

  public sortby(key: string) {}

  public sortbyStringKey(key: string) {
    this.reminderSchedules = this.reminderSchedules.sort((a, b) =>
      a[key].localeCompare(b[key])
    );
  }

  public saveChanges(rem: ReminderScheduleGet) {
    const message = `¿Estas seguro de querer guardar estos cambios?`;
    const dialogData = new ConfirmDialogModel(
      'Confirmación',
      message,
      'Si',
      'Cancelar'
    );
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        const loading_dialog = this.dialog.open(LoadingDialogComponent, {
          maxWidth: '400px',
          data: new LoadingDialogModel('Cargando', '', false),
        });
        loading_dialog.disableClose = true;

        this.API.UpdateReminderSchedule(rem).subscribe(
          () => {
            loading_dialog.close();
            this.dialog.open(ConfirmDialogComponent, {
              maxWidth: '600px',
              data: new ConfirmDialogModel(
                'Listo',
                'Los cambios han sido salvados.',
                'Okay',
                null
              ),
            });
            rem._isBeingEdited = false;
          },
          (error) => {
            loading_dialog.close();
            this.showError(error);
          }
        );
      }
    });
  }

  public discardChanges() {
    this.LoadReminderSchedules();
  }
}
