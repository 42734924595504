import { AfterViewInit, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationService } from 'primeng/api';
import { User } from '../../_models';
import { AuthenticationService } from '../../_services';
import { ApiService } from '../../api.service';
import PermissionSlugs from '../../Constants/PermissionSlugs';
import { DoctorDetailedDTO, GoogleTokenId } from '../../Dtos/Doctors/DoctorDTO';
import { PermissionSlugsService } from '../../Services/PermissionSlugsService';

export interface DoctorEditDialogData {
  doctor: DoctorDetailedDTO;
  title: String;
}
@Component({
  selector: 'app-doctor-dialog',
  templateUrl: './doctor-dialog.component.html',
  styleUrls: ['./doctor-dialog.component.scss'],
})
export class DoctorDialogComponent implements AfterViewInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DoctorEditDialogData,
    public dialogRef: MatDialogRef<DoctorDialogComponent>,
    private _snackBar: MatSnackBar,
    private API: ApiService,
    private permissionSlugsService: PermissionSlugsService,
    private confirmationService: ConfirmationService,
    private authenticationService: AuthenticationService
  ) {
    if (this.data.doctor) {
      this.setData(this.data.doctor);
    }
    this.permissionSlugsService.fetch();
    this.permissionSlugsService.permissionsListener.subscribe((r) => {
      this.permissions = r;
    });
    this.authenticationService.currentUser.subscribe((u) => (this.User = u));
  }
  User: User;
  public isGoogleCalendar = false;
  public permissions: string[] = [];
  public doctor: DoctorDetailedDTO;
  public _isActive_updt: boolean;
  public googleToken: GoogleTokenId;

  isLoading = false;
  baseUrl: string;

  ngAfterViewInit() {
    this.getGoogleConnection(this.doctor.doctorId);
  }

  public hasGoogleCalendarPermission = () =>
    this.permissions.includes(PermissionSlugs.GOOGLE_CALENDAR);

  public setData(doctor: DoctorDetailedDTO) {
    this.doctor = {
      doctorId: doctor.doctorId,
      clinicId: doctor.clinicId,
      clinic_DoctorId: doctor.clinic_DoctorId,
      doctorSpecialtyId: doctor.doctorSpecialtyId,
      specialtyId: doctor.doctorSpecialtyId,
      clinicName: doctor.clinicName,
      doctorName: doctor.doctorName,
      doctorInternalIdentifier: doctor.doctorInternalIdentifier,
      specialtyName: doctor.specialtyName,
    } as DoctorDetailedDTO;
  }

  public close() {
    this.dialogRef.close();
  }

  public onDismiss() {
    this.dialogRef.close(false);
  }

  public async Save() {
    const confirm = window.confirm(
      `¿Estás segur@ de querer cambiar el Identificador Interno?`
    );
    if (!confirm) {
      return;
    }
    this.upadatedoctor(this.doctor);
  }

  public onConnectGoogleCalendarButtonClicked = () => {
    if (!this.googleToken) {
      window
        .open(
          `/api/GoogleConnection/Connect?doctorId=${this.doctor.doctorId}`,
          '_blank'
        )
        .focus();
    } else {
      this.confirmationService.confirm({
        target: event.target,
        message: '¿Estás segur@ de querer desconectar tu calendario de google?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          // Trigger request to the backend
        },
        reject: () => {
          this.confirmationService.close();
        },
      });
    }
  };
  private async upadatedoctor(_doctor: DoctorDetailedDTO) {
    const route = this.baseUrl + `Doctors/UpdateDoctor`;
    const doctor = {
      clinicId: _doctor.clinicId,
      clinicDoctorId: _doctor.clinic_DoctorId,
      doctorId: _doctor.doctorId,
      specialtyId: _doctor.specialtyId,
      doctorName: _doctor.doctorName,
      doctorInternalIdentifier: _doctor.doctorInternalIdentifier,
    };

    this.API.UpdateDoctor({
      ClinicId: _doctor.clinicId,
      ClinicDoctorId: _doctor.clinic_DoctorId,
      doctorId: _doctor.doctorId,
      specialtyId: _doctor.specialtyId,
      doctorName: _doctor.doctorName,
      doctorInternalIdentifier: _doctor.doctorInternalIdentifier,
    }).subscribe(
      () => {
        this.isLoading = false;
        this.dialogRef.close(true);
        this._snackBar.open(
          '¡Listo!, se ha actualizado el Identificador Interno.',
          'OK ',
          {
            duration: 10000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: 'success-dialog',
          }
        );
      },
      (f) => {
        this.isLoading = false;
        window.alert('Lo sentimos, ha ocurrido un error.');
        console.error('ERROR AL actualizar Identificador Interno', f);
      }
    );
  }

  public getGoogleConnection(doctorID: number) {
    this.API.GetGoogleConnection(doctorID).subscribe((r) => {
      this.googleToken = r;
    });
  }
}
