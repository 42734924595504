import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { User } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services';
import { ApiService } from 'src/app/api.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from 'src/app/confirm-dialog/confirm-dialog.component';
import { AdminViewAsClinicDTO } from 'src/app/dtos/AdminViewAsClinicDTO';
import {
  ApiUserDTO,
  IApiUser,
  IApiUserList,
  IApiUsersClinic,
} from 'src/app/DTOS/ApiUsers/ApiUsersDTO';
import {
  LoadingDialogComponent,
  LoadingDialogModel,
} from 'src/app/loading-dialog/loading-dialog.component';

@Component({
  selector: 'app-api-users-list',
  templateUrl: './api-users-list.component.html',
  styleUrls: ['./api-users-list.component.scss'],
})
export class ApiUsersListComponent implements OnInit {
  isLoading = false;
  @Input() public isStandalone: boolean;
  @Input() public ViewAs: AdminViewAsClinicDTO;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public User: User;
  searchTerm = '';
  dataSource: MatTableDataSource<IApiUser>;
  public pageSize = 10;
  public currentPage = 1;
  public totalSize = 0;
  public clinicId: number;
  public apiUserClinics: IApiUsersClinic[] = [];
  loadingRef: MatDialogRef<LoadingDialogComponent>;
  ApiUserList: IApiUserList;
  listItems: IApiUser[];
  displayedColumns: string[] = [
    'apiUserId',
    'userLoginName',
    'isActive',
    'associate',
    'clinics',
    'createDate',
    'Edit',
  ];
  slidePanelOpen: Boolean = false;
  slideEditPanelOpen: Boolean = false;
  sidepanelUserDetails: ApiUserDTO = {} as any;
  public openAddNewSidePanel() {
    this.sidepanelUserDetails = {} as ApiUserDTO;
    this.slidePanelOpen = true;
    this.apiUserClinics = [] as IApiUsersClinic[];
  }
  public openAddNewEditSidePanel(user: IApiUser) {
    this.slidePanelOpen = true;
    this.sidepanelUserDetails = this.mapUserApiEdit(user);
  }
  mapUserApiEdit(user: IApiUser) {
    this.apiUserClinics = [] as IApiUsersClinic[];
    let UserDetails = {} as ApiUserDTO;
    let { associate, clinics } = user;
    UserDetails.apiUserId = user.apiUserId;
    UserDetails.isActive = user.isActive;
    UserDetails.userLoginName = user.userLoginName;
    UserDetails.associateId = associate.associateId;
    UserDetails.password = user.password;
    UserDetails.repeatPassword = user.password;
    UserDetails.clinicsIds = [];
    clinics.forEach((element) => {
      let clinics = {
        clinicId: element.clinicId,
        clinicName: element.clinicName,
      };
      UserDetails.clinicsIds.push(element.clinicId);
      this.apiUserClinics.push(clinics);
    });
    return UserDetails;
  }

  public closeAddNewSidePanel() {
    this.slidePanelOpen = false;
    this.sidepanelUserDetails = {} as any;
    this.LoadApiUsers();
  }

  constructor(
    private authenticationService: AuthenticationService,
    private API: ApiService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe((user) => {
      this.User = user;
    });
    this.LoadApiUsers();
  }

  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
    this.LoadApiUsers();
  }

  public LoadApiUsers() {
    const clinicID = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    this.clinicId = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    this.currentPage = this.currentPage === 0 ? 1 : this.currentPage;
    this.API.GetApiUsers(
      this.currentPage,
      this.pageSize,
      clinicID,
      this.searchTerm
    ).subscribe(
      (r) => {
        this.dataSource = new MatTableDataSource<IApiUser>(r.items);
        this.ApiUserList = r;
        this.dataSource.paginator = this.paginator;
        this.totalSize = this.ApiUserList.totalCount;
        this.pageSize = this.ApiUserList.pageSize;
        this.currentPage = this.ApiUserList.pageIndex;
        this.listItems = this.ApiUserList.items;
        this.isLoading = false;
      },
      (error) => {
        console.error(error);
        this.hideLoading();
        this.showError(error);
      }
    );
  }

  public ShowLoading() {
    const loading_dialog = this.dialog.open(LoadingDialogComponent, {
      maxWidth: '400px',
      data: new LoadingDialogModel('Cargando', '', false),
    });
    loading_dialog.disableClose = true;
    this.loadingRef = loading_dialog;
  }

  public hideLoading() {
    if (this.loadingRef) {
      this.loadingRef.close();
    }
  }

  private showError(error: any) {
    console.error('CITAMED ERROR', error);
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: new ConfirmDialogModel('Ha ocurrido un error', '', 'Okay', null),
    });
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex + 1;
    this.pageSize = e.pageSize;
    this.LoadApiUsers();
  }

  public formatDate(date: Date) {
    if (date) {
      moment.locale('en');
      return moment(date).format('L');
    }
  }
}
