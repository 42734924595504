import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { User } from '../../_models/user';
import { AuthenticationService } from '../../_services/authentication.service';
import { ApiService } from '../../api.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from '../../confirm-dialog/confirm-dialog.component';
import { CallTimingSprocDTO } from '../../DTOS/CallTimingSproc/CallTimingSproc';
import {
  LoadingDialogComponent,
  LoadingDialogModel,
} from '../../loading-dialog/loading-dialog.component';
import { PermissionSlugsService } from '../../Services/PermissionSlugsService';

@Component({
  selector: 'app-call-timing-sprocs',
  templateUrl: './call-timing-sprocs.component.html',
  styleUrls: ['./call-timing-sprocs.component.scss'],
})
export class CallTimingSprocsComponent implements AfterViewInit {
  constructor(
    http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private permissionSlugsService: PermissionSlugsService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private API: ApiService
  ) {
    this.httpClient = http;
    this.baseUrl = baseUrl;
    this.permissionSlugsService.fetch();
    this.permissionSlugsService.permissionsListener.subscribe((r) => {
      this.permissions = r;
    });
    this.authenticationService.currentUser.subscribe((u) => (this.User = u));
  }

  public permissions: string[] = [];
  httpClient: HttpClient;
  baseUrl: string;
  public User: User;
  displayedColumns: string[] = [
    'callTimingSprocid',
    'description',
    'createDate',
    'ehr',
    'Details',
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource: MatTableDataSource<CallTimingSprocDTO>;
  loadingRef: MatDialogRef<LoadingDialogComponent>;
  @Input() public isStandalone: boolean;

  ngAfterViewInit() {
    this.User = this.authenticationService.currentUserValue;
    this.LoadSprocs();
  }

  public LoadSprocs() {
    if (this.isStandalone) {
      return;
    }
    const userId = this.User.userId;
    this.ShowLoading();
    this.API.GetCallTimingSprocs().subscribe(
      (r) => {
        const res = r as CallTimingSprocDTO[];
        this.dataSource = new MatTableDataSource<CallTimingSprocDTO>(res);
        this.dataSource.paginator = this.paginator;
        this.hideLoading();
      },
      (error) => {
        console.error(error);
        this.hideLoading();
        this.showError(error);
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public ShowLoading() {
    const loading_dialog = this.dialog.open(LoadingDialogComponent, {
      maxWidth: '400px',
      data: new LoadingDialogModel('Cargando', '', false),
    });
    loading_dialog.disableClose = true;
    this.loadingRef = loading_dialog;
  }

  public hideLoading() {
    if (this.loadingRef) {
      this.loadingRef.close();
    }
  }
  private showError(error: any) {
    console.error('CITAMED ERROR', error);
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: new ConfirmDialogModel('Ha ocurrido un error', '', 'Okay', null),
    });
  }
}
