import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  EventEmitter,
  Inject,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import {
  ReportsViewerComponent,
  reportsViewerData,
} from 'src/app/_dialogs/reports-viewer/reports-viewer.component';
import { AppointmentType } from 'src/app/DTOS/Appointments-types/appointmentType';
import { getTcmReportName } from 'src/app/Helpers/Reports';
import { User } from '../../_models';
import { AuthenticationService } from '../../_services';
import { ApiService } from '../../api.service';
import { AdminViewAsClinicDTO } from '../../dtos/AdminViewAsClinicDTO';
import {
  TransitionalCareAppointments,
  TransitionalCareRecordsResultDTO,
} from '../../DTOS/TransitionalCareAdmisionsAndDischarges/TransitionalCareAdmisionsAndDischarges';
import { Subscription } from 'rxjs';

const DEFAULT_PAGE = 0,
  DEFAULT_PAGE_SIZE = 10;
@Component({
  selector: 'app-transitional-care-table',
  templateUrl: './transitional-care-table.html',
  styleUrls: ['./transitional-care-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class TransitionalCareTableComponent implements AfterViewInit {
  suscription: Subscription;
  constructor(
    private authenticationService: AuthenticationService,
    private API: ApiService,
    public dialog: MatDialog,
    @Inject('BASE_URL') baseUrl: string
  ) {
    this.baseUrl = baseUrl;
  }
  @Input() public ViewAs: AdminViewAsClinicDTO;
  @Input() public appointmentTypeId: number;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onImportClicked = new EventEmitter();
  @Input() public title: string;
  baseUrl: string;
  public pageSize = DEFAULT_PAGE_SIZE;
  public page = DEFAULT_PAGE;
  public totalSize = 0;
  public User: User;
  // public AppointmentType: number;
  public isLoading: Boolean = true;
  public dataSource: MatTableDataSource<TransitionalCareAppointments> =
    new MatTableDataSource<TransitionalCareAppointments>([]);
  public searchTerm: string;
  public sortByColumn: string;
  public sorByDirection: string;
  public chatAppointment: TransitionalCareAppointments;
  public reportName: string = getTcmReportName();
  expandedElement: TransitionalCareAppointments | null;
  listItems: TransitionalCareAppointments[];
  Items: TransitionalCareRecordsResultDTO;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input() public from: Date;
  @Input() public to: Date;

  appointmentsSearchTerm = '';
  displayedColumns: string[] = [
    'patientName',
    'patientPhoneNumber',
    'admitionDoctorName',
    'primaryDoctorName',
    'admitionDate',
    'chat',
    'callStatusName',
    'Acciones',
  ];
  allColumns: string[] = [
    'patientName',
    'chat',
    'patientPhoneNumber',
    'admitionDoctorName',
    'primaryDoctorName',
    'primaryDoctorPhone',
    'admitionDate',
    'dischargeDate',
    'appointmentDate',
    'daysAdmitted',
    'callStatusName',
    'hospitalName',
    'businessLine',
    'Acciones',
  ];

  timeout = null;
  getFriendlyColumnName(column: string) {
    switch (column) {
      case 'patientName':
        return 'Nombre';

      case 'patientPhoneNumber':
        return 'Teléfono';

      case 'admitionDoctorName':
        return 'Médico Admisión';

      case 'primaryDoctorName':
        return 'Médico Primario';

      case 'primaryDoctorPhone':
        return 'Tel. Médico Primario';

      case 'admitionDate':
        return 'Fecha de Admisión';

      case 'dischargeDate':
        return 'Fecha de Alta';

      case 'appointmentDate':
        return 'Fecha de Cita';

      case 'daysAdmitted':
        return 'Días Admitido';

      case 'callStatusName':
        return 'Estatus';

      case 'hospitalName':
        return 'Hospital';

      case 'chat':
        return 'Chat';

      case 'businessLine':
        return 'Plan Médico';

      case 'Acciones':
        return 'Acciones';
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.User?.userId) {
      this.load();
    }
  }
  public onChatButtonClicked(appointment: TransitionalCareAppointments) {
    this.chatAppointment = appointment;
  }
  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
    this.pageSize = DEFAULT_PAGE_SIZE;
    this.page = DEFAULT_PAGE;
    this.load();
  }
  public toLocal = (date: Date) => {
    return moment(date).local().format('YYYY-MM-DD HH:mm:ss');
  };
  ngAfterViewInit(): void {
    this.User = this.authenticationService.currentUserValue;
    this.dataSource.sort = this.sort;
    this.load();
  }

  public whenImportClicked() {
    this.onImportClicked.next();
  }
  sortData(sort: Sort) {
    this.LoadLists(
      this.appointmentTypeId,
      this.from,
      this.to,
      null,
      sort.active,
      sort.direction
    );
  }

  public onListLoaded = (result, fromDate: Date, toDate: Date) => {
    const res = result as TransitionalCareRecordsResultDTO;

    this.listItems = res.items;
    this.dataSource.data = this.listItems;
    this.Items = res;
    this.totalSize = this.Items.totalCount;
    this.pageSize = this.Items.pageSize;
    this.page = this.Items.pageIndex;
    this.isLoading = false;
    this.from = fromDate;
    this.to = toDate;
  };

  public loadDetail = (record: TransitionalCareAppointments) => {
    this.expandedElement = this.expandedElement === record ? null : record;

    if (!record._detail) {
      const clinicid = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
      this.API.GetTcmDetail(
        clinicid,
        record.transitionalCareRecord.appointmentId
      ).subscribe((res) => {
        record._detail = res;
      });
    }
  };

  public async LoadLists(
    AppointmentType: number,
    fromDate: Date,
    toDate: Date,
    clinicId?: number,
    sortByColumn: string = null,
    sorByDirection: string = null,
    searchTerm: string = null
  ) {
    if (!fromDate || !toDate) return;

    if (this.suscription && !this.suscription.closed) {
      this.suscription.unsubscribe();
    }

    this.sortByColumn = sortByColumn;
    this.sorByDirection = sorByDirection;
    this.dataSource.data = [];

    this.isLoading = true;
    if (!clinicId) {
      clinicId = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    }
    const fromFormat = moment(fromDate).format('YYYY-MM-DD');
    const toFormat = moment(toDate).format('YYYY-MM-DD');

    this.suscription = this.API.GetTransitionalCareRecords(
      clinicId,
      AppointmentType,
      fromFormat,
      toFormat,
      this.page,
      this.pageSize,
      sortByColumn,
      sorByDirection,
      searchTerm
    ).subscribe(
      (result) => this.onListLoaded(result, fromDate, toDate),
      (error) => {
        console.error(error);
      }
    );
  }

  getReportTitle() {
    switch (this.appointmentTypeId) {
      case AppointmentType.TransitionalCareHospitalizados:
        return 'Lista de Hospitalizados';
      case AppointmentType.TransitionalCareAltasconCita:
        return 'Lista de Altas con Citas';
      case AppointmentType.TransitionalCareAltassinCita:
        return 'Lista de Altas sin Citas';
      default:
        return 'Lista del total de admisiones';
    }
  }
  DownloadReport(ExportType: string) {
    let clinicid = this.User.clinicId;
    if (this.ViewAs) {
      clinicid = this.ViewAs.clinicId;
    }

    const link =
      this.baseUrl +
      `Reports/TCMReports?StartDate=${moment(this.from).format(
        'YYYY-MM-DD'
      )}&EndTime=${moment(this.to).format(
        'YYYY-MM-DD'
      )}&clinicid=${clinicid}&ExportType=${ExportType}&reportName=${
        this.reportName
      }&reportTitle=${this.getReportTitle()}${
        this.appointmentTypeId
          ? `&appointmentTypeID=${this.appointmentTypeId}`
          : ''
      }`;

    if (ExportType === 'PDF') {
      const data: reportsViewerData = {
        link,
      };
      this.dialog.open(ReportsViewerComponent, {
        data,
        width: '80%',
        disableClose: true,
      });
    } else {
      window.open(link, '_blank');
    }
  }
  public handlePage(e: any) {
    this.page = e.pageIndex;
    this.pageSize = e.pageSize;

    this.load();
  }

  public load() {
    this.LoadLists(
      this.appointmentTypeId,
      this.from,
      this.to,
      null,
      this.sortByColumn,
      this.sorByDirection,
      this.searchTerm
    );
  }
  applyFilter(filterValue: string) {
    this.searchTerm = filterValue;
    this.page = 0;
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.reset();
    }, 1200);
  }

  public formatDate(date: Date) {
    if (date) {
      moment.locale('es');
      return moment(date).format('DD MMM YYYY');
    }
  }

  public calcDaysAdmitted(start: any, end: any) {
    if (!end) {
      return moment(moment()).diff(start, 'days', true) | 0;
    } else {
      return moment(end).diff(start, 'days', true) | 0;
    }
  }

  public reset() {
    this.pageSize = DEFAULT_PAGE_SIZE;
    this.page = DEFAULT_PAGE;
    this.load();
  }
}
