import { Input, OnChanges, SimpleChanges } from '@angular/core';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { isEmpty } from 'lodash';
import { ApiService } from '../../api.service';

import { User } from '../../_models';
import { AdminViewAsClinicDTO } from '../../dtos/AdminViewAsClinicDTO';
import {
  ClicPermissionDTO,
  ClinicPermissionDTO,
} from '../../DTOS/Permission/PermissionDTO';
import { LoadingDialogComponent } from '../../loading-dialog/loading-dialog.component';

@Component({
  selector: 'app-clinic-permissions',
  templateUrl: './clinic-permissions.component.html',
  styleUrls: ['./clinic-permissions.component.scss'],
})
export class ClinicPermissionsComponent implements AfterViewInit, OnChanges {
  constructor(
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private API: ApiService
  ) {}
  isLoading: boolean;
  loadingRef: MatDialogRef<LoadingDialogComponent>;
  Permissions: ClinicPermissionDTO[];
  ClinicPermissions: ClinicPermissionDTO[];
  ListClinicPermission: ClicPermissionDTO[];
  baseUrl: string;
  public User: User;
  dataSource: MatTableDataSource<ClinicPermissionDTO>;
  public _isActive_updt: boolean;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns: string[] = ['slug', 'permissionName', 'clinicPermissionId'];
  searchTearm: string;

  @Input() public ViewAs: AdminViewAsClinicDTO;
  @Input() public isStandalone: boolean;
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ViewAs?.previousValue !== changes.ViewAs?.currentValue) {
      this.FetchData();
    }
  }

  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
    this.FetchData();
  }

  async FetchData() {
    await this.FetchPermissions();
  }

  public async FetchPermissions() {
    this.isLoading = true;

    await this.API.GetAllPermissions(false)
      .toPromise()
      .then(
        (result) => {
          const res = result as ClinicPermissionDTO[];
          this.Permissions = res;
          this.isLoading = false;
          this.FetchClinicPermissions();
        },
        (error) => {
          console.error(error);
          this.isLoading = false;
        }
      );
  }

  public async FetchClinicPermissions() {
    this.isLoading = true;

    if (this.isStandalone && !this.ViewAs?.clinicId) {
      return;
    }
    const clinicID = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;

    await this.API.GetAllClinicPermissions(clinicID).subscribe(
      (result) => {
        const res = result as ClinicPermissionDTO[];
        this.ClinicPermissions = res;
        for (let i = 0; i < this.ClinicPermissions.length; i++) {
          const option = this.ClinicPermissions[i];
          const element = this.Permissions.find(
            (e) => e.permissionId === option.permissionId
          );

          if (!isEmpty(element)) {
            const index = this.Permissions.findIndex(
              (f) => f.permissionId === element.permissionId
            );
            this.Permissions[index].clinicId = option.clinicId;
            this.Permissions[index].deleteDate = option.deleteDate;
            this.Permissions[index].clinicPermissionId =
              option.clinicPermissionId;
          }
        }

        this.dataSource = new MatTableDataSource<ClinicPermissionDTO>(
          this.Permissions
        );
        this.dataSource.paginator = this.paginator;
        this.isLoading = false;
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
      }
    );
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngAfterViewInit() {
    this.FetchData();
  }

  public async Save(clicPermissionDTO: ClinicPermissionDTO) {
    if (clicPermissionDTO.clinicPermissionId) {
      this.deletePermission(clicPermissionDTO);
    } else {
      this.createPermission(clicPermissionDTO);
    }
  }

  private async createPermission(clicPermissionDTO: ClinicPermissionDTO) {
    if (this.isStandalone && !this.ViewAs?.clinicId) {
      return;
    }
    const clinicID = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    clicPermissionDTO.clinicId = clinicID;

    await this.API.CreateClinicPermission(clicPermissionDTO).subscribe(
      () => {
        this.isLoading = false;
        this._snackBar.open('¡Listo!, se ha activado el permiso.', 'OK ', {
          duration: 10000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
          panelClass: 'success-dialog',
        });
        this.FetchData();
      },
      (f) => {
        this.isLoading = false;
        window.alert('Lo sentimos, ha ocurrido un error.');
        console.error('ERROR AL AGREGAR PERMISO', f);
      }
    );
  }

  private async deletePermission(clicPermissionDTO: ClinicPermissionDTO) {
    await this.API.DeleteClinicPermissions(
      clicPermissionDTO.clinicPermissionId
    ).subscribe(
      () => {
        this.isLoading = false;
        this._snackBar.open('¡Listo!, se desactivado el permiso.', 'OK ', {
          duration: 10000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
          panelClass: 'success-dialog',
        });
        this.FetchData();
      },
      (f) => {
        this.isLoading = false;
        window.alert('Lo sentimos, ha ocurrido un error.');
        console.error('ERROR AL actualizar PERMISO', f);
      }
    );
  }
}
