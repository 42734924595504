import { BoardTransitions } from './BoardTransaction';

export class BoardColumns {
  boardColumnId: number;
  columnName: string;
  colorCode: string;
  backgroundColorCode: string;
  boardId: number;
  createDate: string;
  deleteDate: string | null;
  createdByUserId: number;
  deletedByUserId: number | null;
  transitions: BoardTransitions[];
}
