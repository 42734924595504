import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmationService } from 'primeng/api';
import { ApiService } from 'src/app/api.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from 'src/app/confirm-dialog/confirm-dialog.component';
import {
  EHRGetDTO,
  EHRList,
  ElectronicCalendar,
} from 'src/app/DTOS/Ehrs/EhrGetDTO';
import {
  LoadingDialogComponent,
  LoadingDialogModel,
} from 'src/app/loading-dialog/loading-dialog.component';
import {
  AddEhrDialogComponent,
  EHRDialogData,
} from 'src/app/_dialogs/add-ehr-dialog/add-ehr-dialog.component';
import { User } from 'src/app/_models';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-ehrs',
  templateUrl: './ehrs.component.html',
  styleUrls: ['./ehrs.component.scss'],
})
export class EhrsComponent implements OnInit {
  constructor(
    private authenticationService: AuthenticationService,
    private API: ApiService,
    public dialog: MatDialog,
    private confirmationService: ConfirmationService,
    private _snackBar: MatSnackBar
  ) {}

  isLoading = false;
  public EHRsList: EHRList;
  public EHR: ElectronicCalendar;
  public isUpdatingEhr: Boolean = false;
  public isEditing: Boolean = false;
  public isSubmitting: Boolean = false;
  listItems: ElectronicCalendar[];
  @Input() public isStandalone: boolean;
  public User: User;
  public searchTerm = '';
  public pageSize = 10;
  public currentPage = 1;
  public totalSize = 0;
  loadingRef: MatDialogRef<LoadingDialogComponent>;
  dataSource: MatTableDataSource<ElectronicCalendar>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  editSlidePanelOpen: Boolean = false;
  editSlidePanelEhrDetails: ElectronicCalendar = {} as any;
  displayedColumns: string[] = [
    'electronicCalendarId',
    'electronicCalendarName',
    'integrationType',
    'manufacturer',
    'printerEhr',
    'Edit',
  ];

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe((user) => {
      this.User = user;
    });
    this.LoadEhrs();
  }

  public LoadEhrs() {
    this.currentPage = this.currentPage === 0 ? 1 : this.currentPage;
    this.ShowLoading();
    this.API.GetEHRPagedList(
      this.currentPage,
      this.pageSize,
      this.searchTerm
    ).subscribe(
      (r) => {
        const res = r as EHRList;
        this.dataSource = new MatTableDataSource<ElectronicCalendar>(res.items);
        this.EHRsList = res;
        this.dataSource.paginator = this.paginator;
        this.totalSize = this.EHRsList.totalCount;
        this.pageSize = this.EHRsList.pageSize;
        this.currentPage = this.EHRsList.pageIndex;
        this.listItems = this.EHRsList.items;
        this.isLoading = false;
        this.hideLoading();
      },
      (error) => {
        console.error(error);
        this.hideLoading();
        this.showError(error);
      }
    );
  }

  public UpdateEHR(electronicCalendar: ElectronicCalendar) {
    this.API.UpdateElectronicCalendar(electronicCalendar).subscribe(
      () => {
        this.isUpdatingEhr = true;
        this.isLoading = false;
        this._snackBar.open(
          `¡Listo!, el record electronico ${electronicCalendar.electronicCalendarName} ha sido actualizado. `,
          'OK ',
          {
            duration: 70000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: 'success-dialog',
          }
        );
        this.editSlidePanelOpen = false;
      },
      (e) => {
        this.isUpdatingEhr = false;
        this.isLoading = false;
        this.isEditing = true;
        this.showError(e);
      }
    );
  }

  public DeleteEHR(event: Event, electronicCalendarId: number) {
    if (this.User?.isAdmin === false) {
      return;
    }

    this.confirmationService.confirm({
      target: event.target,
      message: '¿Estás segur@ de querer eliminar este record electronico?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si, eliminar',
      rejectLabel: 'Cancelar',
      accept: () =>
        this.API.DeleteElectronicCalendar(electronicCalendarId).subscribe(
          (r) => {
            this.editSlidePanelOpen = false;
            this.LoadEhrs();
          },
          (f) => {
            window.alert('Lo sentimos, ha ocurrido un error.');
            console.error('ERROR AL eliminar el record electronico', f);
          }
        ),
      reject: () => {
        this.confirmationService.close();
      },
    });
  }

  public ShowLoading() {
    const loading_dialog = this.dialog.open(LoadingDialogComponent, {
      maxWidth: '400px',
      data: new LoadingDialogModel('Cargando', '', false),
    });
    loading_dialog.disableClose = true;
    this.loadingRef = loading_dialog;
  }

  public hideLoading() {
    if (this.loadingRef) {
      this.loadingRef.close();
    }
  }

  private showError(error: any) {
    console.error('CITAMED ERROR', error);
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: new ConfirmDialogModel('Ha ocurrido un error', '', 'Okay', null),
    });
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex + 1;
    this.pageSize = e.pageSize;
    this.LoadEhrs();
  }

  public openEditSidePanel(ehr: ElectronicCalendar) {
    this.editSlidePanelOpen = true;
    this.editSlidePanelEhrDetails = ehr as ElectronicCalendar;
  }

  public closeEditSidePanel(ehr: ElectronicCalendar) {
    this.editSlidePanelOpen = false;
    this.editSlidePanelEhrDetails = ehr as ElectronicCalendar;
  }

  OpenEHRDialog(electronicCalendar: ElectronicCalendar) {
    const data: EHRDialogData = {
      electronicCalendar,
      title: electronicCalendar
        ? 'Editar Record Electronico'
        : 'Añadir Record Electronico',
    };

    this.dialog
      .open(AddEhrDialogComponent, {
        data,
        maxWidth: '39.5rem',
        height: '25rem',
        panelClass: 'full-with-dialog',
        disableClose: false,
        hasBackdrop: true,
        autoFocus: true,
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        const result = dialogResult as boolean;
        if (result) {
          this.LoadEhrs();
        }
      });
  }
}
