import { RatePlan } from './../RatePlans/RatePlan';
import { deactivation } from './Company';

export class AddCompanyDTO {
  constructor() {
    this._ratePlan = {} as any;
  }
  companyId: number;
  ratePlanId: number;
  nextMonthRatePlanId: number;
  associateId: number;
  companyName: string;
  isActive: boolean;
  contractStartDate: Date;
  contractEndDate: Date;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  mainContact: string;
  phoneNumber: string;
  emailAddress: string;
  _ratePlan: RatePlan;
  deactivationReason: string;
  deactivation?: deactivation;
}
