import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { ConfirmationService } from 'primeng/api';
import { AuthenticationService } from 'src/app/_services';
import { ApiService } from 'src/app/api.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from 'src/app/confirm-dialog/confirm-dialog.component';
import { AdminViewAsClinicDTO } from 'src/app/dtos/AdminViewAsClinicDTO';
import { VirtualAssistantAccountDTO } from 'src/app/DTOS/VA_Configurations/VA_Configurations';
import {
  LoadingDialogComponent,
  LoadingDialogModel,
} from 'src/app/loading-dialog/loading-dialog.component';
import { PermissionSlugsService } from 'src/app/Services/PermissionSlugsService';
import { User } from '../../_models/user';

@Component({
  selector: 'app-va-accounts',
  templateUrl: './va-accounts.component.html',
  styleUrls: ['./va-accounts.component.scss'],
})
export class AccountsComponentComponent implements AfterViewInit {
  public permissions: string[] = [];
  public User: User;
  @Input() public ViewAs: AdminViewAsClinicDTO;
  @Input() public isStandalone: boolean;
  dataSource: MatTableDataSource<VirtualAssistantAccountDTO>;
  displayedColumns: string[] = [
    'accountID',
    'clinicName',
    'city',
    'state',
    'country',
    'twilioAssignedPhoneNumber',
    'enableRecordings',
    'deactivationDate',
    'Edit',
  ];
  httpClient: HttpClient;
  baseUrl: string;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  loadingRef: MatDialogRef<LoadingDialogComponent>;

  constructor(
    http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private permissionSlugsService: PermissionSlugsService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private API: ApiService,
    private confirmationService: ConfirmationService,
    private _snackBar: MatSnackBar
  ) {
    this.httpClient = http;
    this.baseUrl = baseUrl;
    this.permissionSlugsService.fetch();
    this.permissionSlugsService.permissionsListener.subscribe((r) => {
      this.permissions = r;
    });
    this.authenticationService.currentUser.subscribe((u) => (this.User = u));
  }

  ngAfterViewInit() {
    this.User = this.authenticationService.currentUserValue;
    this.LoadVirtualAssistants();
  }

  public LoadVirtualAssistants() {
    if (this.isStandalone && !this.ViewAs?.clinicId) {
      return;
    }

    const clinicId = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    this.ShowLoading();

    this.API.GetVirtualAssistants(clinicId).subscribe(
      (result) => {
        const res = result as VirtualAssistantAccountDTO[];
        this.dataSource = new MatTableDataSource<VirtualAssistantAccountDTO>(
          res
        );
        this.dataSource.paginator = this.paginator;
        this.hideLoading();
      },
      (error) => {
        console.error(error);
        this.hideLoading();
        this.showError(error);
      }
    );
  }

  public ShowLoading() {
    const loading_dialog = this.dialog.open(LoadingDialogComponent, {
      maxWidth: '400px',
      data: new LoadingDialogModel('Cargando', '', false),
    });
    loading_dialog.disableClose = true;
    this.loadingRef = loading_dialog;
  }

  public hideLoading() {
    if (this.loadingRef) {
      this.loadingRef.close();
    }
  }

  private showError(error: any) {
    console.error('CITAMED ERROR', error);
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: new ConfirmDialogModel('Ha ocurrido un error', '', 'Okay', null),
    });
  }
}
