import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';
import { User } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services';
import { ApiService } from 'src/app/api.service';
import { InsurancePlansDTO } from 'src/app/DTOS/InsurancePlans/InsurancePlansDTO';
import { PermisionDialogData } from '../permission-dialog/permission-dialog.component';
export interface InsurancePlanDialogData {
  insurancePlan: InsurancePlansDTO;
  title: String;
}

@Component({
  selector: 'app-insurance-plan-dialog',
  templateUrl: './insurance-plan-dialog.component.html',
  styleUrls: ['./insurance-plan-dialog.component.scss'],
})
export class InsurancePlanDialogComponent implements AfterViewInit {
  User: User;
  public insurancePlan: InsurancePlansDTO;
  isLoading = false;

  ngAfterViewInit() {
    this.authenticationService.currentUser.subscribe((u) => {
      this.User = u;
    });
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: InsurancePlanDialogData,
    public dialogRef: MatDialogRef<InsurancePlanDialogComponent>,
    private _snackBar: MatSnackBar,
    @Inject('BASE_URL') baseUrl: string,
    private API: ApiService,
    private authenticationService: AuthenticationService
  ) {
    if (this.data.insurancePlan) {
      this.setData(this.data.insurancePlan);
    } else {
      this.setAsNewRecord();
    }
  }

  public setData(insurancePlan: InsurancePlansDTO) {
    this.insurancePlan = {
      insurancePlanID: insurancePlan.insurancePlanID,
      insurancePlanName: insurancePlan.insurancePlanName,
    } as InsurancePlansDTO;
  }

  public setAsNewRecord() {
    this.insurancePlan = new InsurancePlansDTO();
  }

  public close() {
    this.dialogRef.close();
  }

  public onDismiss() {
    this.dialogRef.close(false);
  }

  public async Save() {
    const confirm = window.confirm(
      `¿Estás segur@ de querer ${
        this.insurancePlan.insurancePlanID ? 'actualizar' : 'crear'
      } este plan de seguro?`
    );
    if (!confirm) {
      return;
    }

    if (this.insurancePlan.insurancePlanID) {
      this.UpdateInsurancePlan(this.insurancePlan);
    } else {
      this.CreateInsurancePlan(this.insurancePlan);
    }
  }

  private CreateInsurancePlan(insurancePlan: InsurancePlansDTO) {
    const insurancePlanDTO = _.cloneDeep(insurancePlan);
    this.API.CreateInsurancePlan(insurancePlan).subscribe(
      () => {
        this.isLoading = false;
        this.dialogRef.close(true);
        this._snackBar.open('¡Listo!, se ha creado el plan de seguro.', 'OK ', {
          duration: 10000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
          panelClass: 'success-dialog',
        });
      },
      (f) => {
        this.isLoading = false;
        this._snackBar.open('Lo sentimos, ha ocurrido un error.');
        console.error('ERROR AL CREAR PERMISO', f);
      }
    );
  }

  private UpdateInsurancePlan(_insurancePlan: InsurancePlansDTO) {
    const insurancePlan = { ..._insurancePlan };

    this.API.UpdateInsurancePlan(insurancePlan).subscribe(
      () => {
        this.isLoading = false;
        this.dialogRef.close(true);
        this._snackBar.open(
          '¡Listo!, se ha actualizado el plan de seguro.',
          'OK ',
          {
            duration: 10000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: 'success-dialog',
          }
        );
      },
      (f) => {
        this.isLoading = false;
        this._snackBar.open('Lo sentimos, ha ocurrido un error.');
        console.error('ERROR AL actualizar permiso', f);
      }
    );
  }
}
