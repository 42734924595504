import * as moment from 'moment';

import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { AdminViewAsClinicDTO } from '../dtos/AdminViewAsClinicDTO';
import { ApiService } from '../api.service';
import { AppointmentType } from '../DTOS/Appointments-types/appointmentType';
import { AuthenticationService } from '../_services';
import { ChartOptions } from 'chart.js';
import { TransitionalCareResumentDTO } from '../DTOS/TransitionalCareAdmisionsAndDischarges/TransitionalCareAdmisionsAndDischarges';
import { User } from '../_models';

type ChartType = 'bar' | 'lines' | 'pie';
@Component({
  selector: 'app-transitional-care-resumen-tab',
  templateUrl: './transitional-care-resumen-tab.component.html',
  styleUrls: ['./transitional-care-resumen-tab.component.scss'],
})
export class TransitionalCareResumenTabComponent
  implements AfterViewInit, OnChanges
{
  constructor(
    private authenticationService: AuthenticationService,
    private API: ApiService
  ) {
    this.isLoading = true;
    moment.locale('es');
  }
  @Input() public searchTerm: string;
  @Input() public ViewAs: AdminViewAsClinicDTO;
  public User: User;
  public bar: ChartType = 'bar';
  public lines: ChartType = 'lines';
  public pie: ChartType = 'pie';
  public isLoading: boolean;
  public visibleGraph: ChartType = 'pie';

  public transitionalCareResumentDTO: TransitionalCareResumentDTO =
    {} as TransitionalCareResumentDTO;

  @Input() public from: Date;
  @Input() public to: Date;
  pieChartOptions = {
    responsive: true,
  };
  pieChartData = [{ data: [0, 0, 0], label: 'Minutes' }];
  pieChartLabels = ['Hospitalizados', 'Altas con Citas', 'Altas sin Citas'];
  public pieChartColors: any[] = [
    {
      backgroundColor: ['#b0f2c2', '#b2e2f2', '#ecd6c0'],
    },
  ];

  // barchart init
  public barChartOptions: ChartOptions = {
    responsive: true,
  };
  public barChartLabels: string[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];

  public barChartData: any[] = [];

  public chartLabel = '';
  ngOnChanges(changes: SimpleChanges): void {
    if (this.User?.userId) {
      this.LoadResumenTransitionalCare();
    }
  }

  public getAppointmentTypeColor(appointmentTypeId: number) {
    switch (appointmentTypeId) {
      case AppointmentType.TransitionalCareAltasconCita:
        return '#b2e2f2';
      case AppointmentType.TransitionalCareAltassinCita:
        return '#ecd6c0';
      case AppointmentType.TransitionalCareHospitalizados:
        return '#b0f2c2';
    }
  }
  public getAppointmentTypeLabel(appointmentTypeId: number) {
    switch (appointmentTypeId) {
      case AppointmentType.TransitionalCareAltasconCita:
        return 'Altas con citas';
      case AppointmentType.TransitionalCareAltassinCita:
        return 'Altas sin cita';
      case AppointmentType.TransitionalCareHospitalizados:
        return 'Hospitalizados';
    }
  }
  public loadChart() {
    const clinicID = this.ViewAs?.clinicId
      ? this.ViewAs.clinicId
      : this.User.clinicId;

    let from = this.from;
    const to = this.to;
    const rangeInDays = moment(this.to).diff(this.from, 'days');

    if (rangeInDays > 5) {
      from = moment(to).add(-5, 'days').toDate();
      this.chartLabel = `Grafico desde ${moment(from).format(
        'MMM/DD/YYYY'
      )} a ${moment(to).format('MMM/DD/YYYY')}`;
    } else {
      this.chartLabel = '';
    }

    this.API.GetTCMGraphsData(clinicID, from, to, this.searchTerm).subscribe(
      (r) => {
        let allDates: Date[] = [];

        r.forEach((element) => {
          allDates = [...allDates, ...element.data.map((a) => a.date)];
        });
        const distinctDates = [...new Set(allDates)];

        this.barChartLabels = distinctDates.map((d) =>
          moment(d).format('MMM/DD/YYYY')
        );
        const barChartData: any[] = [];

        r.forEach((appointmentTypeData) => {
          const countArray = [];
          distinctDates.forEach((d) => {
            const count = appointmentTypeData.data.find(
              (day) => day.date === d
            );
            countArray.push(count?.count || 0);
          });
          barChartData.push({
            data: countArray,
            label: this.getAppointmentTypeLabel(
              appointmentTypeData.appointmentTypeId
            ),
            backgroundColor: this.getAppointmentTypeColor(
              appointmentTypeData.appointmentTypeId
            ),
          });
        });

        this.barChartData = barChartData;
      }
    );
  }

  // barchart end
  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
  }
  ngAfterViewInit(): void {
    this.authenticationService.currentUser.subscribe((user) => {
      this.User = user;
      this.LoadResumenTransitionalCare();
    });
  }

  public async LoadResumenTransitionalCare() {
    this.isLoading = true;
    const fromFormat = moment(this.from).format('YYYY-MM-DD');
    const toFormat = moment(this.to).format('YYYY-MM-DD');
    const clinicID = this.ViewAs?.clinicId
      ? this.ViewAs.clinicId
      : this.User.clinicId;

    this.loadChart();
    await this.API.GetResumenTransitionalCare(
      clinicID,
      fromFormat,
      toFormat
    ).subscribe(
      (result) => {
        this.transitionalCareResumentDTO =
          result as TransitionalCareResumentDTO;
        this.isLoading = false;

        const {
          transitionalCareHospitalized = 0,
          transitionalCarWithappointment = 0,
          transitionalCarWitouthappointment = 0,
        } = this.transitionalCareResumentDTO;

        this.pieChartData = [
          {
            data: [
              transitionalCareHospitalized,
              transitionalCarWithappointment,
              transitionalCarWitouthappointment,
            ],
            label: 'Minutes',
          },
        ];
      },
      (error) => {
        console.error(error);
      }
    );
  }
}
