import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { cloneDeep, isArray } from 'lodash';
import { AuthenticationService } from 'src/app/_services';
import { ApiService } from 'src/app/api.service';
import { AdminViewAsClinicDTO } from 'src/app/dtos/AdminViewAsClinicDTO';
import { CalltimingSet } from 'src/app/DTOS/CalltimingSet/CallTimingSetGetDTO';
import { User } from '../../_models/user';

@Component({
  selector: 'app-call-timings',
  templateUrl: './call-timings.component.html',
  styleUrls: ['./call-timings.component.scss'],
})
export class CallTimingsComponent implements AfterViewInit, OnChanges {
  constructor(
    private API: ApiService,
    private authenticationService: AuthenticationService
  ) {}
  slidePanelOpen: boolean;
  isSubmitting: boolean;
  public User: User;
  @Input() public ViewAs: AdminViewAsClinicDTO;
  public calltimingSets: CalltimingSet[];
  public calltimingToEdit: CalltimingSet = {} as any;
  @Input() public isStandalone: boolean;

  ngOnChanges(): void {
    this.LoadCallTimings();
  }

  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
    this.LoadCallTimings();
  }

  ngAfterViewInit() {
    this.User = this.authenticationService.currentUserValue;
    this.LoadCallTimings();
  }

  private getClinicID() {
    const clinicid = this.ViewAs ? this.ViewAs?.clinicId : this.User?.clinicId;
    return clinicid;
  }

  public calltimeToDate(calltime: string) {
    return new Date('2021-01-01 ' + calltime);
  }

  public numberToOrder(n: number) {
    switch (n) {
      case 1:
        return '1er';
      case 2:
        return '2do';
      case 3:
        return '3er';
      case 4:
        return '4to';
      case 5:
        return '5to';
      case 6:
        return '6to';
      default:
        return n;
    }
  }

  public OpenNewCallTimingSetPanel() {
    this.calltimingToEdit = {} as any;

    this.slidePanelOpen = true;
  }

  public OpenEditCallTimingSetPanel(CalltimingSet: CalltimingSet) {
    this.calltimingToEdit = cloneDeep(CalltimingSet);

    this.slidePanelOpen = true;
  }
  public AddAttemptToEditingSet() {
    if (!isArray(this.calltimingToEdit.timings)) {
      this.calltimingToEdit.timings = [];
    }

    this.calltimingToEdit.timings.push({
      callTimingId: 0,
      callOrder: this.calltimingToEdit.timings.length + 1,
      callTime: '',
      createDate: new Date(),
    });
  }

  public updateCallTimingSet() {
    const clinicid = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    this.isSubmitting = true;

    this.API.UpdateCallTimingSet({
      ...this.calltimingToEdit,
      clinicId: clinicid,
    }).subscribe(() => {
      this.CloseEditCallTimingSetPanel();
      this.isSubmitting = false;
      this.LoadCallTimings();
    });
  }

  public createCallTimingSet() {
    const clinicid = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;

    this.isSubmitting = true;

    this.API.CreateCallTimingSet({
      ...this.calltimingToEdit,
      clinicId: clinicid,
    }).subscribe(() => {
      this.CloseEditCallTimingSetPanel();
      this.isSubmitting = false;
      this.LoadCallTimings();
    });
  }

  public deleteCallTimingSet(set: CalltimingSet) {
    const confirm = window.confirm(
      `¿Estás segur@ de querer eliminar el horario de envios ${set.callTimingSetName}?`
    );
    if (confirm) {
      this.API.DeleteCallTimingSet(set.callTimingSetId).subscribe(() => {
        this.LoadCallTimings();
      });
    }
  }

  public RemoveAttemptToEditingSet() {
    if (!isArray(this.calltimingToEdit.timings)) {
      this.calltimingToEdit.timings = [];
    }
    this.calltimingToEdit.timings.pop();
  }
  public CloseEditCallTimingSetPanel() {
    this.calltimingToEdit = {} as any;
    this.slidePanelOpen = false;
  }

  public LoadCallTimings() {
    const clinicId: number = this.getClinicID();
    if (!clinicId) {
      return;
    }

    if (this.isStandalone && !this.ViewAs?.clinicId) {
      return;
    }
    this.API.GetAllCalltimings(clinicId).subscribe((r: CalltimingSet[]) => {
      this.calltimingSets = r;
    });
  }
}
