type SmsReminderVariable = {
  variable: string;
  maxLength: number;
  description: string;
};
const smsReminderVariables: SmsReminderVariable[] = [
  {
    variable: '{$PatientName$}',
    maxLength: 0,
    description: 'El nombre del paciente',
  },
  {
    variable: '{$DoctorName$}',
    maxLength: 0,
    description: 'Nombre del doctor',
  },
  {
    variable: '{$Time$}',
    maxLength: 0,
    description: 'Hora de la cita. Ej 9:45 AM',
  },
  {
    variable: '{$HourOnly$}',
    maxLength: 0,
    description:
      'Hora sin minutos. Ejemplo: si la cita es a las 9:30 AM, esta variable mostraría: 9:00 AM',
  },
  {
    variable: '{$Weekday$}',
    maxLength: 0,
    description: 'Día de la cita. Ej. Lunes, Martes... Domingo',
  },
  {
    variable: '{$Date$}',
    maxLength: 0,
    description:
      'Solo la fecha de la cita, en formato M/dd/yyyy. Ej: 9/26/2022',
  },
  {
    variable: '{$ClinicName$}',
    maxLength: 0,
    description: 'El nombre de la clinica',
  },
  {
    variable: '{$GroupHour$}',
    maxLength: 0,
    description:
      'Calcula la hora dependiendo del grupo de la cita y el rango de fecha en metadata. IMPORTANTE: Configuración adicional es necesaria para esta variable.',
  },
];

export { smsReminderVariables };
