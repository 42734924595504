import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { ApiService } from 'src/app/api.service';
import { RatePlan } from './../../DTOS/RatePlans/RatePlan';

@Component({
  selector: 'app-rate-plans',
  templateUrl: './rate-plans.component.html',
  styleUrls: ['./rate-plans.component.scss'],
})
export class RatePlansComponent implements OnInit {
  constructor(
    private API: ApiService,
    private confirmationService: ConfirmationService
  ) {}
  sidePanelPlan: RatePlan = {} as any;
  RatePlans: RatePlan[];
  slidePanelOpen = false;
  isSubmitting = false;

  public OpenAddNewSidePanel() {
    this.sidePanelPlan = {} as any;
    this.slidePanelOpen = true;
  }
  public closeAddNewSidePanel() {
    this.sidePanelPlan = {} as any;
    this.slidePanelOpen = false;
  }

  public isSlidePanelPlanInvalid = () =>
    !(
      this.sidePanelPlan.maximumMinutes &&
      this.sidePanelPlan.monthlyFee &&
      this.sidePanelPlan.ratePlanName &&
      this.sidePanelPlan.pricePerMinute
    )

  public LoadRatePlans() {
    this.API.GetAllRatePlans().subscribe((r) => {
      this.RatePlans = r;
    });
  }
  public deleteRatePlan(event: Event, rateplanId: number) {
    this.confirmationService.confirm({
      target: event.target,
      message: '¿Estás segur@ de querer eliminar este plan de facturación?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si, eliminar',
      rejectLabel: 'Cancelar',
      accept: () => {
        this.API.DeleteRatePlan(rateplanId).subscribe(() => {
          this.LoadRatePlans();
        });
      },
      reject: () => {
        this.confirmationService.close();
      },
    });
  }
  public saveRatePlan() {
    this.isSubmitting = true;
    this.API.CreateRatePlan(this.sidePanelPlan).subscribe(() => {
      this.isSubmitting = false;
      this.LoadRatePlans();
      this.closeAddNewSidePanel();
    });
  }
  ngOnInit() {
    this.LoadRatePlans();
  }
}
