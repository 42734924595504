import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { User } from 'src/app/_models';
import { AuthenticationService } from 'src/app/_services';
import { ApiService } from 'src/app/api.service';
import { AdminViewAsClinicDTO } from 'src/app/dtos/AdminViewAsClinicDTO';
import AppointmentTypeDto from 'src/app/DTOS/Appointments-types/appointmentType';
import { ClinicAppointmentType } from 'src/app/DTOS/Appointments-types/ClinicAppointmentType';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-clinic-appointment-types',
  templateUrl: './clinic-appointment-types.component.html',
  styleUrls: ['./clinic-appointment-types.component.scss'],
})
export class ClinicAppointmentTypesComponent implements OnInit {
  constructor(
    private API: ApiService,
    private authenticationService: AuthenticationService
  ) {}

  public list_AppointmentTypeDto: AppointmentTypeDto[];
  public sidepanelAppointmentType: AppointmentTypeDto = {} as any;
  public slidePanelOpen: Boolean = false;
  public isSubmitting: Boolean = false;
  public ViewAs: AdminViewAsClinicDTO;
  public User: User;
  private ClinicAppointmentTypes: ClinicAppointmentType[];
  private clinicAppointmentTypesIds: number[];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource: MatTableDataSource<AppointmentTypeDto>;
  displayedColumns: string[] = [
    'appointmentTypeId',
    'appointmentTypeName',
    'Edit',
  ];

  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
    this.LoadClinicAppointmentTypes();
  }

  ngOnInit() {
    this.User = this.authenticationService.currentUserValue;
    this.LoadAppointmentTypes();
    this.LoadClinicAppointmentTypes();
  }

  public LoadAppointmentTypes() {
    this.API.GetAppointmentTypes().subscribe((r: AppointmentTypeDto[]) => {
      this.list_AppointmentTypeDto = r;
      this.dataSource = new MatTableDataSource<AppointmentTypeDto>(r);
      this.dataSource.paginator = this.paginator;
    });
  }

  public ToggleAppointmentTypeForClinic(
    appointmentTypeID: number,
    onOrOff: boolean
  ) {
    this.API.ToggleAppointmentTypeForClinic(
      this.ViewAs?.clinicId || this.User.clinicId,
      appointmentTypeID,
      onOrOff
    ).subscribe(() => {
      this.LoadClinicAppointmentTypes();
    });
  }

  public LoadClinicAppointmentTypes() {
    this.API.GetClinicAppointmentTypes(
      this.ViewAs?.clinicId || this.User.clinicId
    ).subscribe((r: ClinicAppointmentType[]) => {
      this.ClinicAppointmentTypes = r;
      this.clinicAppointmentTypesIds = r.map(
        (rr) => rr.appointmentType.appointmentTypeId
      );
    });
  }
}
