import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { User } from '../../_models/user';
import { AuthenticationService } from '../../_services/authentication.service';

@Component({
  selector: 'app-reminders',
  templateUrl: './reminders.component.html',
  styleUrls: ['./reminders.component.scss'],
})
/** Reminders component*/
export class RemindersComponent implements AfterViewInit {
  /** Reminders ctor */
  constructor(
    http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog
  ) {
    this.httpClient = http;
    this.baseUrl = baseUrl;
  }
  httpClient: HttpClient;
  baseUrl: string;
  public User: User;
  ngAfterViewInit() {
    this.User = this.authenticationService.currentUserValue;
  }
}
