import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import {
  ApiService,
  GetReminderSchedulesConfigResult,
} from 'src/app/api.service';
import {
  BookingModel,
  BookingViewModel,
  SuggestedRecurrenceModel,
} from 'src/app/DTOS/Bookings/BookingDTO';
import {
  Component,
  EventEmitter,
  Injectable,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {
  PatientDetail,
  PatientList,
} from 'src/app/DTOS/Patient/Patient-Detail';

import { AddAppointmentDto } from 'src/app/dtos/Appointments/AppointmentDTO';
import { AuthenticationService } from 'src/app/_services';
import { BookingService } from 'src/app/DTOS/Bookings/BookingServiceDTO';
import { Doctor } from 'src/app/Dtos/Doctors/DoctorDTO';
import { GetBookingStatus } from 'src/app/DTOS/Bookings/BookingAppointmentsDTO';
import { Howl } from 'howler';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgForm } from '@angular/forms';
import { ReminderSetDto } from 'src/app/dtos/ReminderSets/ReminderSetDto';
import { User } from 'src/app/_models';
import { deepCopy } from 'src/app/helpers/DeepCopy';
import { RecurrenceDialogComponent } from 'src/app/_dialogs/recurrence-dialog/recurrence-dialog.component';
import { ConfirmationService } from 'primeng/api';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from 'src/app/confirm-dialog/confirm-dialog.component';

type AppointmentConfigMode = 'MANUAL' | 'AUTO';
@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'app-bookings-booking-form',
  templateUrl: './bookings-booking-form.component.html',
  styleUrls: ['./bookings-booking-form.component.scss'],
})
export class BookingsBookingFormComponent implements OnInit {
  @ViewChild('bookingForm', { static: false }) public bookingForm: NgForm;

  @Input() public booking: BookingModel = {
    recurrencePattern: '',
    startDateTimeUTC: undefined,
    endDateTimeUTC: undefined,
    durationInMinutes: 0,
    bookingServiceId: 0,
    bookingStatusId: 0,
    patientRecordId: 0,
    doctorId: 0,
    clinicId: 0,
    isRecurring: false,
    notes: '',
  };

  public isSubmitting: Boolean = false;
  public isLoading: Boolean = false;
  public isLoadingServices: Boolean = false;
  public isLoadingStatuses: Boolean = false;
  public isLoadingDoctors: Boolean = false;
  public isLoadingPatients: Boolean = false;
  public isEditing: Boolean = false;
  public isRecurring: Boolean = false;

  public Services: BookingService[];
  public Statuses: GetBookingStatus[];
  public Doctors: Doctor[];
  public Patients: PatientDetail[];
  public Recurrences: SuggestedRecurrenceModel[];
  public User: User;
  public clinicId: number;
  public pageSize = 10;
  public currentPage = 1;
  public searchTerm = '';
  public _unFiltered_Doctors: Doctor[];
  public _unFiltered_Services: BookingService[];
  public _unFiltered_Statuses: GetBookingStatus[];
  public _unFiltered_Recurrence: SuggestedRecurrenceModel[];
  public dataFromMatDialogEvent: BookingModel;
  selectedReminderSet: ReminderSetDto;
  public ReminderSets: ReminderSetDto[] = new Array<ReminderSetDto>();
  public _unFiltered_ReminderSets: ReminderSetDto[] =
    new Array<ReminderSetDto>();
  appointmentConfigMode: AppointmentConfigMode = null;
  isPlayingAudio = false;
  current_Audio_Hawl: any;
  AppointmentsCallDatePicker;
  appointmentData: AddAppointmentDto;
  getReminderSchedulesConfigResults: GetReminderSchedulesConfigResult[];
  public isLoadingReminderSets = false;
  @ViewChild('AppointmentsCallDatePicker')
  public callAttempts: number;
  @Output('onSidenavClose') sidenavClose = new EventEmitter<string>();
  public isRecurrentInstance = false;
  public isEditDate = false;
  public patienteDelete: BookingViewModel;

  constructor(
    private authenticationService: AuthenticationService,
    private API: ApiService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private injector: Injector
  ) {
    this.dataFromMatDialogEvent = this.injector.get(MAT_DIALOG_DATA, null);
    if (this.dataFromMatDialogEvent) {
      this.booking = this.dataFromMatDialogEvent;
    }
  }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe((user) => {
      this.User = user;
      this.loadData();
    });
  }

  public async loadData(
    clinicId?: number,
    isRecurrentInstance: boolean = false,
    startDateTime?: Date
  ) {
    this.booking.clinicId =
      _.isNil(clinicId) === true ? this.booking.clinicId : clinicId;
    this.clinicId =
      _.isNil(this.booking.clinicId) === true
        ? this.User.clinicId
        : this.booking.clinicId;
    this.isRecurrentInstance = isRecurrentInstance;
    let recurrenceDateInitial =
      _.isNil(startDateTime) === true
        ? this.booking.startDateTime
        : startDateTime;
    this.FetchSuggestedRecurrence(recurrenceDateInitial);

    await Promise.all([
      this.FetchServices(),
      this.FetchStatuses(),
      this.FetchDoctors(),
      this.FetchPatients(),
    ]);
  }

  public async FetchServices() {
    this.isLoadingServices = true;
    this.API.GetBookingServices(this.clinicId).subscribe(
      (result) => {
        var res = result as BookingService[];
        this._unFiltered_Services = res;
        this.Services = deepCopy<BookingService[]>(this._unFiltered_Services);
        this.isLoadingServices = false;
      },
      (error) => {
        console.error(error);
        window.alert(
          'Lo sentimos, ha ocurrido un error cargando los servicios.'
        );
      }
    );
  }

  public async FetchStatuses() {
    this.isLoadingStatuses = true;
    this.API.GetBookingStatuses().subscribe(
      (result) => {
        const res = result as GetBookingStatus[];
        this._unFiltered_Statuses = res;
        this.Statuses = deepCopy<GetBookingStatus[]>(this._unFiltered_Statuses);
        this.isLoadingStatuses = false;
      },
      (error) => {
        console.error(error);
        window.alert('Lo sentimos, ha ocurrido un error cargando los status.');
      }
    );
  }

  public async FetchSuggestedRecurrence(appointmentDate: Date) {
    if (!_.isNil(appointmentDate)) {
      this.API.GetSuggestedRecurrences(appointmentDate).subscribe(
        (result) => {
          const res = result as SuggestedRecurrenceModel[];
          this._unFiltered_Recurrence = res;
          this.Recurrences = deepCopy<SuggestedRecurrenceModel[]>(
            this._unFiltered_Recurrence
          );
        },
        (error) => {
          console.error(error);
          window.alert(
            'Lo sentimos, ha ocurrido un error cargando las recurrencias.'
          );
        }
      );
    }
  }

  public async FetchDoctors() {
    this.isLoadingDoctors = true;
    this.API.GetAllDoctors(this.clinicId).subscribe(
      (result) => {
        const res = result as Doctor[];
        this._unFiltered_Doctors = res;
        this.Doctors = deepCopy<Doctor[]>(this._unFiltered_Doctors);
        this.isLoadingDoctors = false;
      },
      (error) => {
        console.error(error);
        window.alert(
          'Lo sentimos, ha ocurrido un error carguando los Doctores.'
        );
      }
    );
  }

  public async FetchPatients() {
    this.isLoadingPatients = true;
    this.currentPage = this.currentPage === 0 ? 1 : this.currentPage;
    this.API.GetPatients(
      this.currentPage,
      this.pageSize,
      this.clinicId,
      this.searchTerm
    ).subscribe(
      (result) => {
        const res = result as PatientList;
        this.Patients = res.items;
        this.isLoadingPatients = false;
      },
      (error) => {
        console.error(error);
        window.alert(
          'Lo sentimos, ha ocurrido un error cargando los pacientes.'
        );
      }
    );
  }

  public async FilterPatients(filter: string) {
    this.isLoadingPatients = true;
    this.currentPage = this.currentPage === 0 ? 1 : this.currentPage;
    this.API.GetPatients(
      this.currentPage,
      this.pageSize,
      this.clinicId,
      filter
    ).subscribe(
      (result) => {
        const res = result as PatientList;
        this.Patients = res.items;
        this.isLoadingPatients = false;
      },
      (error) => {
        console.error(error);
        window.alert('Lo sentimos, ha ocurrido un error filtrando pacientes.');
      }
    );
  }

  public saveBooking(booking?: BookingModel) {
    if (this.isRecurrentInstance) {
      this.recurrenceDialog(booking, false);
    } else {
      this.isSubmitting = true;
      if (!this.booking.bookingID) {
        this.createBooking();
      } else {
        this.updateBooking();
      }
    }
  }

  public Delete(booking: BookingModel) {
    if (this.isRecurrentInstance) {
      this.recurrenceDialog(booking, true);
    } else {
      this.isSubmitting = true;
      this.getPatienteInfo(booking);
      let htmlContent = `¿Desea eliminar la cita de  <strong>${this.patienteDelete.patienteName}</strong> con <strong>${this.patienteDelete.doctorname}</strong> para el servicio <strong>${this.patienteDelete.servicesName}</strong> programada para el <strong>${this.patienteDelete.dateStart}</strong>? `;
      let data = {
        title: `Eliminar Cita`,
        htmlContent: htmlContent,
        yesBtnLabel: 'Continuar',
        noBtnLabel: 'Cancelar',
      };
      this.dialog
        .open(ConfirmDialogComponent, {
          data: data,
          width: '31.25rem',
          disableClose: true,
        })
        .afterClosed()
        .subscribe((r) => {
          if (r) {
            this.DeleteBooking(booking);
          } else {
            this.dialog.closeAll();
          }
        });
    }
  }

  //NOTA
  public createBooking(
    isRecurrentInstance: boolean = false,
    booking?: BookingModel
  ) {
    let newBooking = _.isNil(booking) === true ? this.booking : booking;
    let startDateTimes = moment(newBooking.startDateTime).toDate(); //moment(newBooking.startDateTime).utc(true).toDate();
    let endDateTime = moment(newBooking.endDateTime).toDate(); // moment(newBooking.endDateTime).utc(true).toDate();
    newBooking.startDateTime = startDateTimes;
    newBooking.endDateTime = endDateTime;
    if (_.isNil(newBooking.recurrencePattern) === false) {
      newBooking.isRecurring = true;
    }
    this.API.CreateBooking(newBooking).subscribe(() => {
      this.loadData(newBooking.clinicId);
      this.isSubmitting = false;
      this.booking = {} as BookingModel;
      this.onSidenavClose();
      this._snackBar.open('¡Listo!, se ha creado la cita.', 'OK ', {
        duration: 10000,
        horizontalPosition: 'left',
        verticalPosition: 'bottom',
        panelClass: 'success-dialog',
      });
      if (this.dataFromMatDialogEvent) {
        this.dialog.closeAll();
      }
    });
  }

  //NOTA
  public updateBooking(booking?: BookingModel) {
    let updateBooking = _.isNil(booking) === true ? this.booking : booking;
    updateBooking.startDateTime = moment(updateBooking.startDateTime).toDate(); //moment(updateBooking.startDateTime).utc(true).toDate();
    updateBooking.endDateTime = moment(updateBooking.endDateTime).toDate(); //moment(updateBooking.endDateTime).utc(true).toDate();
    this.API.UpdateBooking(updateBooking).subscribe(
      () => {
        this.loadData(updateBooking.clinicId);
        this.booking = {} as BookingModel;
        this.onSidenavClose();
        this._snackBar.open('¡Listo!, se ha actualizado la cita.', 'OK ', {
          duration: 10000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
          panelClass: 'success-dialog',
        });
        this.isSubmitting = false;
        if (this.dataFromMatDialogEvent) {
          this.dialog.closeAll();
        }
      },
      (f) => {
        window.alert('Lo sentimos, ha ocurrido un error.');
        console.error('ERROR AL crear la cita', f);
      }
    );
  }

  public onServiceFilterStringChange(filter: string) {
    if (!filter) {
      this.Services = deepCopy<BookingService[]>(this._unFiltered_Services);
    }
    filter = filter.toLowerCase();
    const filteredServices = new Array<BookingService>();
    for (let i = 0; i < this._unFiltered_Services.length; i++) {
      const option = this._unFiltered_Services[i];
      if (option.bookingServiceName.toLowerCase().indexOf(filter) >= 0) {
        filteredServices.push(deepCopy<BookingService>(option));
      }
    }
    this.Services = filteredServices;
  }

  public onStatusFilterStringChange(filter: string) {
    if (!filter) {
      this.Statuses = deepCopy<GetBookingStatus[]>(this._unFiltered_Statuses);
    }
    filter = filter.toLowerCase();
    const filteredStatuses = new Array<GetBookingStatus>();
    for (let i = 0; i < this._unFiltered_Statuses.length; i++) {
      const option = this._unFiltered_Statuses[i];
      if (option.bookingStatusName.toLowerCase().indexOf(filter) >= 0) {
        filteredStatuses.push(deepCopy<GetBookingStatus>(option));
      }
    }
    this.Statuses = filteredStatuses;
  }

  public onDoctorFilterStringChange(filter: string) {
    if (!filter) {
      this.Doctors = deepCopy<Doctor[]>(this._unFiltered_Doctors);
    }
    filter = filter.toLowerCase();
    const filteredDoctors = new Array<Doctor>();
    for (let i = 0; i < this._unFiltered_Doctors.length; i++) {
      const option = this._unFiltered_Doctors[i];
      if (
        (option.doctorId + ' - ' + option.doctorName)
          .toLowerCase()
          .indexOf(filter) >= 0
      ) {
        filteredDoctors.push(deepCopy<Doctor>(option));
      }
    }
    this.Doctors = filteredDoctors;
  }

  public ResetBookingForm = () => {
    this.booking = {} as BookingModel;
  };

  public Close() {
    this.dialog.closeAll();
  }

  public addServiceDurationInMinutes(bookingServiceId: number, date: Date) {
    const service = this.Services.find((s) => {
      return s.bookingServiceId === this.booking.bookingServiceId;
    });

    this.booking.durationInMinutes = service.serviceDurationInMinutes;

    let newDate = moment(date)
      .add(service.serviceDurationInMinutes, 'minutes')
      .toDate();
    return newDate;
  }

  public playReminderSetAudios(remset: ReminderSetDto) {
    const links = remset.audios
      .filter((a) => a.messageOrPath.indexOf('http') > -1)
      .map((e) => e.messageOrPath);
    this.PlayAudioChain(links);
  }
  PlayAudioChain(file_names: string[]) {
    if (file_names.length) {
      this.isPlayingAudio = true;
      const arg = {
        src: file_names[0],
        onend: () => {
          file_names.shift();
          if (file_names.length > 0) {
            this.isPlayingAudio = true;
            this.PlayAudioChain(file_names);
          } else {
            this.isPlayingAudio = false;
          }
        },
      };
      this.current_Audio_Hawl = new Howl(arg);
      this.current_Audio_Hawl.play();
    } else {
      this.isPlayingAudio = false;
    }
  }
  StopAudio() {
    this.current_Audio_Hawl.stop();
    this.isPlayingAudio = false;
  }
  public onAppointmentDateChange(appointmentDate: Date) {
    this.booking.startDateTime = this.roundToMinute(appointmentDate);
    let dateAddMinute = this.addServiceDurationInMinutes(
      this.booking.bookingServiceId,
      this.booking.startDateTime
    );
    this.booking.endDateTime = dateAddMinute;

    this.API.GetReminderSchedulesConfig(
      this.clinicId,
      appointmentDate
    ).subscribe((result) => {
      this.getReminderSchedulesConfigResults = result;
      this.onDoctorSelected(this.booking.doctorId);
    });
  }
  public onDoctorSelected(doctorId: number) {
    this.ReminderSets = new Array<ReminderSetDto>();
    for (let r of this.getReminderSchedulesConfigResults) {
      if (r.doctorId == doctorId) {
        let newReminderSe = {} as ReminderSetDto;
        newReminderSe.reminderSetId = Number(r.reminderSetName.split('-')[0]);
        newReminderSe.reminderSetName = r.reminderSetName;
        this.ReminderSets.push(newReminderSe);
      }
    }
  }
  onSidenavClose() {
    this.sidenavClose.emit('');
  }
  roundToMinute(timeRount: Date) {
    let minute = moment(timeRount).minute();
    if (minute >= 30) {
      return (timeRount = moment(
        moment(timeRount).set({ minute: 30 })
      ).toDate());
    }
    return (timeRount = moment(moment(timeRount).set({ minute: 0 })).toDate());
  }

  recurrenceDialog(booking: BookingModel, isDeleteRecurrence: boolean) {
    this.dialog
      .open(RecurrenceDialogComponent, {
        maxWidth: '37.5rem',
        panelClass: 'full-with-dialog',
        disableClose: false,
        hasBackdrop: true,
        autoFocus: true,
        data: { booking: booking, isDeleteRecurrence: isDeleteRecurrence },
      })
      .afterClosed()
      .subscribe((result) => {
        this.onSidenavClose();
      });
  }

  public DeleteBooking(booking: BookingModel) {
    this.API.DeleteBooking(booking.clinicId, booking.bookingID).subscribe(
      (r) => {
        this.loadData(booking.clinicId);
        this.onSidenavClose();
        this.dialog.closeAll();
        this._snackBar.open('¡Listo!, se ha eliminado la cita.', 'OK ', {
          duration: 10000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
          panelClass: 'success-dialog',
        });
      },
      (error) => {
        window.alert('Lo sentimos, ha ocurrido un error.');
        console.error('ERROR AL crear la cita', error);
      }
    );
  }

  //NOTA
  getPatienteInfo(booking: BookingModel) {
    this.patienteDelete = {} as BookingViewModel;
    let doctor = _.find(this.Doctors, { doctorId: booking.doctorId });
    let service = _.find(this.Services, {
      bookingServiceId: booking.bookingServiceId,
    });
    let patiente = _.find(this.Patients, {
      patientRecordId: booking.patientRecordId,
    });
    this.patienteDelete.doctorname = doctor?.doctorName;
    this.patienteDelete.servicesName = service?.bookingServiceName;
    this.patienteDelete.patienteName = `${patiente?.firstName} ${patiente?.firstLastName} ${patiente?.secondLastName}`;
    this.patienteDelete.dateStart = moment(booking.startDateTime).format(
      'dddd, D  MMMM YYYY, h:mm a'
    ); //moment.utc(booking.startDateTime).format('dddd, D  MMMM YYYY, h:mm a');
    this.patienteDelete.dateEnd = moment(booking.endDateTime).format(
      'dddd, D  MMMM YYYY, h:mm a'
    ); //moment.utc(booking.endDateTime).format('dddd, D  MMMM YYYY, h:mm a');
  }
}
