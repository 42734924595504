import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/_models';
import { AuthenticationService } from 'src/app/_services';

@Component({
  selector: 'app-bookings-dialog',
  templateUrl: './bookings-dialog.component.html',
  styleUrls: ['./bookings-dialog.component.scss'],
})
export class BookingsDialogComponent implements OnInit {
  constructor(
    private authenticationService: AuthenticationService,
    public dialog: MatDialog
  ) {}

  public User: User;
  isLoading = false;

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe((result) => {
      this.User = result;
    });
  }
}
