import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-support-channels-dialog',
  templateUrl: './support-channels-dialog.component.html',
  styleUrls: ['./support-channels-dialog.component.scss'],
})
/** support-channels-dialog component*/
export class SupportChannelsDialogComponent {
  /** support-channels-dialog ctor */
  constructor(public dialogRef: MatDialogRef<SupportChannelsDialogComponent>) {}

  public close() {
    this.dialogRef.close();
  }
}
