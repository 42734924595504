import { isDevelopmentEnv } from './helper-functions';

export const GetHospitalizadosReport = () => {
  return isDevelopmentEnv()
    ? 'TCM_Hospitalizados_DEV'
    : 'TCM_Hospitalizados_PROD';
};
export const GeAltasConCitasReport = () => {
  return isDevelopmentEnv()
    ? 'TCM_AltasConCitas_DEV'
    : 'TCM_AltasConCitas_PROD';
};
export const GeAltasSinCitasReport = () => {
  return isDevelopmentEnv()
    ? 'TCM_AltasSinCitas_DEV'
    : 'TCM_AltasSinCitas_PROD';
};

export const getTcmReportName = () =>
  isDevelopmentEnv() ? 'TCM_Records_DEV' : 'TCM_Records_PROD';
