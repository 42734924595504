import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { User } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ApiService } from 'src/app/api.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from 'src/app/confirm-dialog/confirm-dialog.component';
import PermissionSlugs from 'src/app/Constants/PermissionSlugs';
import { AdminViewAsClinicDTO } from 'src/app/dtos/AdminViewAsClinicDTO';
import { BookingService } from 'src/app/DTOS/Bookings/BookingServiceDTO';
import { Doctor } from 'src/app/Dtos/Doctors/DoctorDTO';
import { Speciality } from 'src/app/DTOS/Speciality/Speciality';
import {
  LoadingDialogComponent,
  LoadingDialogModel,
} from 'src/app/loading-dialog/loading-dialog.component';
import { PermissionSlugsService } from 'src/app/Services/PermissionSlugsService';

@Component({
  selector: 'app-bookings-doctors',
  templateUrl: './bookings-doctors.component.html',
  styleUrls: ['./bookings-doctors.component.scss'],
})
export class BookingsDoctorsComponent implements OnInit {
  public permissions: string[] = [];
  slidePanelOpen: Boolean = false;
  slideEditPanelOpen: Boolean = false;
  selectedAddTabIndex = new UntypedFormControl(0);
  sidePanelBookingDoctor: BookingService = {} as any;
  specialities: Speciality[];
  isSubmitting: Boolean = false;
  httpClient: HttpClient;
  baseUrl: string;
  public clinicId: number;
  isLoading: boolean;
  doctors: Doctor[];
  displayedColumns: string[] = ['doctorId', 'doctorName', 'Edit'];
  @Input() public ViewAs: AdminViewAsClinicDTO;
  @Input() public isStandalone: boolean;
  loadingRef: MatDialogRef<LoadingDialogComponent>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource: MatTableDataSource<Doctor>;
  public User: User;

  constructor(
    private permissionSlugsService: PermissionSlugsService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private API: ApiService,
    private _snackBar: MatSnackBar
  ) {
    this.permissionSlugsService.fetch();
    this.permissionSlugsService.permissionsListener.subscribe((r) => {
      this.permissions = r;
    });
    this.authenticationService.currentUser.subscribe((u) => (this.User = u));
  }

  ngOnInit(): void {
    this.LoadBookingDoctors();
  }

  public saveBookingDoctor() {
    this.isSubmitting = true;
    this.API.CreateBookingService({
      bookingServiceId: this.sidePanelBookingDoctor.bookingServiceId,
      bookingServiceName: this.sidePanelBookingDoctor.bookingServiceName,
      bookingServiceDescription:
        this.sidePanelBookingDoctor.bookingServiceDescription,
      serviceDurationInMinutes:
        this.sidePanelBookingDoctor.serviceDurationInMinutes,
      clinicId: this.sidePanelBookingDoctor.clinicId,
    }).subscribe(() => {
      this.LoadBookingDoctors();
      this.isSubmitting = false;
    });
  }

  public LoadBookingDoctors() {
    this.isLoading = true;
    const ClinicID = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;

    this.API.FetchDoctors(ClinicID).subscribe(
      (result) => {
        const res = result as Doctor[];
        this.dataSource = new MatTableDataSource<Doctor>(res);
        this.dataSource.paginator = this.paginator;
        this.doctors = res;
        this.isLoading = false;
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
      }
    );
  }

  public async updateBookingDoctor(_bookingDoctor: BookingService) {
    this.API.UpdateBookingService({
      bookingServiceId: _bookingDoctor.bookingServiceId,
      bookingServiceName: _bookingDoctor.bookingServiceName,
      bookingServiceDescription: _bookingDoctor.bookingServiceDescription,
      serviceDurationInMinutes: _bookingDoctor.serviceDurationInMinutes,
      clinicId: _bookingDoctor.clinicId,
    }).subscribe(
      () => {
        this._snackBar.open(
          '¡Listo!, se ha actualizado el Identificador Interno.',
          'OK ',
          {
            duration: 10000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: 'success-dialog',
          }
        );
        this.slideEditPanelOpen = false;
      },
      (f) => {
        window.alert('Lo sentimos, ha ocurrido un error.');
        console.error('ERROR AL actualizar Identificador Interno', f);
      }
    );
  }

  public ShowLoading() {
    const loading_dialog = this.dialog.open(LoadingDialogComponent, {
      maxWidth: '400px',
      data: new LoadingDialogModel('Cargando', '', false),
    });
    loading_dialog.disableClose = true;
    this.loadingRef = loading_dialog;
  }

  public hideLoading() {
    if (this.loadingRef) {
      this.loadingRef.close();
    }
  }

  private showError(error: any) {
    console.error('CITAMED ERROR', error);
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: new ConfirmDialogModel('Ha ocurrido un error', '', 'Okay', null),
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
