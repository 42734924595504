import * as _ from 'lodash';
import * as moment from 'moment-timezone';

import {
  BookingAppointments,
  BookingAppointmentsList,
} from '../DTOS/Bookings/BookingAppointmentsDTO';
import {
  BookingModel,
  EventExtendedProps,
  EventModel,
} from '../DTOS/Bookings/BookingDTO';
import {
  Calendar,
  CalendarApi,
  CalendarOptions,
  EventDropArg,
} from '@fullcalendar/core';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Injectable,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { LoadingDialogComponent } from '../loading-dialog/loading-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ApiService } from '../api.service';
import { AuthenticationService } from '../_services/authentication.service';
import { BookingsBookingFormComponent } from '../Pages/bookings-booking-form/bookings-booking-form.component';
import { BookingsDialogComponent } from '../_dialogs/bookings-dialog/bookings-dialog.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { FullCalendarElement } from '@fullcalendar/web-component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from '../_models';
import dayGridPlugin from '@fullcalendar/daygrid';
import esLocale from '@fullcalendar/core/locales/es';
import interactionPlugin, {
  EventResizeDoneArg,
} from '@fullcalendar/interaction'; // for dateClick
import timeGreedPlugin from '@fullcalendar/timegrid';

export type AgendaView = 'agenda' | 'calendario';

const dateToString = (date: Date) => moment(date).format('YYYY-MM-DD hh:mm A');
@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
})
export class AgendaComponent implements OnInit, OnChanges, AfterViewInit {
  constructor(
    private API: ApiService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {
    const name = Calendar.name;

    window.addEventListener('resize', () => {
      this.screenWidth = window.innerWidth;
    });

    moment.locale('es');
  }
  @Input() public view: AgendaView;
  public user: User;
  listEvents: BookingAppointmentsList[];
  isSubmitting: Boolean = false;
  isLoading: Boolean = false;
  showCloseIcon: Boolean = false;
  date = new Date();
  from: Date = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
  to: Date = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);
  public pageSize = 200;
  public currentPage = 1;
  public totalSize = 0;
  EventTarget: EventTarget;
  EventModel: EventModel = null;
  booking: BookingModel;
  @Input() public accountID: number;

  loadingRef: MatDialogRef<LoadingDialogComponent>;
  // references the #calendar in the template
  @ViewChild('calendar') calendarComponent: FullCalendarElement;

  @ViewChild(BookingsBookingFormComponent)
  _BookingFormComponent: BookingsBookingFormComponent;

  slidePanelOpen: Boolean = false;
  slideEditPanelOpen: Boolean = false;
  sidepanelBooking: BookingModel = {} as any;
  public screenWidth: number = window.innerWidth;
  public infoEvent: any;
  @Output() propagar = new EventEmitter<string>();

  events: BookingAppointments[];

  async loadAndReRenderEvents() {
    const events = await this.LoadListsEvents(this.accountID);
    this.events = events;
    this.updateFullCalendarEvents(
      events.map(this.mapBookingAppointmentToAgendaEventModel)
    );
  }

  //NOTA este modal recibe la fecha para mostrar en el SidePanel
  public openAddNewSidePanel(startDateTime: string, endDateTime?: string) {
    this.sidepanelBooking = {} as BookingModel;
    this.sidepanelBooking.startDateTime = moment(startDateTime).toDate();
    this.stringToDate(startDateTime);
    this.sidepanelBooking.endDateTime =
      _.isEmpty(endDateTime) == true ? null : moment(endDateTime).toDate();
    this.sidepanelBooking.clinicId = this.accountID;
    this.dialog
      .open(BookingsDialogComponent, {
        panelClass: 'full-with-dialog',
        disableClose: false,
        hasBackdrop: true,
        autoFocus: true,
        data: this.sidepanelBooking,
      })
      .afterClosed()
      .subscribe(() => {
        this.loadAndReRenderEvents();
      });
  }

  public openAddNewEditSidePanel(
    booking: BookingModel,
    isRecurrentInstance: boolean
  ) {
    let recurrenceDate = booking.startDateTime;

    this.showCloseIcon = false;
    this.sidepanelBooking = booking;
    this.slidePanelOpen = true;
    this._BookingFormComponent.loadData(
      booking.clinicId,
      isRecurrentInstance,
      recurrenceDate
    );
  }

  ngAfterViewInit(): void {
    const cal: Calendar = (this.calendarComponent as any).nativeElement
      ._calendar;

    this.Calendar = cal;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.accountID) {
      this.accountID = changes.accountID.currentValue;
      this.loadAndReRenderEvents();
    }
  }

  calendarOptions: CalendarOptions = {
    headerToolbar: {
      left: this.screenWidth > 840 ? 'prev,next today' : '',
      center: 'title',
      right:
        this.screenWidth > 840
          ? 'dayGridMonth,timeGridWeek,timeGridDay'
          : 'prev,next,dayGridMonth,timeGridWeek,timeGridDay',
    },

    select: (dateClickInfo) => {
      this.openAddNewSidePanel(
        dateClickInfo.start.toString(),
        dateClickInfo.end.toString()
      );
    },
    datesSet: (datesSetEvent) => {
      this.from = datesSetEvent.start;
      this.to = datesSetEvent.end;
      this.loadAndReRenderEvents();
    },

    //NOTA
    eventClick: (info) => {
      const extendedProps: EventExtendedProps = info?.event?.extendedProps;
      const parentId = Number(extendedProps._recurringParentBookingId || 0);
      // Recurrent instance
      if (parentId > 0) {
        this.FetchEventAndOpenEditForm(parentId, true, extendedProps);
      }
      // "Normal" non-recurrent instance
      else {
        let _bookingId = info.event.id;
        this.FetchEventAndOpenEditForm(Number(_bookingId), false, null);
      }
    },
    eventDrop: (info: EventDropArg) => {
      let { _recurringParentBookingId } = info?.event?.extendedProps;
      const parentId = Number(_recurringParentBookingId || 0);
      let _bookingId = parentId === 0 ? Number(info.event.id) : parentId;

      const event: EventModel = {
        title: info.event.title,
        id: String(_bookingId),
        start: dateToString(info.event.start),
        end: dateToString(info.event.end),
        color: '',
        description: '',
        backgroundColor: '',
      };

      this.infoEvent = info;

      this.DoEventUpdate(
        event,
        Number(event.id),
        info.oldEvent.start,
        info.oldEvent.end,
        parentId,
        true
      );
    },
    eventResize: (info: EventResizeDoneArg) => {
      let { _recurringParentBookingId } = info?.event?.extendedProps;
      const parentId = Number(_recurringParentBookingId || 0);
      let bookingId = parentId === 0 ? Number(info.event.id) : parentId;

      const event: EventModel = {
        title: info.event.title,
        id: String(bookingId),
        start: dateToString(info.event.start),
        end: dateToString(info.event.end),
        color: '',
        description: '',
        backgroundColor: '',
      };
      this.DoEventUpdate(
        event,
        Number(event.id),
        info.oldEvent.start,
        info.oldEvent.end,
        null,
        false
      );
    },
    events: [],

    initialView: this.screenWidth > 840 ? 'timeGridWeek' : 'timeGridDay',
    weekends: true,
    editable: true,
    dayHeaders: true,
    defaultAllDay: false,
    eventResizableFromStart: true,
    droppable: true,
    displayEventTime: true,
    displayEventEnd: true,
    locale: esLocale,
    plugins: [dayGridPlugin, timeGreedPlugin, interactionPlugin],
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    nowIndicator: true,
    timeZone: 'local',
    businessHours: [
      {
        daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
        startTime: '05:00',
        endTime: '22:00',
      },
    ],
    eventTimeFormat: {
      hour: 'numeric',
      minute: '2-digit',
      meridiem: 'short',
      hour12: true,
    },
    slotLabelFormat: (date) => moment(date.start).format('hh:mm a'),
    viewClassNames: (arg) => {
      return 'cmed-agenda-container';
    },
  };
  Calendar: Calendar;
  CalendarApi: CalendarApi;

  ngOnInit() {
    this.authenticationService.currentUser.subscribe((user) => {
      this.user = user;
      this.accountID = this.user.clinicId;
      this.loadAndReRenderEvents();
    });
  }

  //NOTA

  //NOTA
  private mapBookingAppointmentToAgendaEventModel(
    event: BookingAppointments
  ): EventModel {
    let end = moment(event.endDateTime).format();
    let start = moment(event.startDateTime).format();
    return {
      id: event.bookingID.toString(),
      start: start,
      end: end,
      title: `${event.patient.firstName}  ${event.patient.firstLastName} (${event.bookingServiceName})`,
      backgroundColor: event.bookingStatusColor || '#447f7c',
      _recurringParentBookingId: event._recurringParentBookingId,
      _eventStart: event.startDateTime,
      _eventEnd: event.endDateTime,
    };
  }

  public async LoadListsEvents(accountID): Promise<BookingAppointments[]> {
    this.isSubmitting = true;
    this.isLoading = true;
    this.currentPage = this.currentPage === 0 ? 1 : this.currentPage;

    const result = await this.API.GetBookingAppointments(
      accountID,
      this.currentPage,
      this.pageSize,
      null,
      this.from,
      this.to
    );

    const { items } = result;

    const agendaEvents: BookingAppointments[] = [];

    const compareDateFormat = (date: Date) =>
      moment(date).format('DD MMMM YYYY');

    items.forEach((event) => {
      if (event.recurrencePattern && event.bookingRecurrences) {
        const exceptions = (event.bookingRecurrencesExceptions || []).map(
          compareDateFormat
        );
        event.bookingRecurrences
          .filter((recurrence) => {
            return (
              exceptions.some(
                (exceptionDate) =>
                  exceptionDate === compareDateFormat(recurrence.startDateTime)
              ) == false
            );
          })
          .forEach((recurrence, idx) => {
            agendaEvents.push({
              ...event,
              bookingRecurrences: null,
              startDateTime: recurrence.startDateTime,
              endDateTime: recurrence.endDateTime,
              bookingID: `${event.bookingID}:recurrent-${idx}`,
              _recurringParentBookingId: Number(event.bookingID),
            });
          });
      } else {
        agendaEvents.push(event);
      }
    });

    return agendaEvents;
  }

  updateFullCalendarEvents(events: EventModel[]) {
    this.Calendar.removeAllEventSources();
    this.Calendar.addEventSource(events);
    this.Calendar.render();
  }

  public async FetchEventAndOpenEditForm(
    BookingID: number,
    isRecurrentInstance: boolean = false,
    extendedProps: EventExtendedProps
  ) {
    const data = {
      BookingID,
      isRecurrentInstance,
    };
    let clinicID = this.accountID ? this.accountID : this.user.clinicId;
    this.currentPage = this.currentPage === 0 ? 1 : this.currentPage;

    this.API.GetBooking(clinicID, BookingID).then(
      (result) => {
        if (isRecurrentInstance) {
          result.startDateTime = extendedProps._eventStart;
          result.endDateTime = extendedProps._eventEnd;
        }

        // result.startDateTime =
        //   isRecurrentInstance === false
        //     ? result.startDateTime
        //     : this.stringToDate(result.startDateTime.toString());
        // result.endDateTime =
        //   isRecurrentInstance === true
        //     ? result.endDateTime
        //     : this.stringToDate(result.endDateTime.toString());
        this.openAddNewEditSidePanel(result, isRecurrentInstance);
      },
      (error) => {
        console.error(error);
        window.alert('Lo sentimos, ha ocurrido un error.');
      }
    );
  }

  UpdateEventDrop(Booking: BookingModel) {
    this.API.UpdateBooking(Booking).subscribe(
      () => {
        this._snackBar.open('¡Listo!, se ha actualizado la cita.', 'OK ', {
          duration: 10000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
          panelClass: 'success-dialog',
        });
        this.loadAndReRenderEvents();
      },
      (f) => {
        window.alert('Lo sentimos, ha ocurrido un error.');
        console.error('ERROR AL algualizar la cita', f);
      }
    );
  }

  //NOTA
  public async DoEventUpdate(
    Event: EventModel,
    BookingID: number,
    oldStartEvent: Date,
    oldendEvent: Date,
    parentId?: number,
    isEventDrop?: boolean
  ) {
    let htmlContent = '';

    if (isEventDrop) {
      htmlContent = this.getEventUpdateConfirmMessage(Event, oldStartEvent);
    } else {
      htmlContent = this.getEventResizeConfirmMessage(
        Event,
        oldStartEvent,
        oldendEvent
      );
    }

    let data = {
      title: `Actualizar evento`,
      htmlContent: htmlContent,
      yesBtnLabel: 'Continuar',
      noBtnLabel: 'Cancelar',
    };

    this.dialog
      .open(ConfirmDialogComponent, {
        data: data,
        width: '31.25rem',
        disableClose: true,
      })
      .afterClosed()
      .subscribe(async (confirmado: Boolean) => {
        if (confirmado) {
          let clinicID = this.accountID ? this.accountID : this.user.clinicId;
          this.currentPage = this.currentPage === 0 ? 1 : this.currentPage;
          const result = await this.API.GetBooking(clinicID, BookingID);
          this.booking = result;
          this.booking.endDateTime = moment(Event.end).toDate();
          this.booking.startDateTime = moment(Event.start).toDate();
          this._BookingFormComponent.isRecurrentInstance =
            this.booking.isRecurring;
          if (this.booking.isRecurring) {
            this._BookingFormComponent.recurrenceDialog(this.booking, false);
          } else {
            this._BookingFormComponent.updateBooking(this.booking);
          }
          this._BookingFormComponent.loadData(clinicID);
        } else {
          this.dialog.closeAll();
          this.loadAndReRenderEvents();
        }
      });
  }

  mapEvent(info) {
    let { _recurringParentBookingId } = info?.event?.extendedProps;
    const parentId = Number(_recurringParentBookingId || 0);
    let _bookingId = parentId === 0 ? info.event.id : parentId;
    let { _def } = info.oldEvent;
    let { _instance } = info.event;
    let { range } = _instance;
    let end = range.end.toString();
    let start = range.start.toString();
    let updateEvent: EventModel = {
      title: _def.title,
      id: _bookingId,
      start: start,
      end: end,
      color: '',
      description: '',
      backgroundColor: '',
    };
    return updateEvent;
  }

  getEventUpdateConfirmMessage(Event: EventModel, oldStartDate: Date) {
    let message = '';
    let Eventstart = moment(Event.start).format('YYYY-MM-DD');
    let oldStartDateFormat = moment(oldStartDate).format('YYYY-MM-DD');
    let oldStartDateFormatHour = moment(oldStartDate).format('hh:m A');
    let EventstartHour = moment.utc(Event.start).format('hh:m A');
    let oldStartDateFormatDay = moment(oldStartDate).format(
      'dddd, D  MMMM YYYY, h:mm a'
    );
    let EventstartDay = moment
      .utc(Event.start)
      .format('dddd, D  MMMM YYYY, h:mm a');

    if (Eventstart == oldStartDateFormat) {
      message = `¿Desea cambiar la hora de la cita de <strong> ${Event.title}</strong>, de las <strong>${oldStartDateFormatHour}</strong>  a las  <strong> ${EventstartHour}</strong> ?`;
    } else {
      message = `¿Desea cambiar la fecha de la cita de <strong>${Event.title}</strong>, de <strong> ${oldStartDateFormatDay}</strong>  al <strong> ${EventstartDay} </strong>?`;
    }
    return message;
  }

  getEventResizeConfirmMessage(
    Event: EventModel,
    oldStartDate: Date,
    oldEndtDate: Date
  ) {
    let message = '';
    let oldStartDateFormatHour = moment(oldStartDate).format('hh:mm A');
    let oldEndDateFormatHour = moment(oldEndtDate).format('hh:mm A');
    let StartDateFormatHour = moment(Event.start).format('hh:mm A');
    let EndDateFormatHour = moment(Event.end).format('hh:mm A');

    message = `¿Desea ampliar la fecha de la cita de <strong>${Event.title}</strong>, rango actual <strong>  desde: ${oldStartDateFormatHour} hasta: ${oldEndDateFormatHour} 
    </strong>  nuevo rango <strong> desde: ${StartDateFormatHour} hasta ${EndDateFormatHour}</strong>?`;
    return message;
  }

  onSidenavClose() {
    this.slidePanelOpen = false;
    this.sidepanelBooking = {} as BookingModel;
    this.loadAndReRenderEvents();
  }

  private stringToDate(toUTCString: string) {
    return moment(toUTCString).toDate();
  }

  public onSchedulerEventEditClick(event: BookingAppointments) {
    if (event.recurrencePattern) {
      this.FetchEventAndOpenEditForm(
        Number(String(event.bookingID).split(':')[0]),
        true,
        {
          _eventStart: event.startDateTime,
          _eventEnd: event.endDateTime,
          _recurringParentBookingId: Number(event.bookingID),
        }
      );
    }
    // "Normal" non-recurrent instance
    else {
      this.FetchEventAndOpenEditForm(Number(event.bookingID), false, null);
    }
  }
}
