import { deepCopy } from 'src/app/helpers/DeepCopy';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';
import { ConfirmationService } from 'primeng/api';
import { ApiService } from 'src/app/api.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from 'src/app/confirm-dialog/confirm-dialog.component';
import { AdminViewAsClinicDTO } from 'src/app/dtos/AdminViewAsClinicDTO';
import {
  ApiUserDTO,
  IApiUsersClinic,
  AssociateDTO,
} from 'src/app/DTOS/ApiUsers/ApiUsersDTO';
import { User } from 'src/app/_models';
import { AuthenticationService } from 'src/app/_services';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ApiUsersListComponent } from '../api-users-list/api-users-list.component';

@Component({
  selector: 'app-api-users-details',
  templateUrl: './api-users-details.component.html',
  styleUrls: ['./api-users-details.component.scss'],
})
export class ApiUsersDetailsComponent implements OnInit {
  constructor(
    private authenticationService: AuthenticationService,
    private API: ApiService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private confirmationService: ConfirmationService
  ) {}

  @ViewChild('apiUserForm', { static: false }) public apiUserForm: NgForm;
  @Input() public ViewAs: AdminViewAsClinicDTO;
  @Input() public isStandalone: boolean;
  searchTerm = '';
  removable = true;
  public isLoading: boolean = false;
  public isEditing: boolean = true;
  public isUpdatingApiUser: boolean = false;
  public backup_apiUser: ApiUserDTO = {} as any;
  public User: User;
  public apiUserID: number;
  public Associate: AssociateDTO[];
  public clinics: number[] = [];
  @Input() public apiUserClinics: IApiUsersClinic[] = [];
  public _unFiltered_Associate: AssociateDTO[];
  ApiUsersListComponent: ApiUsersListComponent;

  @Input() public apiUser: ApiUserDTO = {
    apiUserId: 0,
    userLoginName: '',
    password: '',
    repeatPassword: '',
    isActive: true,
    associateId: null,
    clinicsIds: null,
  };

  ngOnInit(): void {
    this.User = this.authenticationService.currentUserValue;
    this.fetchAssociates();
  }

  public enableEdition = () => {
    this.isEditing = true;
    this.backup_apiUser = _.cloneDeep(this.apiUser);
  };

  public discardEditionChanges = () => {
    this.isEditing = false;
    this.apiUser = _.cloneDeep(this.backup_apiUser);
    this.backup_apiUser = {} as ApiUserDTO;
    this.apiUserClinics = [] as IApiUsersClinic[];
  };

  public save = () => {
    this.isLoading = true;
    if (this.apiUser?.apiUserId) {
      this.doUpdate();
    } else {
      this.doCreate();
    }
  };

  doCreate() {
    this.apiUser.clinicsIds = [];
    this.apiUserClinics.forEach((obj) => {
      this.apiUser.clinicsIds.push(Number(obj.clinicId));
    });
    this.API.CreateApiUser(this.apiUser).subscribe(
      (result) => {
        this.isLoading = false;
        this._snackBar.open(
          `¡Listo!, el paciente ${this.apiUser.userLoginName} ha sido creado. `,
          'OK ',
          {
            duration: 70000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: 'success-dialog',
          }
        );
        this.ClearInput();
      },
      (e) => {
        this.isLoading = false;
        this.isEditing = true;
        this.showError(e);
      }
    );
  }

  doUpdate() {
    this.API.UpdateApiUser(this.apiUser).subscribe(
      () => {
        this.isUpdatingApiUser = true;
        this.isLoading = false;
        this._snackBar.open(
          `¡Listo!, el paciente ${this.apiUser.userLoginName} ha sido actualizado. `,
          'OK ',
          {
            duration: 70000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: 'success-dialog',
          }
        );
      },
      (e) => {
        this.isUpdatingApiUser = false;
        this.isLoading = false;
        this.isEditing = true;
        this.showError(e);
      }
    );
  }

  public fetchAssociates() {
    this.API.GetAssociates().subscribe(
      (r) => {
        this.Associate = r as AssociateDTO[];
      },
      (e) => {
        this.isLoading = false;
        this.isEditing = true;
        this.showError(e);
      }
    );
  }

  public fetchApiUser = (apiUserId: number) => {
    this.isLoading = true;
    this.API.GetApiUser(apiUserId).subscribe(
      (r) => {
        this.apiUser = r as ApiUserDTO;
        this.isLoading = false;
        this.isEditing = true;
      },
      (e) => {
        this.isLoading = false;
        this.isEditing = true;
        this.showError(e);
      }
    );
  };
  public isSaveChangesDisabled(apiUserForm, apiUserClinicsLength) {
    if (
      this.apiUser.password == this.apiUser.repeatPassword &&
      apiUserForm.valid &&
      apiUserClinicsLength > 0
    ) {
      return false;
    } else {
      return true;
    }
  }

  viewClinics($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
    let clinics = {
      clinicId: this.ViewAs.clinicId,
      clinicName: this.ViewAs.clinicName,
    };
    const exist = _.some(this.apiUserClinics, clinics);
    if (!exist) {
      if (this.apiUser.apiUserId > 0) {
        this.confirmationService.confirm({
          target: event.target,
          message: '¿Estás segur@ de querer agregar acceso a esta clínica?',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'Si, continuar',
          rejectLabel: 'Cancelar',
          accept: () =>
            this.API.GrantAccessToClinic(
              this.apiUser.apiUserId,
              this.ViewAs.clinicId
            ).subscribe((r) => {
              this.apiUserClinics.push(clinics);
            }),
          reject: () => {
            this.confirmationService.close();
          },
        });
      } else {
        this.apiUserClinics.push(clinics);
      }
    } else {
      this.clinicaExiste();
    }
  }

  grantClinic(clinicId: number) {
    this.API.GrantAccessToClinic(this.apiUser.apiUserId, clinicId);
  }
  revokeClinic(clinicId: number) {
    this.API.RevokeAccessToClinic(this.apiUser.apiUserId, clinicId);
  }

  remove(tag: IApiUsersClinic, apiUserId: number): void {
    if (apiUserId > 0) {
      this.confirmationService.confirm({
        target: event.target,
        message: '¿Estás segur@ de querer eliminar este registro?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Si, eliminar',
        rejectLabel: 'Cancelar',
        accept: () =>
          this.API.RevokeAccessToClinic(
            this.apiUser.apiUserId,
            tag.clinicId
          ).subscribe((r) => {
            this.removeTag(tag);
          }),
        reject: () => {
          this.confirmationService.close();
        },
      });
    } else {
      this.removeTag(tag);
    }
  }

  removeTag(tag: IApiUsersClinic) {
    const index = this.apiUserClinics.indexOf(tag);
    if (index >= 0) {
      this.apiUserClinics.splice(index, 1);
    }
  }

  public onServiceFilterStringChange(filter: string) {
    if (!filter) {
      this.Associate = deepCopy<AssociateDTO[]>(this._unFiltered_Associate);
    }
    filter = filter.toLowerCase();
    const filteredServices = new Array<AssociateDTO>();
    for (let i = 0; i < this._unFiltered_Associate.length; i++) {
      const option = this._unFiltered_Associate[i];
      if (option.associateName.toLowerCase().indexOf(filter) >= 0) {
        filteredServices.push(deepCopy<AssociateDTO>(option));
      }
    }
    this.Associate = filteredServices;
  }

  ClearInput() {
    this.apiUserClinics = [];
    this.apiUser = {
      apiUserId: 0,
      userLoginName: '',
      password: '',
      repeatPassword: '',
      isActive: false,
      associateId: 0,
      clinicsIds: null,
    };
  }

  private showError(error: any) {
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: new ConfirmDialogModel('Ha ocurrido un error', '', 'Okay', null),
    });
  }
  private clinicaExiste() {
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: new ConfirmDialogModel(
        'Esta clínica ya existe agregada',
        '',
        'Okay',
        null
      ),
    });
  }
  public showOptions(event: MatCheckboxChange): void {
    this.apiUser.isActive = event.checked;
  }
}
