import { SelectionModel } from '@angular/cdk/collections';
import { Input, OnChanges, SimpleChanges } from '@angular/core';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Guid } from 'guid-typescript';
import {
  AssistanceStatusModel,
  DoctorAssistantTableItem,
} from 'src/app/DTOS/AssistanceStatus';
import { Doctor } from 'src/app/Dtos/Doctors/DoctorDTO';
import { User } from '../../_models';
import { AuthenticationService } from '../../_services';
import { ApiService } from '../../api.service';
import { AdminViewAsClinicDTO } from '../../dtos/AdminViewAsClinicDTO';
import { LoadingDialogComponent } from '../../loading-dialog/loading-dialog.component';
@Component({
  selector: 'app-doctor-assistance',
  templateUrl: './doctor-assistance.component.html',
  styleUrls: ['./doctor-assistance.component.scss'],
})
export class DoctorAssistanceComponent implements AfterViewInit, OnChanges {
  constructor(
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private API: ApiService,
    private authenticationService: AuthenticationService
  ) {}
  isLoading: boolean;
  loadingRef: MatDialogRef<LoadingDialogComponent>;
  baseUrl: string;
  public User: User;
  public doctors: Doctor[] = new Array();
  public assistanceStatuses: AssistanceStatusModel[] = new Array();
  dataSource: MatTableDataSource<DoctorAssistantTableItem> =
    new MatTableDataSource<DoctorAssistantTableItem>();
  public _isActive_updt: boolean;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  selection = new SelectionModel<DoctorAssistantTableItem>(true, []);
  displayedColumns: string[] = ['check', 'Doctor', 'status', 'template'];
  searchTearm: string;
  isLoadingDoctors: boolean;
  @Input() public ViewAs: AdminViewAsClinicDTO;
  @Input() public isStandalone: boolean;
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ViewAs?.previousValue !== changes.ViewAs?.currentValue) {
      this.FetchData();
    }
  }
  ngAfterViewInit() {
    this.dataSource.data = [];
    this.User = this.authenticationService.currentUserValue;
    this.FetchData();
    this.FetchAssistanceStatuses();
  }

  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
    this.FetchData();
  }

  private getClinicID = () => {
    if (this.isStandalone) {
      return this.ViewAs?.clinicId;
    }
    let clinicid = this.User.clinicId;
    if (this.ViewAs) {
      clinicid = this.ViewAs.clinicId;
    }
    return clinicid;
  };
  async FetchData() {
    await this.FetchConfig();
    await this.FetchDoctors();
    await this.FetchAssistanceStatuses();
  }
  public FetchDoctors() {
    this.isLoadingDoctors = true;
    const clinicid = this.getClinicID();
    if (!clinicid) {
      return;
    }
    return this.API.GetAllDoctors(clinicid).subscribe(
      (result) => {
        const res = result as Doctor[];
        this.doctors = res;
        this.isLoadingDoctors = false;
      },
      (error) => console.error(error)
    );
  }

  public FetchConfig() {
    this.isLoadingDoctors = true;
    const clinicid = this.getClinicID();
    if (!clinicid) {
      return;
    }
    return this.API.GetClinicAssistanceConfig(clinicid).subscribe(
      (result) => {
        const res = result as DoctorAssistantTableItem[];
        this.dataSource.data = res;
      },
      (error) => console.error(error)
    );
  }

  public FetchAssistanceStatuses() {
    return this.API.GetAssistanceStatuses().subscribe(
      (result) => {
        const res = result as AssistanceStatusModel[];
        this.assistanceStatuses = res;
      },
      (error) => console.error(error)
    );
  }

  public AddNew = () => {
    const guid = Guid.create().toString();
    const dummyRecord = {
      isNewItem: true,
      guid,
    } as DoctorAssistantTableItem;
    this.dataSource.data = [dummyRecord, ...this.dataSource.data];
  };
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource?.data?.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource?.data.forEach((row) => this.selection.select(row));
  }
  checkboxLabel(row?: DoctorAssistantTableItem): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row `;
  }

  private deleteNonCreatedSelectedItems() {
    const newElements = this.selection.selected
      .filter((c) => c.guid)
      .map((c) => c.guid);

    const data = this.dataSource.data.filter(
      (c) => newElements.includes(c.guid) === false
    );

    this.dataSource.data = data;
  }
  public async runDelete() {
    this.deleteNonCreatedSelectedItems();

    const existingElementsToDelete = this.selection.selected.filter(
      (c) => c.clinicDoctorAssistanceStatusId
    );

    const requests = existingElementsToDelete.map((record) => {
      return this.deleteRecord(record.clinicDoctorAssistanceStatusId);
    });

    Promise.all(requests).then(this.FetchData);

    this.selection.clear();
  }

  private createNonExistentRecords() {
    const items = this.selection.selected.filter((c) => c.guid);

    const requests = items.map((i) => {
      return this.API.CreateNewAssistanceStatusConfig({
        ...i,
        clinicID: this.getClinicID(),
      })
        .toPromise()
        .then(
          (e) => {},
          () => {
            const doctor = this.doctors.find((d) => d.doctorId === i.doctorId);
            if (!doctor) {
              this._snackBar.open(`Proceedor invalido.`);
            }
            const status = this.assistanceStatuses.find(
              (d) => d.assistanceStatusID === i.assistanceStatusID
            );
            if (!status) {
              this._snackBar.open(`Estatus invalido.`);
            }
            this._snackBar.open(
              `Ha ocurrido un error registrando la configuracion para ${doctor.doctorName} y el status ${status.assistanceStatusName}`
            );
          }
        );
    });
    Promise.all(requests).then(() => this.FetchData());
  }
  public runSave() {
    this.createNonExistentRecords();
    this.selection.clear();
  }

  private async deleteRecord(clinicDoctorAssistanceStatusId: number) {
    return this.API.DeleteAssistanceStatusConfig(clinicDoctorAssistanceStatusId)
      .toPromise()
      .then(
        () => {
          this.isLoading = false;
          this._snackBar.open('¡Listo!, se elimino la configuracion.', 'OK ', {
            duration: 10000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: 'success-dialog',
          });
          this.FetchData();
        },
        (f) => {
          this.isLoading = false;
          window.alert('Lo sentimos, ha ocurrido un error.');
          console.error('ERROR AL actualizar PERMISO', f);
        }
      );
  }
}
