import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  ViewChild,
} from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';
import { ConfirmationService } from 'primeng/api';
import { User } from '../../_models';
import { AuthenticationService } from '../../_services';
import { ApiService } from '../../api.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from '../../confirm-dialog/confirm-dialog.component';
import { AdminViewAsClinicDTO } from '../../dtos/AdminViewAsClinicDTO';
import {
  VirtualAssistantDoctorsDTO,
  VirtualAssistantDoctorTagsDTO,
  VirtualAssistantDoctorTagsResultDTO,
} from '../../DTOS/VA_Configurations/VA_Configurations';
import {
  LoadingDialogComponent,
  LoadingDialogModel,
} from '../../loading-dialog/loading-dialog.component';

@Component({
  selector: 'app-va-doctor-tags',
  templateUrl: './va-doctor-tags.component.html',
  styleUrls: ['./va-doctor-tags.component.scss'],
})
export class VaDoctorTagsComponent implements OnChanges, AfterViewInit {
  @Input() public ViewAs: AdminViewAsClinicDTO;
  @Input() public sidepanelItems: VirtualAssistantDoctorsDTO;

  loadingRef: MatDialogRef<LoadingDialogComponent>;
  @Input() public isStandalone: boolean;
  public User: User;
  isSubmitting: Boolean = false;
  isLoading: Boolean = false;
  public pageSize = 10;
  public currentPage = 1;
  public totalSize = 0;
  searchTearm = '';
  public dataSource: any;
  listItems: VirtualAssistantDoctorTagsDTO[];
  ListdorctorTags: VirtualAssistantDoctorTagsResultDTO;
  dorctorTag: VirtualAssistantDoctorTagsDTO = {} as any;
  ListdorctorTag: VirtualAssistantDoctorTagsDTO[] = [];
  selectable = true;
  removable = true;
  addOnBlur = true;
  _dataLoading = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  listDoctorTag: VirtualAssistantDoctorTagsDTO[];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private API: ApiService,
    private confirmationService: ConfirmationService,
    private _snackBar: MatSnackBar
  ) {}

  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
  }

  ngAfterViewInit() {
    this.User = this.authenticationService.currentUserValue;
    this._dataLoading = false;
  }

  ngOnChanges(): void {
    this.User = this.authenticationService.currentUserValue;
    const s = this.sidepanelItems;
    this.ListdorctorTag = _.cloneDeep(this.sidepanelItems.tags);
    this._dataLoading = false;
  }

  public AddTags(doctorTag: VirtualAssistantDoctorTagsDTO) {
    this.isSubmitting = true;
    this.isLoading = true;
    const exist = _.some(this.ListdorctorTag, doctorTag);
    if (!exist) {
      const accountID = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
      this.API.CreateDoctorTagsVirtualAssistant(
        this.sidepanelItems.accountID,
        doctorTag
      ).subscribe(() => {
        this.isSubmitting = false;
        this.isLoading = false;

        this._snackBar.open(
          `¡Listo!, la Doctor creado correctamente. `,
          'OK ',
          {
            duration: 70000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: 'success-dialog',
          }
        );
      });

      this.ListdorctorTag.push(doctorTag);
    }
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      const srs = this.sidepanelItems;

      const newTag = {} as VirtualAssistantDoctorTagsDTO;
      newTag.tag = value;
      newTag.doctorID = this.sidepanelItems.doctorID;
      newTag.accountID = this.sidepanelItems.accountID;
      this.AddTags(newTag);
    }
    event.chipInput!.clear();
  }

  remove(tag: VirtualAssistantDoctorTagsDTO): void {
    this.confirmationService.confirm({
      target: event.target,
      message: '¿Estás segur@ de querer eliminar este registro?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si, eliminar',
      rejectLabel: 'Cancelar',
      accept: () =>
        this.API.DeleteSDoctorTagsVirtualAssistant(
          this.sidepanelItems.accountID,
          tag.doctorTagID
        ).subscribe((r) => {
          const index = this.ListdorctorTag.indexOf(tag);

          if (index >= 0) {
            this.ListdorctorTag.splice(index, 1);
          }
        }),
      reject: () => {
        this.confirmationService.close();
      },
    });
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
  }

  private showError(error: any) {
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: new ConfirmDialogModel('Ha ocurrido un error', '', 'Okay', null),
    });
  }
}
