import { Doctor } from '../doctors/DoctorDTO';

export class NoCallDateCreateDTO {
  clinicId: number;
  NoCallDate: Date;
  NoCallDate_To: Date;
  DoctorsIds: number[];
}
export interface NoCallDateGetDTO {
  noCallDateId: number;
  noCallDate: string;
  comments: string;
  createDate: Date;
  doctor: Doctor;
}
