import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ApiService } from 'src/app/api.service';
import {
  AccountStat,
  AccountStatItem,
} from 'src/app/DTOS/Company/AccountStatItem';
import { ClinicStatByStatusGroupedByClinic } from 'src/app/DTOS/Reports/ClinicStatByStatus';

@Component({
  selector: 'app-account-stats-report',
  templateUrl: './account-stats-report.component.html',
  styleUrls: ['./account-stats-report.component.scss'],
})
export class AccountStatsReportComponent implements OnInit {
  constructor(private API: ApiService) {}

  public accountsWithWarning = 0;
  public accountsWithError = 0;
  public accountsSevereError = 0;
  public accountsThatHaveNeverSynced = 0;
  public callDate: Date = new Date();
  public accountsThatUsePrinter = 0;
  public isLoadingAccountStats = true;
  public isLoadingStatusStats = true;
  public statsByCallStatus: {
    statusData: ClinicStatByStatusGroupedByClinic[];
    chartDataPerClinic: {
      [clinicId: number]: {
        labels: string[];
        chartData: { data: number[]; label: string };
      };
    };
  } = {
    statusData: [],
    chartDataPerClinic: {},
  };
  public stats: AccountStat;
  public statsBackup: AccountStat;
  ngOnInit() {
    this.LoadStats();
    this.LoadCallStatusStats(new Date());
  }

  public getPct(amount: number, total: number) {
    return (100 * (amount / total)).toFixed();
  }
  public resetStats() {
    this.stats = _.cloneDeep(this.statsBackup);
  }
  public onCounterClicked(
    counter: 'printer' | 'warning' | 'errors' | 'severe' | 'neverSynced'
  ) {
    switch (counter) {
      case 'printer':
        this.stats.accountStatsResult =
          this.statsBackup.accountStatsResult.filter((i) => i.isPrinterUser);
        break;
      case 'warning':
        this.stats.accountStatsResult =
          this.statsBackup.accountStatsResult.filter(
            (i) => i._datesDiff > 4 && i._datesDiff <= 6
          );
        break;
      case 'errors':
        this.stats.accountStatsResult =
          this.statsBackup.accountStatsResult.filter(
            (i) => i._datesDiff > 6 && i._datesDiff < 20
          );
        break;
      case 'severe':
        this.stats.accountStatsResult =
          this.statsBackup.accountStatsResult.filter(
            (i) => i.lastSyncDate && i._datesDiff >= 20
          );
        break;
      case 'neverSynced':
        this.stats.accountStatsResult =
          this.statsBackup.accountStatsResult.filter((c) => !c.lastSyncDate);
        break;

      default:
        break;
    }
  }

  public LoadStats() {
    this.API.GetAccountStats().subscribe((r) => {
      const res = r as AccountStat;

      const items: AccountStatItem[] = _.orderBy(
        res.accountStatsResult,
        'lastSyncDate',
        ['desc']
      );

      const itemsWithoutSyncDate: AccountStatItem[] = [];
      const itemsWithSyncDate: AccountStatItem[] = [];

      const today = moment();

      items.forEach((i) => {
        if (i.isPrinterUser) {
          this.accountsThatUsePrinter++;
        }

        i._formatted_lastSyncDate = this.dateFormat(i.lastSyncDate);

        if (!!!i.lastSyncDate) {
          itemsWithoutSyncDate.push(i);
        } else {
          let dateDef = '';

          const daysDiff = today.diff(moment(i.lastSyncDate), 'days');
          i._datesDiff = daysDiff;

          if (daysDiff === 0) {
            dateDef = 'Hoy';
          } else if (daysDiff === 1) {
            dateDef = 'ayer';
          } else {
            dateDef = 'Hace ' + daysDiff + ' dias';
          }
          i._todays_date_Diff = dateDef;

          if (!!!i.lastSyncDate || i._datesDiff >= 20) {
            i._hasSeverError = true;
            this.accountsSevereError++;
          } else if (i._datesDiff > 6 && i._datesDiff < 20) {
            i._hasError = true;
            this.accountsWithError++;
          } else if (i._datesDiff > 4 && i._datesDiff <= 6) {
            i._hasWarning = true;
            this.accountsWithWarning++;
          }

          itemsWithSyncDate.push(i);
        }
      });

      this.accountsThatHaveNeverSynced = itemsWithoutSyncDate.length;

      res.accountStatsResult = [...itemsWithSyncDate, ...itemsWithoutSyncDate];

      this.stats = res;
      this.statsBackup = _.cloneDeep(res);
      this.isLoadingAccountStats = false;
    });
  }

  public LoadCallStatusStats(date: Date) {
    this.statsByCallStatus = {
      statusData: [],
      chartDataPerClinic: {},
    };
    this.isLoadingStatusStats = true;
    this.API.GetAccountStatsByCallStatus(date).subscribe((res) => {
      const groupedElements = _.groupBy(res, 'clinicid');

      Object.keys(groupedElements).map((clinicID) => {
        const clinicid = Number(clinicID);
        const clinicStat = _.orderBy(
          groupedElements[clinicID],
          ['appointmentsCount'],
          'desc'
        );
        this.statsByCallStatus.statusData.push({
          clinicid,
          clinicName: groupedElements[clinicID][0].clinicName,
          stat: clinicStat,
        });
        const statusArray = [
          ...new Set([...clinicStat.map((s) => s.callStatusId)]),
        ];
        const chartData = statusArray.map((statusId) => ({
          data: [
            clinicStat.find((d) => d.callStatusId === statusId)
              .appointmentsCount,
          ],
          label: statusId,
        }));
        this.statsByCallStatus.chartDataPerClinic[clinicID] = {
          labels: ['Status'],
          data: chartData,
        };
      });
      this.isLoadingStatusStats = false;
    });
  }

  public dateFormat = (date: any) =>
    date ? moment(date).format('YYYY-MM-DD') : 'Nunca';
}
