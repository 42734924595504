import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { ConfirmationService } from 'primeng/api';
import { User } from 'src/app/_models';
import { AuthenticationService } from 'src/app/_services';
import { ApiService } from 'src/app/api.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from 'src/app/confirm-dialog/confirm-dialog.component';
import { AdminViewAsClinicDTO } from 'src/app/dtos/AdminViewAsClinicDTO';
import {
  ClinicConfigDTO,
  ClinicDetails,
} from 'src/app/DTOS/Clinic/clinic-details';
import { SearchCompanyDTO } from 'src/app/DTOS/Company/SearchCompanyDTO';
import { EHRGetDTO } from 'src/app/DTOS/Ehrs/EhrGetDTO';
import { MatTableDataSource } from '@angular/material/table';
import {
  WhiteListedPhoneNumbers,
  WhiteListedPhoneNumbersList,
} from 'src/app/DTOS/Clinic/WhiteListedPhoneNumbersDTO';
import { MatPaginator } from '@angular/material/paginator';
import {
  ClinicNewWhitelistedPhoneDialogComponent,
  WhiteListedPhoneDialogData,
} from 'src/app/_dialogs/clinic-new-whitelisted-phone-dialog/clinic-new-whitelisted-phone-dialog.component';
import { deepCopy } from 'src/app/helpers/DeepCopy';

@Component({
  selector: 'app-clinic-details',
  templateUrl: './clinic-details.component.html',
  styleUrls: ['./clinic-details.component.scss'],
})
export class ClinicDetailsComponent implements OnInit {
  constructor(
    private authenticationService: AuthenticationService,
    private API: ApiService,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private router: Router,
    private confirmationService: ConfirmationService
  ) {}

  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild('clinicForm', { static: false }) public clinicForm: NgForm;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public isLoading = false;
  public clinic: ClinicDetails = {} as any;
  public User: User;
  public clinicID: number;
  public backup_clinic: ClinicDetails = {} as any;
  public ViewAs: AdminViewAsClinicDTO;
  public isEditing = false;
  public selectedCompany: SearchCompanyDTO;
  public companies: SearchCompanyDTO[] = [];
  public Ehrs: EHRGetDTO[];
  public _unFiltered_Ehrs: EHRGetDTO[];
  public WhiteListedPhones: WhiteListedPhoneNumbersList;
  public isWhiteListed = false;
  public page = 1;
  public pageSize = 10;
  searchTerm = '';
  dataSource: MatTableDataSource<WhiteListedPhoneNumbers>;
  public currentPage = 1;
  public totalSize = 0;
  listItems: WhiteListedPhoneNumbers[];
  whiteListedPhoneNumber = {} as WhiteListedPhoneNumbers;
  displayedColumns: string[] = [
    'whiteListedPhoneNumberId',
    'phoneNumber',
    'username',
    'Delete',
  ];

  ngOnInit(): void {
    this.User = this.authenticationService.currentUserValue;

    const clinicid = this.route.snapshot.params.clinicId;
    if (!clinicid) {
      this.isEditing = true;
      this.onCompanyFilterChange('');
    } else {
      this.clinicID = clinicid;
      this.fetchClinic(clinicid);
    }

    this.FetchEhrs();
  }

  public onCompanySelectChange() {
    this.dialog
      .open(ConfirmDialogComponent, {
        maxWidth: '600px',
        data: new ConfirmDialogModel(
          'Auto completar información de la clínica',
          `¿Completamos el formulario de la clínica con la
        información de la cuenta de facturación (compañia)? `,
          'Si',
          'No'
        ),
      })
      .afterClosed()
      .subscribe((r) => {
        if (r) {
          const compny = _.cloneDeep(this.selectedCompany);

          this.clinic = {
            clinicName: compny.companyName,
            phoneNumber: compny.phoneNumber,
            address1: compny.address1,
            address2: compny.address2 ? compny.address2 : '',
            city: compny.city,
            mainContact: compny.mainContact,
            emailAddress: compny.emailAddress,
            state: compny.state,
            postalCode: compny.postalCode,
            companyId: compny.companyId,
            faxNumber: '',
            viewAsUserID: 0,
            clinicId: 0,
            electronicCalendarId: 0,
            accountStatus: 2,
          };
        }
      });
  }

  public onCompanyFilterChange(searchTerm: string) {
    this.API.SearchCompany(searchTerm).subscribe((r) => {
      this.companies = r as SearchCompanyDTO[];
    });
  }

  public enableEdition = () => {
    this.isEditing = true;
    this.backup_clinic = _.cloneDeep(this.clinic);
  };

  public discardEditionChanges = () => {
    this.isEditing = false;
    this.clinic = _.cloneDeep(this.backup_clinic);
    this.backup_clinic = {} as any;
  };

  public save = () => {
    this.isEditing = false;
    this.isLoading = true;
    if (this.clinic?.clinicId) {
      this.doUpdate();
    } else {
      this.doCreate();
    }
  };

  doCreate() {
    this.API.CreateClinic(this.clinic).subscribe(
      (clinicId) => {
        this.isLoading = false;
        this._snackBar.open(
          `¡Listo!, la clínica ${this.clinic.clinicName} ha sido creada. `,
          'OK ',
          {
            duration: 70000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: 'success-dialog',
          }
        );
        this.router.navigate(['/clinic', clinicId]);
      },
      (e) => {
        this.isLoading = false;
        this.isEditing = true;
        this.showError(e);
      }
    );
  }

  doUpdate() {
    this.API.UpdateClinic(this.clinic).subscribe(
      () => {
        this.isLoading = false;
        this._snackBar.open(
          `¡Listo!, la clínica ${this.clinic.clinicName} ha sido actualizada. `,
          'OK ',
          {
            duration: 70000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: 'success-dialog',
          }
        );
        this.CheckWhiteListed();
      },
      (e) => {
        this.isLoading = false;
        this.isEditing = true;
        this.showError(e);
      }
    );
  }

  private showError(error: any) {
    console.error('CITAMED ERROR', error);
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: new ConfirmDialogModel('Ha ocurrido un error', '', 'Okay', null),
    });
  }

  public fetchClinic = (clinicId: number) => {
    this.isLoading = true;
    this.API.GetClinic(clinicId).subscribe((r) => {
      this.clinic = r as ClinicDetails;
      this.isLoading = false;
      this.ViewAs = {
        clinicId: this.clinic.clinicId,
        clinicName: this.clinic.clinicName,
        companyId: this.clinic.companyId,
        viewAsUserID: this.clinic.viewAsUserID,
        isActive: true,
      };
      this.CheckWhiteListed();
    });
  };

  public FetchEhrs() {
    this.isLoading = true;
    this.API.GetAllEhrs().subscribe(
      (r) => {
        this._unFiltered_Ehrs = r as EHRGetDTO[];
        this.Ehrs = deepCopy<EHRGetDTO[]>(this._unFiltered_Ehrs);
        this.isLoading = false;
      },
      (e) => {
        this.isLoading = false;
        this.isEditing = true;
        this.showError(e);
      }
    );
  }

  public UpdateEHR(event) {
    if (this.User?.isAdmin === false) {
      return;
    }
    const accountID = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    const clinicConfig = {
      ClinicID: accountID,
      DefaultElectronicCalendarId: event.value,
    };
    this.dialog
      .open(ConfirmDialogComponent, {
        maxWidth: '600px',
        data: new ConfirmDialogModel(
          'Actualizar EHR',
          '¿Estás segur@ de querer cambiar el EHR de esta clinica?',
          'Si',
          'No'
        ),
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.API.UpdateEHR(clinicConfig).subscribe((r) => {
            this._snackBar.open(`¡Listo!, el EHR ha sido actualizado.`, 'OK ', {
              duration: 70000,
              horizontalPosition: 'left',
              verticalPosition: 'bottom',
              panelClass: 'success-dialog',
            });
          });
        }
        this.dialog = null;
      });
  }

  public CheckWhiteListed() {
    if (this.clinic.accountStatus == 2) {
      this.isWhiteListed = true;
      this.GetWhiteListedClinicPhones();
    } else {
      this.isWhiteListed = false;
    }
  }

  public GetWhiteListedClinicPhones() {
    this.isLoading = true;
    this.API.GetAllWhiteListClinic(
      this.clinicID,
      this.page,
      this.pageSize,
      this.searchTerm
    ).subscribe(
      (r) => {
        this.dataSource = new MatTableDataSource<WhiteListedPhoneNumbers>(
          r.items
        );
        this.WhiteListedPhones = r;
        this.dataSource.paginator = this.paginator;
        this.totalSize = this.WhiteListedPhones.totalCount;
        this.pageSize = this.WhiteListedPhones.pageSize;
        this.currentPage = this.WhiteListedPhones.pageIndex;
        this.listItems = this.WhiteListedPhones.items;
        this.isLoading = false;
      },
      (e) => {
        this.isLoading = false;
        this.isEditing = true;
        this.showError(e);
      }
    );
  }

  public deleteWhiteListedPhoneNumber(
    event: Event,
    whiteListedPhoneNumberId: number
  ) {
    if (this.User?.isAdmin === false) {
      return;
    }

    this.confirmationService.confirm({
      target: event.target,
      message: '¿Estás segur@ de querer eliminar este telefono?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si, eliminar',
      rejectLabel: 'Cancelar',
      accept: () =>
        this.API.DeleteWhiteListedPhone(whiteListedPhoneNumberId).subscribe(
          () => {
            this.GetWhiteListedClinicPhones();
          },
          (f) => {
            window.alert('Lo sentimos, ha ocurrido un error.');
            console.error('ERROR AL eliminar doctor', f);
          }
        ),
      reject: () => {
        this.confirmationService.close();
      },
    });
  }

  OpenWhiteListPhoneDialog() {
    this.whiteListedPhoneNumber.clinicID = this.clinicID;
    this.whiteListedPhoneNumber.companyID = this.clinicID;
    const data = {} as WhiteListedPhoneDialogData;
    data.whiteListedPhoneNumber = this.whiteListedPhoneNumber;
    this.dialog
      .open(ClinicNewWhitelistedPhoneDialogComponent, {
        data,
        width: '20%',
        disableClose: false,
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        const result = dialogResult as boolean;
        if (result) {
          this._snackBar.open('¡Listo!, se ha creado el numero.', 'ok ', {
            duration: 8000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: 'success-dialog',
          });
          this.GetWhiteListedClinicPhones();
        }
      });
  }

  public onEHRFilterStringChange(filter: string) {
    if (!filter) {
      this.Ehrs = deepCopy<EHRGetDTO[]>(this._unFiltered_Ehrs);
    }
    filter = filter.toLowerCase();
    const filteredEhrs = new Array<EHRGetDTO>();
    for (let i = 0; i < this._unFiltered_Ehrs.length; i++) {
      const option = this._unFiltered_Ehrs[i];
      if (option.electronicCalendarName.toLowerCase().indexOf(filter) >= 0) {
        filteredEhrs.push(deepCopy<EHRGetDTO>(option));
      }
    }
    this.Ehrs = filteredEhrs;
  }
}
