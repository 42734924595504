import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
export class UserCreateDTO {
  password: string;
  repeatPassword: string;
}

@Component({
  selector: 'app-password-recover',
  templateUrl: './password-recover.component.html',
  styleUrls: ['./password-recover.component.scss'],
})
/** password-recover component*/
export class PasswordRecoverComponent implements OnInit {
  /** password-recover ctor */
  password_strength = 'La contraseña es débil';
  pswrdStrengthColor = 'Red';
  error: '';
  token: '';
  reqid: '';
  submitted = false;
  loading = false;

  httpClient: HttpClient;
  isPasswordValid = true;
  public NewUser = new UserCreateDTO();
  constructor(
    private route: ActivatedRoute,
    http: HttpClient,
    private _snackBar: MatSnackBar,
    private router: Router
  ) {
    this.httpClient = http;
  }
  ngOnInit() {
    // get return url from route parameters or default to '/'
    this.token = this.route.snapshot.queryParams['tkn'];
    this.reqid = this.route.snapshot.queryParams['req'];
  }
  public CheckPasswordStrength(password) {
    if (!password) {
      return;
    }
    this.isPasswordValid = false;
    this.password_strength = '';

    this.pswrdStrengthColor = '';
    if (password.length === 0) {
      return;
    }

    // Regular Expressions
    const regex = new Array();
    regex.push('[A-Z]'); // For Uppercase Alphabet
    regex.push('[a-z]'); // For Lowercase Alphabet
    regex.push('[0-9]'); // For Numeric Digits
    regex.push('[$@$!%*#?&]'); // For Special Characters

    let passed = 0;

    // Validation for each Regular Expression
    for (let i = 0; i < regex.length; i++) {
      if (new RegExp(regex[i]).test(password)) {
        passed++;
      }
    }

    // Validation for Length of Password
    if (passed > 2 && password.length > 8) {
      passed++;
    }

    // Display of Status
    let color = '';
    let passwordStrength = '';
    switch (passed) {
      case 0:
        break;
      case 1:
        passwordStrength = 'La contraseña es muy débil.';
        color = 'Red';
        this.isPasswordValid = false;
        break;
      case 2:
        passwordStrength = 'La contraseña es débil.';
        color = 'darkorange';
        this.isPasswordValid = true;
        break;
      case 3:
        break;
      case 4:
        passwordStrength = 'La contraseña es segura.';
        this.isPasswordValid = true;
        color = 'Green';
        break;
      case 5:
        this.isPasswordValid = true;
        passwordStrength = 'La contraseña es muy segura.';
        color = 'darkgreen';
        break;
    }
    this.password_strength = passwordStrength;
    this.pswrdStrengthColor = color;
  }

  private onErrorOcurred(error: any) {
    const snackBar = this._snackBar.open(
      '¡Oops! Ha ocurrido un error :(',
      'ok ',
      {
        duration: 8000,
        horizontalPosition: 'left',
        verticalPosition: 'bottom',
        panelClass: 'success-dialog',
      }
    );

    this.submitted = false;
    this.loading = false;
    console.error(error);
  }
  moveToLogin() {
    this.router.navigate(['/']);
  }
  onSubmit() {
    this.submitted = true;
    this.loading = true;
    try {
      const dto = {
        Password: this.NewUser.password,
        token: this.token,
        ReqId: this.reqid,
      };
      this.httpClient.post('PasswordReset/ResetPassword', dto).subscribe(
        (result) => {
          this._snackBar.open(
            '¡Listo! Tu contraseña ha sido restaurada.',
            'ok ',
            {
              duration: 8000,
              horizontalPosition: 'left',
              verticalPosition: 'bottom',
              panelClass: 'success-dialog',
            }
          );
          this.loading = false;
          this.moveToLogin();
        },
        (e) => {
          this.onErrorOcurred(e);
          this.error = e;
        }
      );
    } catch (e) {
      this.onErrorOcurred(e);
    }
  }
  // Keydown Events delete - backspace
  @HostListener('document:keydown.delete', ['$event'])
  onDeleteComponent(event: KeyboardEvent) {
    this.CheckPasswordStrength(this.NewUser.password);
  }
  @HostListener('document:keydown.backspace', ['$event'])
  onBackspaceComponent(event: KeyboardEvent) {
    this.CheckPasswordStrength(this.NewUser.password);
  }
}
