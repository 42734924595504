import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BookingAppointments } from '../DTOS/Bookings/BookingAppointmentsDTO';
const MINUTES_STEP = 30;
const generateHoursList = (): string[] => {
  const returnValue = [];

  const startDate = new Date();
  startDate.setHours(0, 0, 0, 0);
  const optionsCount = (60 / MINUTES_STEP) * 24;
  for (var i = 0; i < optionsCount; i++) {
    returnValue.push(moment(startDate).format('hh:mm a'));
    startDate.setMinutes(startDate.getMinutes() + MINUTES_STEP);
  }
  return returnValue;
};

type AgendaBooking = BookingAppointments & {
  _age: string;
  _formattedFromTo: string;
};
type AgendaDictionary = {
  [time: string]: AgendaBooking[];
};

const DATE_ONLY_FORMAT = 'MM/D/YYYY';
const FULL_DATE_FORMAT = 'MM/D/YYYY hh:mm a';
const HOUR_ONLY_FORMAT = 'hh:mm a';

@Component({
  selector: 'app-agenda-scheduler-view',
  templateUrl: './agenda-scheduler-view.component.html',
  styleUrls: ['./agenda-scheduler-view.component.scss'],
})
export class AgendaSchedulerViewComponent implements OnChanges {
  @Input() public events: BookingAppointments[] = [];
  @Input() public date: Date;
  @Input() public clinicId: number;

  public eventsDictionary: AgendaDictionary = {};
  public hoursList: string[] = [];
  public bookingAppointments: BookingAppointments[];
  public showNoEvents: boolean = true;
  constructor() {}

  public onSelectedDateChange(date: Date) {
    this.date = date;
    this.RenderEvents(this.bookingAppointments);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const newEvents = changes.events.currentValue as BookingAppointments[];
    this.bookingAppointments = newEvents;
    console.log('newEvents,newEvents', newEvents);
    this.RenderEvents(newEvents);
  }

  @Output() onEventEditClick: EventEmitter<BookingAppointments> =
    new EventEmitter();

  private RenderEvents(newEvents: BookingAppointments[]) {
    const selectedDateMoment = moment(this.date, DATE_ONLY_FORMAT);

    // Gets all the events that are in the selected date.
    const selectedDayEvents = newEvents.filter((event) => {
      const eventMoment = moment(event.startDateTime, DATE_ONLY_FORMAT);
      return selectedDateMoment.isSame(eventMoment, 'date');
    });

    this.showNoEvents = selectedDayEvents.length ? false : true;

    let newEventsDictionary: AgendaDictionary = {};
    let hoursList = generateHoursList();
    hoursList.forEach((hour, index) => {
      const nextSlot = hoursList[index + 1];
      newEventsDictionary[hour] = selectedDayEvents
        .filter((event) => {
          const compareDateString =
            selectedDateMoment.format(DATE_ONLY_FORMAT) + ' ' + hour;

          const compareDate = moment(
            moment(compareDateString, FULL_DATE_FORMAT)
          );

          const evDate = moment(event.startDateTime);
          if (nextSlot) {
            const nextSlotCompareDateString =
              selectedDateMoment.format(DATE_ONLY_FORMAT) + ' ' + nextSlot;

            const nextSlotCompareDate = moment(
              moment(nextSlotCompareDateString, FULL_DATE_FORMAT)
            );

            const isBetween = evDate.isBetween(
              compareDate,
              nextSlotCompareDate,
              null,
              '[)'
            );

            return isBetween;
          }

          console.log(
            'compareDate.add(MINUTES_STEP, ',
            compareDate.add(1, 'hour')
          );
          return evDate.isBetween(
            compareDate,
            compareDate.add(1, 'hour'),
            null,
            '[)'
          );
        })
        .map((b) => {
          const formattedFromTo =
            moment(b.startDateTime).format(HOUR_ONLY_FORMAT) +
            ' - ' +
            moment(b.endDateTime).format(FULL_DATE_FORMAT);
          return {
            ...b,
            _age: String(moment().diff('1981-01-01', 'years', false)),
            _formattedFromTo: formattedFromTo,
          };
        });
    });

    let skippableHours = [];
    for (let index = 0; index < hoursList.length; index++) {
      const element = hoursList[index];
      if (!newEventsDictionary[element].length) {
        skippableHours.push(element);
      } else {
        break;
      }
    }
    hoursList = hoursList.filter((h) => skippableHours.includes(h) == false);
    this.eventsDictionary = newEventsDictionary;
    this.hoursList = hoursList;
  }

  public onEventEdit(clickedEntry: BookingAppointments): void {
    this.onEventEditClick.emit(clickedEntry);
  }
}
