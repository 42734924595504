import * as _ from 'lodash';

import {
  AddAppointmentDialogData,
  AddAppointmentsDialogComponent,
} from 'src/app/_dialogs/add-appointments-dialog/add-appointments-dialog.component';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  menuHideShow as MenuHideShow,
  MenuNode,
} from 'src/app/DTOS/sidebar-menu/menu';

import { ApiService } from 'src/app/api.service';
import { AuthenticationService } from 'src/app/_services';
import { ImportAppointmentsDialogComponent } from 'src/app/_dialogs/import-appointments-dialog/import-appointments-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import PermissionSlugs from 'src/app/Constants/PermissionSlugs';
import { PermissionSlugsService } from 'src/app/Services/PermissionSlugsService';
import { Router } from '@angular/router';
import { TawkToService } from 'src/app/_services/TawkToService';
import { User } from 'src/app/_models';
import { getCurrentScreen } from 'src/app/Helpers/viewportHelpers';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
})
export class SidebarMenuComponent implements OnInit {
  menuHideShow: MenuHideShow = {} as MenuHideShow;
  hasBookingPermission: boolean;
  currentUser: User = {} as User;
  TREE_DATA: MenuNode[] = [] as MenuNode[];
  @Output() menuExpandedChange = new EventEmitter<boolean>();
  screen: string;
  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private authenticationService: AuthenticationService,
    public router: Router,
    private tawkToService: TawkToService,
    private permissionSlugsService: PermissionSlugsService,
    private API: ApiService
  ) {
    this.menuHideShow = {} as MenuHideShow;
  }
  @Input() public menuExpanded: boolean;
  public rootClass: string = '';
  public screenSizes = {
    height: window.innerHeight,
    width: window.innerWidth,
  };
  ngOnInit(): void {
    window.onresize = () => {
      this.screenSizes = {
        height: window.innerHeight,
        width: window.innerWidth,
      };
    };
  }
  openLink(link) {
    window.open(link, '_blank');
  }

  public onNodeClicked(node: MenuNode) {
    if (node.url) {
      this.navigateTo(node.url);
    } else if (node.onClick) {
      node.onClick();
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.onSidenavClose();
  }

  public getRootClass() {
    const screen = getCurrentScreen();
    this.screen = screen;
    if (['sm', 'md'].includes(screen) && this.menuExpanded) {
      return 'citamed-sidebar mobileExpanded expandedmenu';
    }
    if (screen === 'sm' && !this.menuExpanded) {
      return 'citamed-sidebar';
    }
    if (screen === '3xl' || this.menuExpanded) {
      return 'citamed-sidebar expandedmenu';
    } else {
      return 'citamed-sidebar';
    }
  }

  ngAfterViewInit() {
    this.authenticationService.currentUser.subscribe((currentUserValue) => {
      if (!currentUserValue?.clinicId) {
        this.currentUser = null;
        return;
      }
      this.currentUser = currentUserValue;
      if (this.currentUser?.userId) {
        this.permissionSlugsService.fetch();
      }
      this.tawkToService.StartChat(this.currentUser);
      if (this.currentUser.isAdmin) {
        this.menuHideShow.isPrinter = this.currentUser.isAdmin;
      } else {
        this.API.IsUserPrinter(this.currentUser.clinicId).subscribe(
          () => {
            this.menuHideShow.isPrinter = true;
          },
          (error) => {
            console.error(error);
          }
        );
      }
    });

    this.permissionSlugsService.permissionsListener.subscribe((r) => {
      this.menuHideShow.showDWL = r.includes(
        PermissionSlugs.DIGITAL_WAITING_LIST
      );
      this.menuHideShow.hideAppointmentManagement = r.includes(
        PermissionSlugs.BLOCK_APPOINTMENT_MANAGEMENT
      );
      this.menuHideShow.showVirtualAssistant = r.includes(
        PermissionSlugs.VIRTUAL_ASSISTANT
      );
      this.menuHideShow.showCampaigns = r.includes(PermissionSlugs.CAMPAIGNS);
      this.menuHideShow.showTransitionalCare = r.includes(
        PermissionSlugs.TRANSITIONAL_CARE
      );
      this.menuHideShow.patientManagement = r.includes(
        PermissionSlugs.PATIENT_MANAGEMENT
      );
      this.menuHideShow.hasBookingPermission = r.includes(
        PermissionSlugs.BOOKINGS
      );
      this.setmenuData();
    });

    // Transitional Care Management Users
    if (
      this.menuHideShow.hideAppointmentManagement === true &&
      this.menuHideShow.showTransitionalCare === true
    ) {
      this.router.navigate(['/Transitional-Care-admisions-and-discharges']);
    } else if (
      this.menuHideShow.hideAppointmentManagement === true &&
      this.menuHideShow.showCampaigns === true
    ) {
      this.router.navigate(['/campañas/crear']);
    } else if (
      this.menuHideShow.hideAppointmentManagement === true &&
      this.menuHideShow.showVirtualAssistant === true &&
      this.menuHideShow.showTransitionalCare === false
    ) {
      this.router.navigate(['/llamadasPerdidas']);
    }
  }
  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  setmenuData() {
    const menuOptions = [
      {
        name: 'Inicio',
        ngif: true,
        url: '/',
        icon: 'home',
      },
      {
        name: `Citas`,
        icon: 'event',
        ngif: !this.menuHideShow.hideAppointmentManagement,
        url: '',
        children: [
          {
            name: 'Añadir Cita',
            ngif: true,
            icon: 'event',
            isClic: true,
            onClick: this.OpenAppointmentAddDialog.bind(this),
          },
          {
            name: 'Añadir Multiples Citas',
            ngif: true,
            icon: 'list_alt',
            url: '/add-appointments',
          },
          {
            name: 'Importar Citas ',
            isClic: true,
            icon: 'upload_file',
            ngif: this.menuHideShow.isPrinter,
            onClick: this.OpenImportAppointmentDialog.bind(this),
          },
          {
            name: 'Pausar Envios',
            ngif: true,
            icon: 'pause_circle',
            url: '/no-call-dates-add-new',
          },
          {
            name: 'Llamada Especial',
            ngif: true,
            icon: 'phone_forwarded',
            url: '/special-Announcement',
          },
        ],
      },
      {
        name: 'Pacientes',
        icon: 'person',
        ngif: this.menuHideShow.patientManagement,
        url: '',
        children: [
          {
            name: 'Registrar Nuevo',
            ngif: true,
            url: `/paciente-nuevo/${this.currentUser?.clinicId}`,
          },
          {
            name: 'Listado de Pacientes',
            ngif: true,
            url: 'Pacientes',
          },
          {
            name: 'Planes de Seguros',
            ngif: true,
            url: 'planes-de-seguros',
          },
        ],
      },
      {
        name: 'Agendamientos',
        icon: 'settings_applications',
        ngif: this.menuHideShow.hasBookingPermission,
        url: '',
        children: [
          {
            name: 'Agenda',
            ngif: true,
            url: `/agendamiento`,
          },
          // {
          //   name: 'Nuevo',
          //   ngif: true,
          //   url: '/Agendamiento/Nuevo',
          // },
          {
            name: 'Configuración',
            ngif: true,
            url: '/agendamiento/configuracion',
          },
        ],
      },
      {
        name: 'Asistente Virtual',
        icon: 'smart_toy',
        ngif: this.menuHideShow.showVirtualAssistant,
        url: '',
        children: [
          {
            name: 'Llamadas perdidas',
            ngif: true,
            url: 'asistente-virtual/llamadas-perdidas',
          },
          {
            name: 'Configuración',
            ngif: true,
            url: `asistente-virtual/Configuracion/${this.currentUser?.clinicId}`,
          },
          {
            name: 'Cuentas',
            ngif: this.currentUser?.isAdmin,
            url: 'VA/accounts',
          },
        ],
      },
      {
        name: 'Estatus de Turno',
        icon: 'view_column',
        ngif: this.menuHideShow.showDWL,
        url: '',
        children: [
          {
            name: 'Tablero',
            ngif: true,
            url: 'waiting-list',
          },
        ],
      },
      {
        name: 'Comunicaciones',
        icon: 'dialpad',
        ngif: this.menuHideShow.showCampaigns,
        url: '',
        children: [
          {
            name: 'Crear Campaña',
            ngif: this.menuHideShow.showCampaigns,
            url: '/campañas/crear',
          },
        ],
      },
      {
        name: 'Transitional Care',
        icon: 'dashboard',
        ngif: this.menuHideShow.showTransitionalCare,
        url: '',
        children: [
          {
            name: 'Admisiones y Altas',
            ngif: true,
            url: '/Transitional-Care-admisions-and-discharges',
          },
          {
            name: 'Confirmaciones de citas',
            ngif: false,
            url: '/Transitional-Care-confirmations',
          },
        ],
      },

      {
        name: 'Mi Cuenta',
        icon: 'event',
        ngif: true,
        url: '',
        children: [
          {
            name: 'Detalles y perfil',
            ngif: true,
            icon: 'business',
            url: 'account-details',
          },
          {
            name: 'Doctores/Proveedores',
            ngif: true,
            icon: 'group',
            url: 'Doctors-list',
          },
          {
            name: 'Usuarios',
            ngif: true,
            url: '/Users',
            icon: 'supervised_user_circle',
          },
          {
            name: 'Tipos de Citas',
            ngif: !!this.currentUser?.isAdmin,
            url: '/my-clinic-appointment-types',
          },
        ],
      },

      {
        name: 'Cuentas',
        ngif: this.currentUser?.isAdmin,
        url: '',
        icon: 'business',
        children: [
          {
            name: 'Compañias',
            ngif: true,
            url: '/company',
          },
          {
            name: 'Clínicas',
            ngif: true,
            url: '/clinic',
          },
          {
            name: 'Reporte de Estados',
            ngif: true,
            url: '/accounts-stats',
          },
        ],
      },
      {
        name: 'Calendarios',
        icon: 'calendar_month',
        ngif: this.currentUser?.isAdmin,

        url: '',
        children: [
          {
            name: 'Calendarios de Llamadas',
            ngif: true,

            url: '/reminder-schedules',
          },
          {
            name: 'Horarios/Frecuencias',
            ngif: true,

            url: '/calltimings',
          },
          {
            name: ' Re-calls',
            ngif: true,
            url: '/call-again-schedules',
          },
        ],
      },
      {
        name: 'Administradores',
        icon: 'settings',
        ngif: this.currentUser?.isAdmin,
        url: '',
        children: [
          {
            name: 'Récords Electrónicos',
            ngif: true,
            url: '/ehrs',
          },
          {
            name: 'Planes',
            ngif: true,
            url: '/rate-plans',
          },
          {
            name: 'Estatus de Asistencia',
            ngif: true,
            url: '/Estatus-de-asistencia',
          },
          {
            name: 'Tipos de Citas',
            ngif: true,
            url: '/appointment-types',
          },
          {
            name: 'Twilio Caller ID',
            ngif: true,
            url: '/twilio-caller-id-activator',
          },
          {
            name: 'Admin Console',
            ngif: true,
            isLink: true,
            url: 'https://console.citamed-secure.com/',
          },
          {
            name: 'Permisos',
            ngif: true,
            url: '/permisos',
          },
          {
            name: 'Sprocs',
            ngif: true,
            url: '/Sprocs',
          },
          {
            name: ' Creación de Mensajes',
            ngif: true,
            url: '/add-reminders',
          },
          {
            name: 'ReminderSet-ReminderSet',
            ngif: true,
            url: '/reminderSet-reminderSet',
          },
        ],
      },
    ].filter((opt) => opt.ngif);

    if (menuOptions.length === 3) {
      let opts = [];
      menuOptions.forEach((option) => {
        opts = [...opts, ...(option.children || [])];
        if (!option.children?.length) {
          opts.push(option);
        }
      });
      this.TREE_DATA = opts;
    } else {
      this.TREE_DATA = menuOptions;
    }
  }

  OpenAppointmentAddDialog() {
    const data: AddAppointmentDialogData = {
      clinicID: this.currentUser.clinicId,
      UserId: this.currentUser.userId,
      ClinicName: this.currentUser.clinicName,
    };
    this.dialog
      .open(AddAppointmentsDialogComponent, {
        data,
        maxWidth: '39.5rem',
        maxHeight: '42rem',
        panelClass: 'full-with-dialog',
        disableClose: false,
        hasBackdrop: true,
        autoFocus: true,
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        const result = dialogResult as boolean;
        if (result) {
          this._snackBar.open(
            '¡Listo! La cita ha sido añadida correctamente.',
            'ok ',
            {
              duration: 8000,
              horizontalPosition: 'left',
              verticalPosition: 'bottom',
              panelClass: 'success-dialog',
            }
          );
        }
      });
  }

  hasChild = (n: number, node: MenuNode) => {
    if (!!node.children && node.children.length > 0) {
      let { children } = node;
      let childrenFilter = children.filter((item) => item.ngif == true);
      node.children = childrenFilter;
      return node;
    }
  };

  logout() {
    this.authenticationService.logout();
    this.tawkToService.endChat();
    this.router.navigate(['/login']);
  }

  OpenImportAppointmentDialog() {
    this.dialog
      .open(ImportAppointmentsDialogComponent, {
        maxWidth: '39.5rem',
        maxHeight: '43rem',
        panelClass: 'full-with-dialog',
        disableClose: false,
        hasBackdrop: true,
        autoFocus: true,
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        const result = dialogResult as boolean;
        if (result) {
          this._snackBar.open(
            '¡Listo! Las citas han sido importadas correctamente.',
            'ok ',
            {
              duration: 8000,
              horizontalPosition: 'left',
              verticalPosition: 'bottom',
              panelClass: 'success-dialog',
            }
          );
        }
      });
  }
  onSidenavClose() {
    this.menuExpandedChange.emit(false);
  }
}
