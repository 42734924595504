import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {
  InsurancePlanDialogComponent,
  InsurancePlanDialogData,
} from 'src/app/_dialogs/insurance-plan-dialog/insurance-plan-dialog.component';
import PermissionSlugs from 'src/app/Constants/PermissionSlugs';
import { User } from '../../_models/user';
import { AuthenticationService } from '../../_services/authentication.service';
import { ApiService } from '../../api.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from '../../confirm-dialog/confirm-dialog.component';
import { InsurancePlansDTO } from '../../DTOS/InsurancePlans/InsurancePlansDTO';
import {
  LoadingDialogComponent,
  LoadingDialogModel,
} from '../../loading-dialog/loading-dialog.component';
import { PermissionSlugsService } from '../../Services/PermissionSlugsService';

@Component({
  selector: 'app-insurance-plans',
  templateUrl: './insurance-plans.component.html',
  styleUrls: ['./insurance-plans.component.scss'],
})
export class InsurancePlansComponent implements AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource: MatTableDataSource<InsurancePlansDTO>;
  loadingRef: MatDialogRef<LoadingDialogComponent>;
  @Input() public isStandalone: boolean;
  public isLoading: boolean;
  public permissions: string[] = [];
  httpClient: HttpClient;
  baseUrl: string;
  public User: User;
  displayedColumns: string[] = [
    'InsurancePlanID',
    'InsurancePlanName',
    'Details',
  ];
  isLoadingPermissions = true;
  hasInsurancePlans_Permission = false;
  searchTearm: string;

  constructor(
    http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private permissionSlugsService: PermissionSlugsService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private API: ApiService
  ) {
    this.httpClient = http;
    this.baseUrl = baseUrl;
    this.permissionSlugsService.fetch();
    this.permissionSlugsService.permissionsListener.subscribe((r) => {
      this.permissions = r;
    });
    this.authenticationService.currentUser.subscribe((u) => (this.User = u));
  }

  ngAfterViewInit() {
    this.User = this.authenticationService.currentUserValue;
    this.permissionSlugsService.fetch();
    this.permissionSlugsService.permissionsListener.subscribe((r) => {
      this.hasInsurancePlans_Permission = r.includes(
        PermissionSlugs.PATIENT_MANAGEMENT
      );
    });
    this.permissionSlugsService.loaderListener.subscribe((r) => {
      this.isLoadingPermissions = r;
    });
    this.LoadInsurancePlans();
  }

  public LoadInsurancePlans() {
    if (this.isStandalone) {
      return;
    }
    this.ShowLoading();
    this.API.GetInsurancePlans().subscribe(
      (r) => {
        const res = r as InsurancePlansDTO[];
        this.dataSource = new MatTableDataSource<InsurancePlansDTO>(res);
        this.dataSource.paginator = this.paginator;
        this.hideLoading();
      },
      (error) => {
        console.error(error);
        this.hideLoading();
        this.showError(error);
      }
    );
  }

  public ShowLoading() {
    const loading_dialog = this.dialog.open(LoadingDialogComponent, {
      maxWidth: '400px',
      data: new LoadingDialogModel('Cargando', '', false),
    });
    loading_dialog.disableClose = true;
    this.loadingRef = loading_dialog;
  }

  public hideLoading() {
    if (this.loadingRef) {
      this.loadingRef.close();
    }
  }

  private showError(error: any) {
    console.error('CITAMED ERROR', error);
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: new ConfirmDialogModel('Ha ocurrido un error', '', 'Okay', null),
    });
  }

  OpenInsurancePlanDialog(insurancePlan: InsurancePlansDTO) {
    const data: InsurancePlanDialogData = {
      insurancePlan,
      title: insurancePlan ? 'Editar Plan de Seguro' : 'Añadir Plan de Seguro',
    };
    this.dialog
      .open(InsurancePlanDialogComponent, {
        data,
        maxWidth: '39.5rem',
        height: '18rem',
        panelClass: 'full-with-dialog',
        disableClose: false,
        hasBackdrop: true,
        autoFocus: true,
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        const result = dialogResult as boolean;
        if (result) {
          this.LoadInsurancePlans();
        }
      });
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
