export interface PermissionDetailDTO {
  permissionId: number;
  slug: string;
  permissionName: string;
  description: string;
  isEnabledByDefault: boolean;
  IsActive: boolean;
  deleteDate: Date;
}
export interface ClinicPermissionDTO {
  permissionId: number;
  deletedByUserId: number;
  deleteDate: Date;
  clinicPermissionId: number;
  clinicId: number;
  permissionName: string;
  slug: string;
}
export class AddPermissionDTO {
  permissionId: number;
  slug: string;
  permissionName: string;
  description: string;
  isEnabledByDefault: boolean;
}

export class ClicPermissionDTO {
  permissionId: number;
  clinicPermissionId: number;
  clinicId: number;
  authorUserId: number;
  deleteDate: Date;
  slug: string;
  permissionName: string;
  description: string;
  isEnabledByDefault: boolean;
}
