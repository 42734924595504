import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { ConfirmationService } from 'primeng/api';
import { ApiService } from 'src/app/api.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from 'src/app/confirm-dialog/confirm-dialog.component';
import {
  EngineSetting,
  IEngineSettingsList,
} from 'src/app/DTOS/Admin/AdminViewAsClinic';
import { LoadingDialogComponent } from 'src/app/loading-dialog/loading-dialog.component';
import { User } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services';
import * as _ from 'lodash';
@Component({
  selector: 'app-engine-settings',
  templateUrl: './engine-settings.component.html',
  styleUrls: ['./engine-settings.component.scss'],
})
export class EngineSettingsComponent implements OnInit, AfterViewInit {
  User: User;
  isLoading = false;
  @Input() public isStandalone: boolean;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  searchTerm = '';
  public pageSize = 10;
  public currentPage = 1;
  public totalSize = 0;
  public isEditing = false;
  dataSource: MatTableDataSource<EngineSetting>;
  public apiUserClinics: IEngineSettingsList[] = [];
  loadingRef: MatDialogRef<LoadingDialogComponent>;
  listItems: EngineSetting[];
  EngineSetting = {} as EngineSetting;

  ApiSettingList: IEngineSettingsList;
  displayedColumns: string[] = [
    'constantId',
    'constantName',
    'constantValue',
    'createDate',
    'Actions',
  ];
  slidePanelOpen: Boolean = false;
  slideEditPanelOpen: Boolean = false;
  sidepanelDetails: EngineSetting = {} as any;
  public openAddNewSidePanel() {
    this.sidepanelDetails = {} as EngineSetting;
    this.slidePanelOpen = true;
    this.isEditing = false;
  }
  public openAddNewEditSidePanel(itemEdit: EngineSetting) {
    this.slidePanelOpen = true;
    this.EngineSetting = _.cloneDeep(itemEdit);
    this.isEditing = true;
  }
  public closeAddNewSidePanel() {
    this.slidePanelOpen = false;
    this.EngineSetting = {} as EngineSetting;
    this.LoadEngineSettings();
  }
  constructor(
    private API: ApiService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private authenticationService: AuthenticationService,
    private confirmationService: ConfirmationService
  ) {}
  ngAfterViewInit() {
    this.authenticationService.currentUser.subscribe(
      (user) => (this.User = user)
    );
  }
  ngOnInit(): void {
    this.LoadEngineSettings();
  }

  public LoadEngineSettings() {
    this.currentPage = this.currentPage === 0 ? 1 : this.currentPage;
    this.API.GetEngineSettings(
      this.currentPage,
      this.pageSize,
      this.searchTerm
    ).subscribe(
      (r) => {
        this.dataSource = new MatTableDataSource<EngineSetting>(r.items);
        this.ApiSettingList = r;
        this.dataSource.paginator = this.paginator;
        this.totalSize = this.ApiSettingList.totalCount;
        this.pageSize = this.ApiSettingList.pageSize;
        this.currentPage = this.ApiSettingList.pageIndex;
        this.listItems = this.ApiSettingList.items;
        this.isLoading = false;
      },
      (error) => {
        console.error(error);
        this.hideLoading();
        this.showError(error);
      }
    );
  }
  public handlePage(e: any) {
    this.currentPage = e.pageIndex + 1;
    this.pageSize = e.pageSize;
    this.LoadEngineSettings();
  }

  public formatDate(date: Date) {
    if (date) {
      moment.locale('en');
      return moment(date).format('L');
    }
  }

  public hideLoading() {
    if (this.loadingRef) {
      this.loadingRef.close();
    }
  }

  private showError(error: any) {
    console.error('CITAMED ERROR', error);
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: new ConfirmDialogModel('Ha ocurrido un error', '', 'Okay', null),
    });
  }

  public save = () => {
    this.isLoading = true;
    if (this.EngineSetting?.constantId) {
      this.doUpdate();
    } else {
      this.doCreate();
    }
  };

  doCreate() {
    this.API.CreateConstant(this.EngineSetting).subscribe(
      (result) => {
        this.isLoading = false;
        this._snackBar.open(
          `¡Listo!, la constante ${this.EngineSetting.constantName} ha sido creada. `,
          'OK ',
          {
            duration: 70000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: 'success-dialog',
          }
        );
        this.slidePanelOpen = false;
        this.ClearInput();
        this.LoadEngineSettings();
      },
      (e) => {
        this.isLoading = false;
        this.isEditing = true;
        this.showError(e);
      }
    );
  }

  doUpdate() {
    this.API.UpdateConstant(this.EngineSetting).subscribe(
      () => {
        this.isLoading = false;
        this.slidePanelOpen = false;
        this._snackBar.open(
          `¡Listo!, la constante ${this.EngineSetting.constantName} ha sido actualizada. `,
          'OK ',
          {
            duration: 70000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: 'success-dialog',
          }
        );
        this.slidePanelOpen = false;
        this.ClearInput();
        this.LoadEngineSettings();
      },
      (e) => {
        this.isLoading = false;
        this.isEditing = true;
        this.showError(e);
      }
    );
  }

  public DeleteConstant(event: Event, ConstantID: number) {
    if (this.User?.isAdmin === false) {
      return;
    }
    this.confirmationService.confirm({
      target: event.target,
      message: '¿Estás segur@ de querer eliminar esta constante del sistema?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si, eliminar',
      rejectLabel: 'Cancelar',
      accept: () =>
        this.API.DeleteConstant(ConstantID).subscribe(
          (r) => {
            this.slideEditPanelOpen = false;
            this.LoadEngineSettings();
            this._snackBar.open(
              `¡Listo!, la constante  ha sido eliminada. `,
              'OK ',
              {
                duration: 70000,
                horizontalPosition: 'left',
                verticalPosition: 'bottom',
                panelClass: 'success-dialog',
              }
            );
          },
          (f) => {
            window.alert('Lo sentimos, ha ocurrido un error.');
            console.error('ERROR AL eliminar la constante', f);
          }
        ),
      reject: () => {
        this.confirmationService.close();
      },
    });
  }

  ClearInput() {
    this.EngineSetting = {
      constantId: 0,
      constantName: '',
      constantValue: '',
      createDate: null,
      deleteDate: null,
    };
  }
}
