import { AuthGuard, AuthGuardAdmin } from './_helpers';

import { AccountDetailsComponent } from './account-details/account-details.component';
import { AccountStatsReportComponent } from './Pages/account-stats-report/account-stats-report.component';
import { AccountsComponentComponent } from './Pages/va-accounts/va-accounts.component';
import { AddAppointmentsComponent } from './Pages/add-appointments/add-appointments.component';
import { AddRemindersComponent } from './Pages/add-reminders/add-reminders.component';
import { AddUserComponent } from './add-user/add-user.component';
import { ApiUsersListComponent } from './Pages/api-users-list/api-users-list.component';
import { AppointmentTypesComponent } from './Pages/appointment-types/appointment-types.component';
import { AppointmentsRequestListComponent } from './Pages/appointments-request-list/appointments-request-list.component';
import { AssistanceStatusConfigPageComponent } from './Pages/assistance-status-config-page/assistance-status-config-page.component';
import { BookingsAgendaPageComponent } from './Pages/bookings-agenda-page/bookings-agenda-page.component';
import { BookingsConfigurationComponent } from './Pages/bookings-configuration/bookings-configuration.component';
import { BookingsNewBookingComponent } from './Pages/bookings-new-booking/bookings-new-booking.component';
import { BulkMessageCreateComponent } from './Pages/bulk-message-create/bulk-message-create.component';
import { CallAgainSchedulesComponent } from './Pages/call-again-schedules/call-again-schedules.component';
import { CallTimingSprocsComponent } from './Pages/call-timing-sprocs/call-timing-sprocs.component';
import { CallTimingsComponent } from './Pages/call-timings/call-timings.component';
import { ClinicAppointmentTypesComponent } from './Pages/clinic-appointment-types/clinic-appointment-types.component';
import { ClinicComponent } from './Pages/clinic/clinic.component';
import { ClinicDetailsComponent } from './Pages/clinic-details/clinic-details.component';
import { CompanyComponent } from './Pages/company/company.component';
import { CompanyDetailsComponent } from './pages/company-details/company-details.component';
import { DigitalWaitingListComponent } from './Pages/digital-waiting-list/digital-waiting-list.component';
import { DoctorsListComponent } from './Pages/doctors-list/doctors-list.component';
import { EhrsComponent } from './Pages/ehrs/ehrs.component';
import { EngineSettingsComponent } from './pages/engine-settings/engine-settings.component';
import { HomeComponent } from './Pages/home/home.component';
import { InsurancePlansComponent } from './Pages/insurance-plans/insurance-plans.component';
import { LoginComponent } from './Pages/login/login.component';
import { MissedCallsComponent } from './Pages/missed-calls/missed-calls.component';
import { NoCallDateComponent } from './Pages/no-call-date/no-call-date.component';
import { PasswordRecoverComponent } from './pages/password-recover/password-recover.component';
import { PasswordRecoveryComponent } from './pages/password-recovery/password-recovery.component';
import { PatientDetailsComponent } from './Pages/patient-details/patient-details.component';
import { PatientListComponent } from './Pages/patient-list/patient-list.component';
import { PermissionComponent } from './Pages/permission/permission.component';
import { RatePlansComponent } from './Pages/rate-plans/rate-plans.component';
import { ReminderScheduleComponent } from './pages/reminder-schedule/reminder-schedule.component';
import { RemindersComponent } from './pages/reminders/reminders.component';
import { RemindersetRemindersetComponent } from './pages/reminderset-reminderset/reminderset-reminderset.component';
import { Routes } from '@angular/router';
import { SpecialAnnouncementComponent } from './pages/special-announcement/special-announcement.component';
import { TransitionalCareAdmisionsAndDischargesComponent } from './Pages/transitional-care-admisions-and-discharges/transitional-care-admisions-and-discharges.component';
import { TwilioCallerIdActivatorComponent } from './Pages/twilio-caller-id-activator/twilio-caller-id-activator.component';
import { UsersComponent } from './users/users.component';
import { VaConfigurationComponent } from './Pages/va-configuration/va-configuration.component';

type CitamedRoute = Routes & {
  routeName?: string;
  hideNavigationBar?: boolean;
};

export type AppRoutesInfo = {
  routeName: string;
  path: string;
  hideNavigationBar?: boolean;
};

const appRoutes: CitamedRoute = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    routeName: 'Inicio',
  },
  {
    path: 'admin-view-as/:clinicID',
    component: HomeComponent,
    canActivate: [AuthGuardAdmin],
    routeName: 'Inicio',
  },
  {
    path: 'login',
    component: LoginComponent,
    routeName: 'Citamed - iniciar sesión',
  },
  {
    path: 'RecoverPassword',
    component: PasswordRecoveryComponent,
    routeName: 'Recuperar contraseña',
  },
  {
    path: 'ResetPassword',
    component: PasswordRecoverComponent,
    routeName: 'Reestablecer contraseña',
  },
  {
    path: 'add-appointments',
    component: AddAppointmentsComponent,
    canActivate: [AuthGuard],
    routeName: 'Añadir Citas',
  },
  {
    path: 'no-call-dates-add-new',
    component: NoCallDateComponent,
    canActivate: [AuthGuard],
    routeName: 'Pausar envio de mensajes',
  },
  {
    path: 'special-Announcement',
    component: SpecialAnnouncementComponent,
    canActivate: [AuthGuard],
    routeName: 'Llamada Especial',
  },
  {
    path: 'Users',
    component: UsersComponent,
    canActivate: [AuthGuard],
    routeName: 'Usuarios',
  },
  {
    path: 'Add-User',
    component: AddUserComponent,
    canActivate: [AuthGuard],
    routeName: 'Añadir Usuario',
  },
  {
    path: 'Doctors-list',
    component: DoctorsListComponent,
    canActivate: [AuthGuard],
    routeName: 'Doctores',
  },
  {
    path: 'my-clinic-appointment-types',
    component: ClinicAppointmentTypesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'account-details',
    component: AccountDetailsComponent,
    canActivate: [AuthGuard],
    routeName: 'Detalles y perfil',
  },
  {
    path: 'waiting-list',
    component: DigitalWaitingListComponent,
    canActivate: [AuthGuard],
    hideNavigationBar: true,
  },
  {
    path: 'reminders',
    component: RemindersComponent,
    canActivate: [AuthGuardAdmin],
    hideNavigationBar: true,
  },
  {
    path: 'add-reminders',
    component: AddRemindersComponent,
    canActivate: [AuthGuardAdmin],
    routeName: 'Añadir mensaje',
  },
  {
    path: 'company',
    component: CompanyComponent,
    canActivate: [AuthGuardAdmin],
  },
  {
    path: 'clinic',
    component: ClinicComponent,
    canActivate: [AuthGuardAdmin],
  },
  {
    path: 'reminder-schedules',
    component: ReminderScheduleComponent,
    canActivate: [AuthGuardAdmin],
  },
  {
    path: 'reminderSet-reminderSet',
    component: RemindersetRemindersetComponent,
    canActivate: [AuthGuardAdmin],
  },
  {
    path: 'ehrs',
    component: EhrsComponent,
    canActivate: [AuthGuardAdmin],
  },
  {
    path: 'rate-plans',
    component: RatePlansComponent,
    canActivate: [AuthGuardAdmin],
  },
  {
    path: 'calltimings',
    component: CallTimingsComponent,
    canActivate: [AuthGuardAdmin],
  },
  {
    path: 'appointment-types',
    component: AppointmentTypesComponent,
    canActivate: [AuthGuardAdmin],
  },
  {
    path: 'twilio-caller-id-activator',
    component: TwilioCallerIdActivatorComponent,
    canActivate: [AuthGuardAdmin],
  },
  {
    path: 'call-again-schedules',
    component: CallAgainSchedulesComponent,
    canActivate: [AuthGuardAdmin],
  },
  {
    path: 'clinic/:clinicId',
    component: ClinicDetailsComponent,
    canActivate: [AuthGuardAdmin],
  },
  {
    path: 'admin/clinic/New',
    component: ClinicDetailsComponent,
    canActivate: [AuthGuardAdmin],
  },
  {
    path: 'accounts-stats',
    component: AccountStatsReportComponent,
    canActivate: [AuthGuardAdmin],
  },
  {
    path: 'VA/accounts',
    component: AccountsComponentComponent,
    routeName: 'Lista de Asistentes Virtuales',
  },
  {
    path: 'asistente-virtual/llamadas-perdidas',
    component: MissedCallsComponent,
    routeName: 'Llamadas Perdidas',
  },
  {
    path: 'solicitudes-de-citas',
    component: AppointmentsRequestListComponent,
    canActivate: [AuthGuardAdmin],
  },
  {
    path: 'permisos',
    component: PermissionComponent,
    canActivate: [AuthGuardAdmin],
  },
  {
    path: 'asistente-virtual/Configuracion',
    component: VaConfigurationComponent,
    routeName: 'Configuración de asistente virtual',
  },
  {
    path: 'asistente-virtual/Configuracion/:accountID',
    component: VaConfigurationComponent,
    routeName: 'Configuración de asistente virtual',
  },
  {
    path: 'campañas/crear',
    component: BulkMessageCreateComponent,
    routeName: 'Crear nueva campaña',
  },
  {
    path: 'Transitional-Care-admisions-and-discharges',
    component: TransitionalCareAdmisionsAndDischargesComponent,
    hideNavigationBar: true,
  },
  {
    path: 'Estatus-de-asistencia',
    component: AssistanceStatusConfigPageComponent,
    canActivate: [AuthGuardAdmin],
  },
  {
    path: 'Sprocs',
    component: CallTimingSprocsComponent,
  },
  {
    path: 'Pacientes',
    component: PatientListComponent,
    routeName: 'Lista de Pacientes',
  },
  {
    path: 'paciente-nuevo/:clinic_Id',
    component: PatientDetailsComponent,
    routeName: 'Registrar nuevo Paciente',
  },
  {
    path: 'Paciente/:patient_id/:clinic_Id',
    component: PatientDetailsComponent,
    routeName: 'Detalles del Paciente',
  },
  {
    path: 'planes-de-seguros',
    component: InsurancePlansComponent,
  },
  {
    path: 'agendamiento/configuracion',
    component: BookingsConfigurationComponent,
    routeName: 'Configuración de Agenda',
  },
  {
    path: 'Agendamiento/Nuevo',
    component: BookingsNewBookingComponent,
    routeName: 'Registrar nueva cita',
  },
  {
    path: 'api-users',
    component: ApiUsersListComponent,
    canActivate: [AuthGuardAdmin],
    routeName: 'Usuarios de API',
  },
  {
    path: 'agendamiento',
    component: BookingsAgendaPageComponent,
    hideNavigationBar: true,
  },
  {
    path: 'company/:companyId',
    component: CompanyDetailsComponent,
    canActivate: [AuthGuardAdmin],
    routeName: 'Cuenta de facturación',
  },
  {
    path: 'admin/company/New',
    component: CompanyDetailsComponent,
    canActivate: [AuthGuardAdmin],
    routeName: 'Registrar nueva Compañia',
  },
  {
    path: 'engine-setting',
    component: EngineSettingsComponent,
    canActivate: [AuthGuardAdmin],
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

const appRoutesNames: AppRoutesInfo[] = appRoutes.map((a) => {
  const route = a as CitamedRoute;
  return {
    routeName: route.routeName,
    path: (route as any).path,
    hideNavigationBar: route.hideNavigationBar,
  };
});

export { appRoutes, appRoutesNames };
