export interface BookingModel {
  bookingID?: number;
  startDateTimeUTC: Date;
  endDateTimeUTC: Date;
  durationInMinutes: number;
  appointmentId?: number;
  bookingServiceId: number;
  bookingStatusId: number;
  patientRecordId: number;
  doctorId: number;
  clinicId: number;
  isRecurring: boolean;
  notes: string;
  startDateTime?: Date;
  endDateTime?: Date;
  recurrencePattern?: string;
}

export type EventModel = EventExtendedProps & {
  id: string;
  start: string;
  end: string;
  title: string;
  color?: string;
  description?: string;
  backgroundColor?: string;
  allDay?: boolean;
  _recurringParentBookingId?: number;
  _eventStart?: Date;
  _eventEnd?: Date;
};

export interface EventExtendedProps {
  _recurringParentBookingId?: number;
  _eventStart?: Date;
  _eventEnd?: Date;
}
export interface SuggestedRecurrenceModel {
  description: string;
  pattern: string;
}

export enum EnumRecurrenceEvent {
  a_thisEvent = 'Este evento',
  b_thisEventandThefollowing = 'Este evento, y los siguientes',
  c_allEvents = 'Todos los eventos',
}
export class BookingViewModel {
  patienteName: string;
  doctorname: string;
  servicesName: string;
  dateStart: string;
  dateEnd: string;
}
