import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import PermissionSlugs from 'src/app/Constants/PermissionSlugs';
import { PermissionSlugsService } from 'src/app/Services/PermissionSlugsService';
import { User } from '../../_models/user';
import { AuthenticationService } from '../../_services/authentication.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from '../../confirm-dialog/confirm-dialog.component';
import { AdminViewAsClinicDTO } from '../../dtos/AdminViewAsClinicDTO';
import { Doctor } from '../../Dtos/Doctors/DoctorDTO';
import { NoCallDateCreateDTO } from '../../dtos/NoCallDate/NoCallDateCreateDTO';
import { NoCallDateGetDTO } from '../../dtos/NoCallDate/NoCallDateCreateDTO';
import { deepCopy } from '../../helpers/DeepCopy';
import {
  LoadingDialogComponent,
  LoadingDialogModel,
} from '../../loading-dialog/loading-dialog.component';
import { ApiService } from './../../api.service';

@Component({
  selector: 'app-no-call-date',
  templateUrl: './no-call-date.component.html',
  styleUrls: ['./no-call-date.component.scss'],
})
export class NoCallDateComponent implements AfterViewInit {
  constructor(
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private API: ApiService,
    private permissionSlugsService: PermissionSlugsService
  ) {}
  displayedColumns: string[] = [
    'Fecha_Cancelada',
    'Nombre_Doctor',
    'ID',
    'Comments',
    'Actions',
  ];

  minSelectedDate: Date = new Date();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  dataSource: MatTableDataSource<NoCallDateGetDTO>;
  recordsCount = 0;
  public cancellAllDoctorsAppointments = false;
  public User: User;
  public hasAntiPermAppointmentsManagement = false;
  isLoadingPermissions = true;
  public ViewAs: AdminViewAsClinicDTO;

  private isLoadingDoctors = false;
  public Doctors: Doctor[] = new Array<Doctor>();
  public _unFiltered_Doctors: Doctor[] = new Array<Doctor>();
  appointmentsDate: Date;
  selectedDoctors: Doctor[];
  NoCallDate_To: Date;

  FREE_HOLDS_appointmentsDateFrom: Date;
  FREE_HOLDS_selectedDoctors: Doctor[];
  FREE_HOLDS_appointmentsDate_To: Date;
  public FreeAllDoctorsNoCallDates = false;

  loadingRef: MatDialogRef<LoadingDialogComponent>;

  ngAfterViewInit() {
    this.User = this.authenticationService.currentUserValue;
    this.FetchDoctors(this.User.clinicId);
    this.LoadNoCallDates();
    this.User = this.authenticationService.currentUserValue;
    this.permissionSlugsService.fetch();
    this.permissionSlugsService.permissionsListener.subscribe((r) => {
      this.hasAntiPermAppointmentsManagement = r.includes(
        PermissionSlugs.BLOCK_APPOINTMENT_MANAGEMENT
      );
    });
    this.permissionSlugsService.loaderListener.subscribe((r) => {
      this.isLoadingPermissions = r;
    });
  }

  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
    this.FetchDoctors(this.ViewAs.clinicId);
    this.LoadNoCallDates();
  }
  public FetchDoctors(ClinicID: number) {
    this.isLoadingDoctors = true;

    this.API.FetchDoctors(ClinicID).subscribe(
      (result) => {
        const res = result as Doctor[];
        this._unFiltered_Doctors = res;
        this.Doctors = deepCopy<Doctor[]>(this._unFiltered_Doctors);
        this.isLoadingDoctors = false;
      },
      (error) => console.error(error)
    );
  }
  public onDoctorFilterStringChange(filter: string) {
    if (!filter) {
      this.Doctors = deepCopy<Doctor[]>(this._unFiltered_Doctors);
    }
    filter = filter.toLowerCase();
    const filteredDoctors = new Array<Doctor>();
    for (let i = 0; i < this._unFiltered_Doctors.length; i++) {
      const option = this._unFiltered_Doctors[i];
      if (
        (option.doctorId + ' - ' + option.doctorName)
          .toLowerCase()
          .indexOf(filter) >= 0
      ) {
        filteredDoctors.push(deepCopy<Doctor>(option));
      }
    }
    this.Doctors = filteredDoctors;
  }
  public DeleteNoCallDate(Nocalldate: any) {
    const message = `¿Estas seguro de querer liberar las llamadas de ${Nocalldate.doctor.doctorName}, de las citas del ${Nocalldate.noCallDate}?. Esto hará que Citamed contacte a los pacientes pendientes para confirmar en esta fecha.`;
    const dialogData = new ConfirmDialogModel(
      'Confirmación',
      message,
      'Si',
      'Cancelar'
    );
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.ShowLoading();

        this.API.DeleteNoCallDate(Nocalldate.noCallDateId).subscribe(
          () => {
            this.hideLoading();
            this.dataSource.data = this.dataSource.data.filter(
              (dd) => dd.noCallDateId !== Nocalldate.noCallDateId
            );
            this.dialog.open(ConfirmDialogComponent, {
              maxWidth: '600px',
              data: new ConfirmDialogModel(
                'Listo',
                `Las llamadas de ${Nocalldate.doctor.doctorName} de las citas del ${Nocalldate.noCallDate} han sido liberadas. Citamed contactará a los pacientes de esta fecha`,
                'Okay',
                null
              ),
            });
          },
          (error) => {
            console.error(error);
            this.hideLoading();
            this.showError(error);
          }
        );
      }
    });
  }

  public LoadNoCallDates() {
    this.ShowLoading();
    const ClinicID = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;

    this.API.FetchNoCallDates(ClinicID).subscribe(
      (result) => {
        const res = result.map((e) => {
          return {
            ...e,
            noCallDate: moment(e.noCallDate).locale('es').format('DD MMM YYYY'),
          };
        }) as NoCallDateGetDTO[];
        this.dataSource = new MatTableDataSource<NoCallDateGetDTO>(res);
        this.dataSource.paginator = this.paginator;
        this.recordsCount = res.length;
        this.hideLoading();
      },
      (error) => {
        console.error(error);
        this.hideLoading();
        this.showError(error);
      }
    );
  }

  public ShowLoading() {
    const loading_dialog = this.dialog.open(LoadingDialogComponent, {
      maxWidth: '400px',
      data: new LoadingDialogModel('Cargando', '', false),
    });
    loading_dialog.disableClose = true;
    this.loadingRef = loading_dialog;
  }
  public hideLoading() {
    if (this.loadingRef) {
      this.loadingRef.close();
    }
  }
  private showError(error: any) {
    console.error('CITAMED ERROR', error);
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: new ConfirmDialogModel(
        'Ha ocurrido un error',
        'No hemos podido cancelar las llamadas.',
        'Okay',
        null
      ),
    });
  }
  public SaveNoCallDate() {
    const noCallDateDTO = new NoCallDateCreateDTO();
    noCallDateDTO.clinicId = this.User.clinicId;
    if (this.ViewAs) {
      noCallDateDTO.clinicId = this.ViewAs.clinicId;
    }
    noCallDateDTO.NoCallDate = this.appointmentsDate;
    if (this.cancellAllDoctorsAppointments) {
      noCallDateDTO.DoctorsIds = this._unFiltered_Doctors.map(
        (e) => e.doctorId
      );
    } else {
      noCallDateDTO.DoctorsIds = this.selectedDoctors.map((e) => e.doctorId);
    }

    const appointments_dates = moment(this.appointmentsDate)
      .locale('es')
      .format('DD [de] MMMM [del] YYYY');

    const tos = moment(this.NoCallDate_To)
      .locale('es')
      .format('DD [de] MMMM [del] YYYY');

    const message = `¿Estas seguro de querer cancelar las llamadas de (${
      this.cancellAllDoctorsAppointments
        ? 'TODOS los'
        : noCallDateDTO.DoctorsIds.length
    }) doctores desde ${appointments_dates} hasta ${tos}?`;
    noCallDateDTO.NoCallDate_To = this.NoCallDate_To;
    const dialogData = new ConfirmDialogModel(
      'Confirmación',
      message,
      'Si',
      'Cancelar'
    );

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        const loading_dialog = this.dialog.open(LoadingDialogComponent, {
          maxWidth: '400px',
          data: new LoadingDialogModel('Cargando', '', false),
        });
        loading_dialog.disableClose = true;

        this.API.CreateNoCallDate(noCallDateDTO).subscribe(
          () => {
            loading_dialog.close();
            this.dialog.open(ConfirmDialogComponent, {
              maxWidth: '600px',
              data: new ConfirmDialogModel(
                'Listo',
                'Las llamadas han sido puestas en hold para los doctores seleccionados.',
                'Okay',
                null
              ),
            });
            this.LoadNoCallDates();
          },
          (error) => {
            loading_dialog.close();
            this.showError(error);
          }
        );
      }
    });
  }

  public RemoveNoCallDate() {
    const noCallDateDTO = new NoCallDateCreateDTO();
    noCallDateDTO.clinicId = this.User.clinicId;
    if (this.ViewAs) {
      noCallDateDTO.clinicId = this.ViewAs.clinicId;
    }
    noCallDateDTO.NoCallDate = this.FREE_HOLDS_appointmentsDateFrom;

    if (this.FreeAllDoctorsNoCallDates) {
      noCallDateDTO.DoctorsIds = this._unFiltered_Doctors.map(
        (e) => e.doctorId
      );
    } else {
      noCallDateDTO.DoctorsIds = this.FREE_HOLDS_selectedDoctors.map(
        (e) => e.doctorId
      );
    }

    const appointments_dates = moment(this.appointmentsDate)
      .locale('es')
      .format('DD [de] MMMM [del] YYYY');

    const tos = moment(this.FREE_HOLDS_appointmentsDate_To)
      .locale('es')
      .format('DD [de] MMMM [del] YYYY');

    const message = `¿Estas seguro de querer liberar las llamadas de (${
      this.cancellAllDoctorsAppointments
        ? 'TODOS los'
        : noCallDateDTO.DoctorsIds.length
    }) doctores desde ${appointments_dates} hasta ${tos}?`;

    noCallDateDTO.NoCallDate_To = this.FREE_HOLDS_appointmentsDate_To;

    const dialogData = new ConfirmDialogModel(
      'Confirmación',
      message,
      'Si',
      'Cancelar'
    );

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        const loading_dialog = this.dialog.open(LoadingDialogComponent, {
          maxWidth: '400px',
          data: new LoadingDialogModel('Cargando', '', false),
        });
        loading_dialog.disableClose = true;

        this.API.RemoveNoCallDate(noCallDateDTO).subscribe(
          () => {
            loading_dialog.close();
            this.dialog.open(ConfirmDialogComponent, {
              maxWidth: '600px',
              data: new ConfirmDialogModel(
                'Listo',
                'Las llamadas han sido liberadas para los doctores seleccionados.',
                'Okay',
                null
              ),
            });
            this.LoadNoCallDates();
          },
          (error) => {
            loading_dialog.close();
            this.showError(error);
          }
        );
      }
    });
  }
}
