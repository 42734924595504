import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Clinic } from 'src/app/Dtos/company/AccountOverview';
import {
  CompanyDialogComponent,
  CompanyDialogData,
} from '../../_dialogs/company-dialog/company-dialog.component';
import { User } from '../../_models';
import { AuthenticationService } from '../../_services/authentication.service';
import { AdminViewAsClinicDTO } from '../../dtos/AdminViewAsClinicDTO';
import { Company } from '../../DTOS/Company/Company';
import { LoadingDialogComponent } from '../../loading-dialog/loading-dialog.component';
@Component({
  selector: 'app-company',
  templateUrl: './clinic.component.html',
  styleUrls: ['./clinic.component.scss'],
})
/** company component*/
export class ClinicComponent implements AfterViewInit {
  /** company ctor */
  constructor(
    http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog
  ) {
    this.httpClient = http;
    this.baseUrl = baseUrl;
  }
  isLoading: boolean;
  Companies: Clinic[];
  loadingRef: MatDialogRef<LoadingDialogComponent>;
  httpClient: HttpClient;
  public ViewAs: AdminViewAsClinicDTO;
  baseUrl: string;
  public User: User;
  dataSource: MatTableDataSource<Clinic>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  displayedColumns: string[] = [
    'companyId',
    'companyName',
    'address',
    'Contacto',
    'EHR',
    'Actions',
  ];
  searchTearm: string;

  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
  }

  ngAfterViewInit() {
    this.User = this.authenticationService.currentUserValue;
    this.FetchClinics();
  }

  OpenCompanyDialog(company: Company) {
    const data: CompanyDialogData = {
      Company: company,
      title: company ? 'Editar Compañia' : 'Añadir Compañia',
    };
    this.dialog
      .open(CompanyDialogComponent, {
        data,
        width: '60%',
        disableClose: true,
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        const result = dialogResult as boolean;
        if (result) {
          this.FetchClinics();
        }
      });
  }

  public FetchClinics() {
    this.isLoading = true;
    const route = this.baseUrl + `Clinics/GetClinicsList`;

    this.httpClient.get(route).subscribe(
      (result) => {
        const res = result as Clinic[];
        this.Companies = res;
        this.dataSource = new MatTableDataSource<Clinic>(res);
        this.dataSource.paginator = this.paginator;
        this.isLoading = false;
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
      }
    );
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
