export class BoardTransitions {
  transitionId?: number;
  fromBoardColumnId?: number;
  toBoardColumnId?: number;
  action?: number; // 1 SMS, 2 EMAIL, 3 CALL
  actionObjId?: number;
  doctorId?: number | null;
  createdByUserId?: number;
  deletedByUserId?: number | null;
  createDate?: string | null;
  deleteDate?: string | null;
  patientOrder?: number | null;
  template?: string;
}
