import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnChanges } from '@angular/core';
import * as moment from 'moment';
import { SmsLogs } from 'src/app/DTOS/Chat/SmsLogs';

export interface IAppChatLinkedAppointment {
  appointmentId: number;
  patientPhoneNumber: string;
}
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnChanges {
  @Input() appointment: IAppChatLinkedAppointment;
  httpClient: HttpClient;
  baseUrl: string;
  isLoading: boolean;
  messages: SmsLogs[];
  newMessage: string;

  constructor(@Inject('BASE_URL') baseUrl: string, http: HttpClient) {
    this.httpClient = http;
    this.baseUrl = baseUrl;
  }
  public getJson(obj: any) {
    return JSON.stringify(obj);
  }
  public loadAppointmentData() {
    this.messages = [];
    this.httpClient
      .get(
        this.baseUrl +
          `SMS/GetAppointmentMessages?appointmentid=${this.appointment.appointmentId}`
      )
      .subscribe((r: SmsLogs[]) => {
        this.messages = r.map((msg) => ({
          ...msg,
          _timeSince: moment(msg.createDate).fromNow(),
        }));
      });
  }
  ngOnChanges() {
    if (this.appointment?.appointmentId) {
      this.loadAppointmentData();
    }
  }

  public cantSendMessage = () =>
    !this.newMessage || this.newMessage.length > 160

  public sendMessage(messageContent: string) {
    this.isLoading = true;
    const route = this.baseUrl + `SMS/sendmessage`;

    const dto = {
      AppointmentId: this.appointment.appointmentId,
      ToNumber: this.appointment.patientPhoneNumber,
      MessageContent: messageContent,
    };

    this.newMessage = '';

    this.httpClient.post(route, dto).subscribe(
      (newRecord: SmsLogs) => {
        this.messages.push(newRecord);
      },
      () => {}
    );
  }
}
