import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatRadioModule } from '@angular/material/radio';

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  BookingModel,
  EnumRecurrenceEvent,
} from 'src/app/DTOS/Bookings/BookingDTO';
import * as moment from 'moment';

import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/api.service';
import { BookingsBookingFormComponent } from 'src/app/Pages/bookings-booking-form/bookings-booking-form.component';
import { AgendaComponent } from 'src/app/agenda/agenda.component';
@Component({
  selector: 'app-recurrence-dialog',
  templateUrl: './recurrence-dialog.component.html',
  styleUrls: ['./recurrence-dialog.component.scss'],
})
export class RecurrenceDialogComponent implements OnInit {
  public dataFromMatDialogEvent: BookingModel;
  public recurrenceEvent: string;
  public _EnumRecurrenceEvent;
  public title = '';
  clinicId: number;
  @Input() public booking: BookingModel = {} as BookingModel;
  public isDeleteRecurrence: boolean;
  @Output('onSidenavClose') sidenavClose = new EventEmitter<string>();

  constructor(
    public dialog: MatDialog,
    private injector: Injector,
    private _snackBar: MatSnackBar,
    private API: ApiService,
    private _BookingFormComponent: BookingsBookingFormComponent,
    private _AgendaComponent: AgendaComponent
  ) {
    let data = this.injector.get(MAT_DIALOG_DATA, null);
    let { booking } = data;
    let { isDeleteRecurrence } = data;
    if (data) {
      this.booking = booking;
      this.clinicId = this.booking.clinicId;
      this.isDeleteRecurrence = isDeleteRecurrence;
    }
    this._EnumRecurrenceEvent = EnumRecurrenceEvent;
  }

  closeModal(): void {
    this.dialog.closeAll();
  }

  ngOnInit(): void {
    this.title = this.isDeleteRecurrence === false ? 'Editar' : 'Eliminar';
  }
  public recurrenceAction() {
    if (this.recurrenceEvent === EnumRecurrenceEvent.a_thisEvent) {
      this.RecordRecurrenceException();
    } else if (
      this.recurrenceEvent === EnumRecurrenceEvent.b_thisEventandThefollowing
    ) {
      this.SetRecurrenceEnd();
    } else if (this.recurrenceEvent === EnumRecurrenceEvent.c_allEvents) {
      if (this.isDeleteRecurrence === true) {
        this._BookingFormComponent.DeleteBooking(this.booking);
        this.closeModalPanel();
      } else {
        this.booking.isRecurring = true;
        this._BookingFormComponent.updateBooking(this.booking);
        this.closeModalPanel();
      }
    }
  }

  public RecordRecurrenceException() {
    this._BookingFormComponent.isLoading = true;
    this.API.RecordRecurrenceException(
      this.booking.bookingID,
      this.booking.startDateTime,
      this.booking.clinicId
    ).subscribe(
      (r) => {
        this.booking.isRecurring = false;
        this.booking.recurrencePattern = null;
        this.booking.bookingID = null;
        this.booking.clinicId = this.clinicId;
        this._BookingFormComponent.isLoading = false;
        if (this.isDeleteRecurrence === false) {
          this._BookingFormComponent.createBooking(true, this.booking);
        }
        this._AgendaComponent.onSidenavClose();
        this.closeModalPanel();
      },
      (f) => {
        window.alert('Lo sentimos, ha ocurrido un error.');
        console.error('ERROR AL actualizar la cita recurrente', f);
      }
    );
  }
  public SetRecurrenceEnd() {
    this._BookingFormComponent.isLoading = true;
    this.API.SetRecurrenceEnd(
      this.booking.bookingID,
      this.booking.startDateTime,
      this.booking.clinicId
    ).subscribe(
      () => {
        this.booking.isRecurring = true;
        this.booking.bookingID = null;
        if (this.isDeleteRecurrence === false) {
          this._BookingFormComponent.createBooking(true, this.booking);
        }
        this.closeModalPanel();
      },
      (f) => {
        window.alert('Lo sentimos, ha ocurrido un error.');
        console.error('ERROR AL actualizar la cita recurrente', f);
      }
    );
  }
  closeModalPanel() {
    this._BookingFormComponent.Close();
    this._BookingFormComponent.onSidenavClose();
  }
}
