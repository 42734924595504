export class ReminderSequenceCreationDTO {
  ReminderSequenceId: number;
  ReminderSetId: number;
  ReminderId: number;
  step: number;
  actionType: string;
  gatherNextStep: string;
  callStatusId: string;
  reminderType: string;
  messageOrPath: string;
  checked: boolean;
}
