import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { User } from 'src/app/_models';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ApiService } from 'src/app/api.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from 'src/app/confirm-dialog/confirm-dialog.component';
import { AdminViewAsClinicDTO } from 'src/app/dtos/AdminViewAsClinicDTO';
import { ClinicAssistanceViewModel } from '../../DTOS/Appointments/AppointmentListItem';
@Component({
  selector: 'app-assistance-status-config-page',
  templateUrl: './assistance-status-config-page.component.html',
  styleUrls: ['./assistance-status-config-page.component.scss'],
})
export class AssistanceStatusConfigPageComponent implements AfterViewInit {
  isSubmitting: Boolean = false;
  isLoading: Boolean = false;
  public User: User;
  @Input() public isStandalone: boolean;
  dataSource: MatTableDataSource<ClinicAssistanceViewModel>;
  public ViewAs: AdminViewAsClinicDTO;
  sidepanelStatus: ClinicAssistanceViewModel = {} as any;
  slidePanelOpen: Boolean = false;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns: string[] = [
    'assistanceStatusID',
    'assistanceStatusName',
    'color',
    'isDefault',
    'Edit',
  ];

  constructor(
    private API: ApiService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {}

  ngAfterViewInit() {
    this.User = this.authenticationService.currentUserValue;
    this.LoadLists();
  }

  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
    this.LoadLists();
  }

  public LoadLists() {
    this.isSubmitting = true;
    this.isLoading = true;
    const clinicId = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;

    this.API.GetAssistanceStatuses().subscribe(
      (result) => {
        const res = result as ClinicAssistanceViewModel[];
        this.dataSource = new MatTableDataSource<ClinicAssistanceViewModel>(
          res
        );
        this.dataSource.paginator = this.paginator;
        this.isSubmitting = false;
        this.isLoading = false;
      },
      (error) => {
        console.error(error);
        this.showError(error);
      }
    );
  }

  public Save() {
    this.isLoading = true;
    this.isSubmitting = true;
    this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId,
      this.API.CreateNewAssistanceStatus(this.sidepanelStatus).subscribe(
        () => {
          this.LoadLists();
          this.closeAddNewSidePanel();
          this.isSubmitting = false;
          this.isLoading = false;
        },
        (error) => {
          console.error(error);
          this.showError(error);
        }
      );
  }
  public Update() {
    this.isSubmitting = true;
    this.API.UpdateAssistanceStatus(this.sidepanelStatus).subscribe(() => {
      this.LoadLists();
      this.closeAddNewSidePanel();
      this.isSubmitting = false;
      this.isLoading = false;
      this._snackBar.open(`¡Listo!, el estatus ha sido actualizado. `, 'OK ', {
        duration: 70000,
        horizontalPosition: 'left',
        verticalPosition: 'bottom',
        panelClass: 'success-dialog',
      });
    });
  }

  private showError(error: any) {
    console.error('CITAMED ERROR', error);
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: new ConfirmDialogModel('Ha ocurrido un error', '', 'Okay', null),
    });
  }

  public closeAddNewSidePanel() {
    this.slidePanelOpen = false;
    this.sidepanelStatus = {} as any;
  }
  public openAddNewSidePanel() {
    this.slidePanelOpen = true;
    this.sidepanelStatus = {} as any;
  }
  public openEditSidePanel(item) {
    this.slidePanelOpen = true;
    this.sidepanelStatus = _.cloneDeep(item);
  }

  onToggle(ev) {
    this.sidepanelStatus.isDefault = ev.checked;
  }
}
