import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  OnChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { ConfirmationService } from 'primeng/api';
import { ApiService } from 'src/app/api.service';
import { GoogleCalendarDto } from 'src/app/DTOS/GoogleCalendars/GoogleCalendarDto';
import { Speciality } from 'src/app/DTOS/Speciality/Speciality';
import {
  DoctorDialogComponent,
  DoctorEditDialogData,
} from '../../_dialogs/doctor-dialog/doctor-dialog.component';
import { User } from '../../_models/user';
import { AuthenticationService } from '../../_services/authentication.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from '../../confirm-dialog/confirm-dialog.component';
import PermissionSlugs from '../../Constants/PermissionSlugs';
import { AdminViewAsClinicDTO } from '../../dtos/AdminViewAsClinicDTO';
import { DoctorDetailedDTO, GoogleTokenId } from '../../dtos/Doctors/DoctorDTO';
import {
  LoadingDialogComponent,
  LoadingDialogModel,
} from '../../loading-dialog/loading-dialog.component';
import { PermissionSlugsService } from '../../Services/PermissionSlugsService';

@Component({
  selector: 'app-doctors-list',
  templateUrl: './doctors-list.component.html',
  styleUrls: ['./doctors-list.component.scss'],
})
/** doctors-list component*/
export class DoctorsListComponent implements OnChanges, AfterViewInit {
  constructor(
    http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private permissionSlugsService: PermissionSlugsService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private API: ApiService,
    private confirmationService: ConfirmationService,
    private _snackBar: MatSnackBar
  ) {
    this.httpClient = http;
    this.baseUrl = baseUrl;
    if (this.sidepanelDoctor) {
      this.setData(this.sidepanelDoctor);
    }
    this.permissionSlugsService.fetch();
    this.permissionSlugsService.permissionsListener.subscribe((r) => {
      this.permissions = r;
    });
    this.authenticationService.currentUser.subscribe((u) => (this.User = u));
  }
  public permissions: string[] = [];
  googleCalendars: GoogleCalendarDto[];
  public googleToken: GoogleTokenId;

  public isLoadingGoogleCalendar = false;
  specialities: Speciality[];
  httpClient: HttpClient;
  baseUrl: string;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  slidePanelOpen: Boolean = false;
  slideEditPanelOpen: Boolean = false;
  isSubmitting: Boolean = false;
  sidepanelDoctor: DoctorDetailedDTO = {} as any;
  displayedColumns: string[] = [
    'doctorId',
    'doctorInternalIdentifier',
    'doctorName',
    'specialtyName',
    'Edit',
  ];

  public User: User;
  selectedAddTabIndex = new UntypedFormControl(0);
  dataSource: MatTableDataSource<DoctorDetailedDTO>;
  loadingRef: MatDialogRef<LoadingDialogComponent>;

  @Input() public ViewAs: AdminViewAsClinicDTO;
  @Input() public isStandalone: boolean;
  ngOnChanges(): void {
    this.LoadDoctors();
  }

  public hasGoogleCalendarPermission = () =>
    this.permissions.includes(PermissionSlugs.GOOGLE_CALENDAR);
  ngAfterViewInit() {
    this.User = this.authenticationService.currentUserValue;
    this.LoadDoctors();
    this.LoadSpecialities();
  }

  public setData(doctor: DoctorDetailedDTO) {
    this.sidepanelDoctor = {
      doctorId: doctor.doctorId,
      clinicId: doctor.clinicId,
      clinic_DoctorId: doctor.clinic_DoctorId,
      doctorSpecialtyId: doctor.doctorSpecialtyId,
      specialtyId: doctor.doctorSpecialtyId,
      clinicName: doctor.clinicName,
      doctorName: doctor.doctorName,
      doctorInternalIdentifier: doctor.doctorInternalIdentifier,
      specialtyName: doctor.specialtyName,
    } as DoctorDetailedDTO;
  }

  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
    this.LoadDoctors();
  }
  public openAddNewSidePanel() {
    this.slidePanelOpen = true;
    this.sidepanelDoctor = {} as any;
  }
  public openAddNewEditSidePanel(doctor: DoctorDetailedDTO) {
    this.slideEditPanelOpen = true;
    this.sidepanelDoctor = doctor as DoctorDetailedDTO;
    this.getGoogleConnection(this.sidepanelDoctor.doctorId);
  }
  public closeAddNewSidePanel() {
    this.slidePanelOpen = false;
    this.sidepanelDoctor = {} as any;
  }
  public closeAddNewEditSidePanel(doctor: DoctorDetailedDTO) {
    this.slideEditPanelOpen = false;
    this.sidepanelDoctor = doctor as DoctorDetailedDTO;
  }
  public LoadSpecialities() {
    this.API.GetSpecialities().subscribe((r) => {
      this.specialities = r;
    });
  }
  public LoadDoctors() {
    if (this.isStandalone && !this.ViewAs?.clinicId) {
      return;
    }
    const userId = this.ViewAs ? this.ViewAs.viewAsUserID : this.User.userId;
    this.ShowLoading();
    this.httpClient
      .get<DoctorDetailedDTO[]>(
        this.baseUrl + 'doctors/GetAllDoctorsDetailed?userid=' + userId
      )
      .subscribe(
        (result) => {
          const res = result as DoctorDetailedDTO[];
          if (res.length < 10) {
            _.range(10 - res.length).forEach(() => {
              res.push({} as any);
            });
          }
          this.dataSource = new MatTableDataSource<DoctorDetailedDTO>(res);
          this.dataSource.paginator = this.paginator;
          this.hideLoading();
        },
        (error) => {
          console.error(error);
          this.hideLoading();
          this.showError(error);
        }
      );
  }
  public ShowLoading() {
    const loading_dialog = this.dialog.open(LoadingDialogComponent, {
      maxWidth: '400px',
      data: new LoadingDialogModel('Cargando', '', false),
    });
    loading_dialog.disableClose = true;
    this.loadingRef = loading_dialog;
  }
  public hideLoading() {
    if (this.loadingRef) {
      this.loadingRef.close();
    }
  }
  private showError(error: any) {
    console.error('CITAMED ERROR', error);
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: new ConfirmDialogModel('Ha ocurrido un error', '', 'Okay', null),
    });
  }
  public enableCalendarSync(
    calendar: GoogleCalendarDto,
    doctorId: number,
    googleTokenId: number
  ) {
    this.isLoadingGoogleCalendar = true;
    const clinicid = this.getClinicID();
    this.API.EnableCalendarSync(clinicid, doctorId, {
      googleTokenId,
      googleCalendarId: calendar.id,
      description: calendar.description,
      summary: calendar.summary,
    }).subscribe((r) => {
      this.LoadGoogleCalendars(clinicid, doctorId);
    });
  }

  public disableCalendarSync(
    doctorId: number,
    googleConnectedCalendarId: number
  ) {
    this.isLoadingGoogleCalendar = true;
    const clinicid = this.getClinicID();
    this.API.DisableCalendarSync(
      clinicid,
      doctorId,
      googleConnectedCalendarId
    ).subscribe((r) => {
      this.LoadGoogleCalendars(clinicid, doctorId);
    });
  }

  public deleteDoctor(event: Event, doctorId: number) {
    if (this.User?.isAdmin === false) {
      return;
    }

    this.confirmationService.confirm({
      target: event.target,
      message: '¿Estás segur@ de querer eliminar este doctor?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si, eliminar',
      rejectLabel: 'Cancelar',
      accept: () =>
        this.API.DeleteDoctor(doctorId).subscribe(
          (r) => {
            this.slideEditPanelOpen = false;
            this.LoadDoctors();
          },
          (f) => {
            window.alert('Lo sentimos, ha ocurrido un error.');
            console.error('ERROR AL eliminar doctor', f);
          }
        ),
      reject: () => {
        this.confirmationService.close();
      },
    });
  }

  public saveDoctor() {
    this.isSubmitting = true;
    this.API.CreateDoctor({
      doctorName: this.sidepanelDoctor.doctorName,
      doctorInternalIdentifier:
        _.isEmpty(this.sidepanelDoctor.doctorInternalIdentifier) === true
          ? this.sidepanelDoctor.doctorName
          : this.sidepanelDoctor.doctorInternalIdentifier,
      specialtyId: this.sidepanelDoctor.specialtyId,
      ClinicId: this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId,
    }).subscribe(() => {
      this.LoadDoctors();
      this.closeAddNewSidePanel();
      this.isSubmitting = false;
    });
  }
  OpenDoctorDialog(doctor: DoctorDetailedDTO) {
    const data: DoctorEditDialogData = {
      doctor,
      title: 'Editar Doctor',
    };

    this.dialog
      .open(DoctorDialogComponent, {
        data,
        width: '50%',
        height: '400px',
        disableClose: true,
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        const result = dialogResult as boolean;
        if (result) {
          this.LoadDoctors();
        }
      });
  }
  public uploadComplete() {
    this.LoadDoctors();
    this.slidePanelOpen = false;
    this._snackBar.open(
      `¡Listo!, el archivo ha sido cargado correctamente. `,
      'OK ',
      {
        duration: 10000,
        horizontalPosition: 'left',
        verticalPosition: 'bottom',
        panelClass: 'success-dialog',
      }
    );
  }
  public getImportUrl() {
    let clinicid = this.User?.clinicId;

    if (this.ViewAs?.clinicId) {
      clinicid = this.ViewAs?.clinicId;
    }

    return `/Doctors/Import?clinicId=${clinicid}`;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public onConnectGoogleCalendarButtonClicked = () => {
    if (!this.googleToken) {
      window.open(
        `/api/GoogleConnection/Connect?doctorId=${this.sidepanelDoctor.doctorId}`,
        '_blank'
      );
      window.focus();
    } else {
      this.confirmationService.confirm({
        target: event.target,
        message: '¿Estás segur@ de querer desconectar tu calendario de google?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.disconnectGoogleConnection(this.googleToken);
        },
        reject: () => {
          this.confirmationService.close();
        },
      });
    }
  };

  public getClinicID() {
    let clinicid = this.User?.clinicId;
    if (this.ViewAs?.clinicId) {
      clinicid = this.ViewAs?.clinicId;
    }
    return clinicid;
  }

  public getGoogleConnection(doctorID: number) {
    this.googleCalendars = [];
    this.googleToken = null;
    this.API.GetGoogleConnection(doctorID).subscribe((r) => {
      this.googleToken = r;
      if (this.googleToken) {
        const clinicid = this.getClinicID();
        this.isLoadingGoogleCalendar = true;
        this.LoadGoogleCalendars(clinicid, doctorID);
      }
    });
  }

  public TogglePrimaryCalendarSync(doctorID: number, GoogleTokenId: number) {
    this.isLoadingGoogleCalendar = true;
    const clinicid = this.getClinicID();
    this.API.TogglePrimaryCalendarSync(
      clinicid,
      doctorID,
      GoogleTokenId
    ).subscribe((r) => {
      this.isLoadingGoogleCalendar = false;
      this.googleToken.dontSyncPrimaryCalendar =
        !this.googleToken.dontSyncPrimaryCalendar;
    });
  }
  private LoadGoogleCalendars(clinicid: number, doctorID: number) {
    this.API.getGoogleCalendars(clinicid, doctorID).subscribe((res) => {
      const primaryCalendar = res.find((p) => p.primary === true);
      const otherCalendars = res.filter((p) => !p.primary);
      this.googleCalendars = [primaryCalendar, ...otherCalendars];
      this.isLoadingGoogleCalendar = false;
    });
  }

  public disconnectGoogleConnection(token: GoogleTokenId) {
    this.API.DisconnectGoogleConnection(token).subscribe(() => {
      this._snackBar.open(
        '¡Listo!, se ha desconectado el Google Calendar.',
        'OK ',
        {
          duration: 10000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
          panelClass: 'success-dialog',
        }
      );
      this.slideEditPanelOpen = false;
    });
  }

  public async updateDoctor(_doctor: DoctorDetailedDTO) {
    const route = this.baseUrl + `Doctors/UpdateDoctor`;
    const doctor = {
      clinicId: _doctor.clinicId,
      clinicDoctorId: _doctor.clinic_DoctorId,
      doctorId: _doctor.doctorId,
      specialtyId: _doctor.specialtyId,
      doctorName: _doctor.doctorName,
      doctorInternalIdentifier: _doctor.doctorInternalIdentifier,
    };

    this.API.UpdateDoctor({
      ClinicId: _doctor.clinicId,
      ClinicDoctorId: _doctor.clinic_DoctorId,
      doctorId: _doctor.doctorId,
      specialtyId: _doctor.specialtyId,
      doctorName: _doctor.doctorName,
      doctorInternalIdentifier: _doctor.doctorInternalIdentifier,
    }).subscribe(
      () => {
        this._snackBar.open(
          '¡Listo!, se ha actualizado el Identificador Interno.',
          'OK ',
          {
            duration: 10000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: 'success-dialog',
          }
        );
        this.slideEditPanelOpen = false;
      },
      (f) => {
        window.alert('Lo sentimos, ha ocurrido un error.');
        console.error('ERROR AL actualizar Identificador Interno', f);
      }
    );
  }
}
