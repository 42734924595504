export default {
  DIGITAL_WAITING_LIST: 'Features.DWL',
  VIRTUAL_ASSISTANT: 'Features.VA',
  CAMPAIGNS: 'Features.BM',
  TRANSITIONAL_CARE: 'Features.TC',
  APPOINTMENT_ASSISTANCE: 'Features.AS',
  BLOCK_APPOINTMENT_MANAGEMENT: 'AntiPerm.AppointmentManagement',
  GOOGLE_CALENDAR: 'Features.GooglCal',
  PATIENT_MANAGEMENT: 'Features.PatientManagement',
  HOME_V2: 'Features.HomeSummary.v2',
  BOOKINGS: 'Features.Bookings',
};
