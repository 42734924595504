import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';

import { ApiService } from '../api.service';
import { CustomIntent } from '../DTOS/VA_Configurations/CustomIntent';
import GenericList from '../DTOS/Generics/GenericList';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-custom-intents',
  templateUrl: './custom-intents.component.html',
  styleUrls: ['./custom-intents.component.scss'],
})
export class CustomIntentsComponent implements OnInit, OnChanges {
  list: GenericList<CustomIntent>;
  public customIntentFormModel: CustomIntent;
  public slidePanelOpen: boolean;
  public isSubmitting: boolean;

  constructor(private API: ApiService, private _snackBar: MatSnackBar) {}

  @Input() public accountID: number;
  @Input() public isStandalone: boolean;

  public page: number;
  public quantity: number;
  public isLoading: boolean;
  public searchTearm: string;

  ngOnInit(): void {
    this.InitializeList();
  }

  private InitializeList() {
    this.page = 1;
    this.quantity = 15;
    this.LoadList();
  }

  ngOnChanges(): void {
    this.InitializeList();
  }

  public handlePage(event: PageEvent) {}

  public LoadList() {
    this.isLoading = true;
    this.API.GetCustomIntentsList(
      this.accountID,
      this.page,
      this.quantity
    ).subscribe((result) => {
      this.list = result;
      this.isLoading = false;
    });
  }

  public closeFormSidePanel() {
    this.customIntentFormModel = null;
    this.slidePanelOpen = false;
    this.isSubmitting = false;
  }

  public initializeAddNewForm() {
    this.customIntentFormModel = {
      accountID: this.accountID,
      customIntentId: 0,
      prompt: '',
      response: '',
      customIntentName: '',
    };
    this.slidePanelOpen = true;
  }

  public SaveFormChanges() {
    this.isSubmitting = true;
    if (this.customIntentFormModel.customIntentId) {
      this.API.UpdateCustomIntent(this.customIntentFormModel).subscribe((r) => {
        this.LoadList();
        this._snackBar.open(
          `¡Listo!, la habilidad personalizada "${this.customIntentFormModel.customIntentName}" ha sido actualizada. `,
          'OK ',
          {
            duration: 10000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: 'success-dialog',
          }
        );

        this.closeFormSidePanel();
      });
    } else {
      this.API.CreateCustomIntent(this.customIntentFormModel).subscribe((r) => {
        this.LoadList();
        this._snackBar.open(
          `¡Listo!, la habilidad personalizada "${this.customIntentFormModel.customIntentName}" ha sido añadida. `,
          'OK ',
          {
            duration: 10000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: 'success-dialog',
          }
        );
        this.closeFormSidePanel();
      });
    }
  }

  public DeleteCustomIntent({
    customIntentId,
    customIntentName,
  }: CustomIntent) {
    const confirm = window.confirm(
      '¿Estás segur@ de querer eliminar esta habilidad?'
    );
    if (!confirm) return;

    this.API.DeleteCustomIntent(customIntentId).subscribe(() => {
      this._snackBar.open(
        `¡Listo!, la habilidad personalizada "${customIntentName}" ha sido eliminada. `,
        'OK ',
        {
          duration: 10000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
          panelClass: 'success-dialog',
        }
      );
      this.LoadList();
      this.closeFormSidePanel();
    });
  }
}
