import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Howl } from 'howler';
import {
  ApiService,
  GetReminderSchedulesConfigResult,
} from 'src/app/api.service';
import { AddAppointmentDto } from 'src/app/dtos/Appointments/AppointmentDTO';
import { Doctor } from '../../dtos/Doctors/DoctorDTO';
import { ReminderSetDto } from '../../dtos/ReminderSets/ReminderSetDto';

export interface AddAppointmentDialogData {
  clinicID: number;
  UserId: number;
  ClinicName: string;
}

type AppointmentConfigMode = 'MANUAL' | 'AUTO';
type AddAppointmentStep = 'ConfigSelection' | 'Form' | 'SuccessMessage';
@Component({
  selector: 'app-add-appointments-dialog',
  templateUrl: './add-appointments-dialog.component.html',
  styleUrls: ['./add-appointments-dialog.component.scss'],
})

/** add-appointments-dialog component*/
export class AddAppointmentsDialogComponent implements AfterViewInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddAppointmentDialogData,
    public dialogRef: MatDialogRef<AddAppointmentsDialogComponent>,
    private _snackBar: MatSnackBar,
    @Inject('BASE_URL') baseUrl: string,
    http: HttpClient,
    public apiService: ApiService
  ) {
    this.httpClient = http;
    this.baseUrl = baseUrl;

    this.appointmentData = new AddAppointmentDto();
    this.appointmentData.clinicId = this.data.clinicID;
    this.appointmentData.UserId = this.data.UserId;
  }
  minSelectedDate: Date = new Date();
  appointmentConfigMode: AppointmentConfigMode = null;
  addAppointmentStep: AddAppointmentStep = 'ConfigSelection';
  selectedReminderSet: ReminderSetDto;
  appointmentData: AddAppointmentDto;
  httpClient: HttpClient;
  current_Audio_Hawl: any;
  isLoading = false;
  isPlayingAudio = false;
  getReminderSchedulesConfigResults: GetReminderSchedulesConfigResult[];
  public doctors: Doctor[];
  public ReminderSets: ReminderSetDto[] = new Array<ReminderSetDto>();
  baseUrl: string;
  @ViewChild('AppointmentsDatePicker')
  AppointmentsDatePicker;
  @ViewChild('AppointmentsCallDatePicker')
  AppointmentsCallDatePicker;
  ngAfterViewInit() {
    this.FetchReminderSets();
    this.FetchDoctors();
  }
  public onDismiss() {
    if (this.current_Audio_Hawl) {
      this.StopAudio();
    }
    this.dialogRef.close(false);
  }
  public onConfigModeSelected() {
    this.appointmentData.appointmentDate = null;
    if (this.appointmentConfigMode === 'MANUAL') {
      this.FetchDoctors();
      this.FetchReminderSets();
    }
  }
  public FetchReminderSets() {
    this.isLoading = true;
    this.httpClient
      .get<ReminderSetDto[]>(
        this.baseUrl + `ReminderSet/GetAll?ClinicID=` + this.data.clinicID
      )
      .subscribe(
        (result) => {
          const res = result as ReminderSetDto[];
          this.ReminderSets = res;
          this.isLoading = false;
        },
        (error) => {
          console.error(error);
          this.isLoading = false;
        }
      );
  }
  public FetchDoctors() {
    this.isLoading = true;
    const ClinicID = this.data.clinicID;
    this.httpClient
      .get<Doctor[]>(this.baseUrl + 'Doctors/GetAll?ClinicID=' + ClinicID)
      .subscribe(
        (result) => {
          const res = result as Doctor[];
          this.doctors = res;
          this.isLoading = false;
        },
        (error) => {
          console.error(error);
          this.isLoading = false;
        }
      );
  }

  SetIsAudioPlaying(val: boolean) {
    this.isPlayingAudio = val;
  }
  PlayAudioChain(file_names: string[]) {
    if (file_names.length) {
      this.isPlayingAudio = true;
      const arg = {
        src: file_names[0],
        onend: () => {
          file_names.shift();
          if (file_names.length > 0) {
            this.isPlayingAudio = true;
            this.PlayAudioChain(file_names);
          } else {
            this.isPlayingAudio = false;
          }
        },
      };
      this.current_Audio_Hawl = new Howl(arg);
      this.current_Audio_Hawl.play();
    } else {
      this.isPlayingAudio = false;
    }
  }
  StopAudio() {
    this.current_Audio_Hawl.stop();
    this.isPlayingAudio = false;
  }

  public playReminderSetAudios(remset: ReminderSetDto) {
    const links = remset.audios
      .filter((a) => a.messageOrPath.indexOf('http') > -1)
      .map((e) => e.messageOrPath);
    this.PlayAudioChain(links);
  }
  public SaveChanges() {
    this.isLoading = true;

    try {
      this.appointmentData.reminderSetId =
        this.selectedReminderSet.reminderSetId;
      this.httpClient
        .post(
          this.baseUrl + `Appointments/AddAppointment`,
          this.appointmentData
        )
        .subscribe(
          () => {
            this.isLoading = false;
            this.dialogRef.close(true);
            this._snackBar.open(
              `¡Listo!, la cita de ${this.appointmentData.patientName} ha sido añadida. `,
              'OK ',
              {
                duration: 10000,
                horizontalPosition: 'left',
                verticalPosition: 'bottom',
                panelClass: 'success-dialog',
              }
            );
          },
          (e) => {
            this.isLoading = false;
            console.error('ERROR', e);
            alert('Ha ocurrido un error');
          }
        );
    } catch (e) {
      this.isLoading = false;
      2;
      this.dialogRef.close(false);
      console.error('ERROR', e);
      alert('Ha ocurrido un error');
    }
  }

  public onAppointmentDateChange(appointmentDate: Date) {
    if (this.appointmentConfigMode === 'AUTO') {
      const ClinicID = this.data.clinicID;
      this.doctors = [];
      this.apiService
        .GetReminderSchedulesConfig(ClinicID, appointmentDate)
        .subscribe((result) => {
          this.getReminderSchedulesConfigResults = result;
          const doctors = result.map((r) => {
            const doc: Doctor = {
              doctorId: r.doctorId,
              doctorName: r.doctorName,
            };
            return doc;
          });
          this.doctors = doctors;
        });
    }
  }

  public onDoctorSelected(doctorId: number) {
    if (this.appointmentConfigMode === 'AUTO') {
      const remschedule = this.getReminderSchedulesConfigResults.find(
        (d) => d.doctorId === doctorId
      );
      this.selectedReminderSet = this.ReminderSets.find(
        (r) =>
          r.reminderSetId === Number(remschedule.reminderSetName.split('-')[0])
      );
      this.appointmentData.callDate = new Date(remschedule.callDate);
      this.appointmentData.reminderScheduleId = remschedule.reminderScheduleId;
    }
  }
}
