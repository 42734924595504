import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { User } from '../../_models';
import { AuthenticationService } from '../../_services/authentication.service';
import { AdminViewAsClinicDTO } from '../../dtos/AdminViewAsClinicDTO';
import { Company } from '../../DTOS/Company/Company';
import { ApiService } from 'src/app/api.service';
import { MatSort } from '@angular/material/sort';
@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
})
/** company component*/
export class CompanyComponent implements AfterViewInit {
  constructor(
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private API: ApiService
  ) {}
  isLoading: boolean;
  Companies: Company[];
  public ViewAs: AdminViewAsClinicDTO;
  baseUrl: string;
  public User: User;
  dataSource: MatTableDataSource<Company>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  recordsCount = 0;

  displayedColumns: string[] = [
    'companyId',
    'companyName',
    'address',
    'Contacto',
    'ratePlan',
    'isActive',
    'Actions',
  ];
  searchTearm: string;

  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
  }

  ngAfterViewInit() {
    this.User = this.authenticationService.currentUserValue;
    this.FetchCompanies();
  }

  public FetchCompanies() {
    this.isLoading = true;
    this.API.GetCompany().subscribe(
      (result) => {
        const res = result as Company[];
        this.Companies = res;
        this.dataSource = new MatTableDataSource<Company>(res);
        this.dataSource.paginator = this.paginator;
        this.isLoading = false;
        this.recordsCount = res.length;
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
      }
    );
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
