import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/api.service';
import {
  AddElectronicCalendar,
  ElectronicCalendar,
} from 'src/app/DTOS/Ehrs/EhrGetDTO';

export interface EHRDialogData {
  electronicCalendar: ElectronicCalendar;
  title: String;
}

@Component({
  selector: 'app-add-ehr-dialog',
  templateUrl: './add-ehr-dialog.component.html',
  styleUrls: ['./add-ehr-dialog.component.scss'],
})
export class AddEhrDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EHRDialogData,
    private API: ApiService,
    public dialogRef: MatDialogRef<AddEhrDialogComponent>,
    private _snackBar: MatSnackBar
  ) {
    this.setAsNewRecord();
  }

  isLoading = false;
  public EHR: ElectronicCalendar;

  ngOnInit(): void {}

  public setAsNewRecord() {
    this.EHR = new AddElectronicCalendar();
  }

  public async Save() {
    const confirm = window.confirm(
      `¿Estás segur@ de querer guardar el record electronico?`
    );
    if (!confirm) {
      return;
    }
    this.API.CreateElectronicCalendar(this.EHR).subscribe(
      () => {
        this.isLoading = false;
        this.dialogRef.close(true);
        this._snackBar.open(
          `¡Listo!, se ha creado el record electronico ${this.EHR.electronicCalendarName}.`,
          'OK ',
          {
            duration: 10000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: 'success-dialog',
          }
        );
      },
      (f) => {
        this.isLoading = false;
        this._snackBar.open('Lo sentimos, ha ocurrido un error.');
        console.error('ERROR AL CREAR RECORD ELECTRONICO', f);
      }
    );
  }

  public Close() {
    this.dialogRef.close();
  }
}
