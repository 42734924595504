import * as moment from 'moment';

import { AfterViewInit, Component, ViewChild } from '@angular/core';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from '../../confirm-dialog/confirm-dialog.component';
import {
  LoadingDialogComponent,
  LoadingDialogModel,
} from '../../loading-dialog/loading-dialog.component';

import { AdminViewAsClinicDTO } from '../../dtos/AdminViewAsClinicDTO';
import { ApiService } from 'src/app/api.service';
import { AuthenticationService } from '../../_services/authentication.service';
import { Doctor } from '../../Dtos/Doctors/DoctorDTO';
import { Howl } from 'howler';
import { HttpClient } from '@angular/common/http';
import { IAppointmentDTO } from '../../dtos/Appointments/AppointmentDTO';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import PermissionSlugs from 'src/app/Constants/PermissionSlugs';
import { PermissionSlugsService } from 'src/app/Services/PermissionSlugsService';
import { ReminderSetDto } from '../../dtos/ReminderSets/ReminderSetDto';
import { SelectionModel } from '@angular/cdk/collections';
import { SpecialAnnouncementCreateDTO } from '../../dtos/SpecialAnnouncement/SpecialAnnouncement';
import { User } from '../../_models/user';
import { deepCopy } from '../../helpers/DeepCopy';

@Component({
  selector: 'app-special-announcement',
  templateUrl: './special-announcement.component.html',
  styleUrls: ['./special-announcement.component.scss'],
})
export class SpecialAnnouncementComponent implements AfterViewInit {
  constructor(
    private authenticationService: AuthenticationService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private API: ApiService,
    private permissionSlugsService: PermissionSlugsService
  ) {
    this.appointmentsDate = (() => {
      const result = new Date();
      result.setDate(result.getDate() + 1);
      return result;
    })();
    this.callTime = moment(new Date()).format('HH:mm');

    this.screenWidth = window.innerWidth;
    window.addEventListener('resize', () => {
      this.screenWidth = window.innerWidth;
    });
  }
  minSelectedDate: Date = new Date();
  @ViewChild('AppointmentsDatePicker')
  AppointmentsDatePicker;
  @ViewChild('AppointmentsCallDatePicker')
  @ViewChild(MatSort)
  sort: MatSort;
  AppointmentsCallDatePicker;
  httpClient: HttpClient;
  baseUrl: string;
  current_Audio_Hawl: any;
  appointmentsDate: Date = (() => {
    const result = new Date();
    result.setDate(result.getDate() + 1);
    return result;
  })();
  callDate: Date = new Date();
  public ViewAs: AdminViewAsClinicDTO;
  public User: User;
  public maxAttempts: number;
  callTime: string;
  private isLoadingDoctors = false;
  private isLoadingAppointments = false;
  private isLoadingReminderSets = false;
  public isPlayingAudio = true;
  public displayedHeader: string[] = [];
  public screenWidth: number;
  displayedColumns: string[] = [
    'select',
    'patientName',
    'patientPhoneNumber',
    'doctorName',
    '_formatedTime',
    'callStatusName',
  ];
  HeaderMobile: string[] = ['select'];
  public appointmentsDatasource: MatTableDataSource<IAppointmentDTO> =
    new MatTableDataSource<IAppointmentDTO>([]);
  public _unfilteredAppointments: IAppointmentDTO[];
  selection = new SelectionModel<IAppointmentDTO>(true, []);
  public recordsCount = 0;
  public ReminderSets: ReminderSetDto[] = new Array<ReminderSetDto>();
  public Doctors: Doctor[] = new Array<Doctor>();

  public _unFiltered_ReminderSets: ReminderSetDto[] =
    new Array<ReminderSetDto>();
  public _unFiltered_Doctors: Doctor[] = new Array<Doctor>();

  public selectedReminderSet: ReminderSetDto;
  public selectedDoctor: Doctor;
  public hasAntiPermAppointmentsManagement = false;
  isLoadingPermissions = true;
  public isViewLoading(): boolean {
    return (
      this.isLoadingDoctors ||
      this.isLoadingReminderSets ||
      this.isLoadingAppointments
    );
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.appointmentsDatasource.data.length;
    return numSelected === numRows;
  }
  onDoctorChange() {
    if (this.selectedDoctor) {
      this.appointmentsDatasource = new MatTableDataSource(
        this._unfilteredAppointments.filter(
          (d) => d.doctorId === this.selectedDoctor.doctorId
        )
      );
    } else {
      this.appointmentsDatasource = new MatTableDataSource(
        this._unfilteredAppointments
      );
    }
    this.selection.clear();
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.appointmentsDatasource.data.forEach((row) =>
          this.selection.select(row)
        );
  }
  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: IAppointmentDTO): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }

    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row `;
  }
  ngAfterViewInit() {
    this.User = this.authenticationService.currentUserValue;
    this.FetchDoctors('');
    this.FetchReminderSets('');
    this.LoadAppointments();
    this.User = this.authenticationService.currentUserValue;
    this.permissionSlugsService.fetch();
    this.permissionSlugsService.permissionsListener.subscribe((r) => {
      this.hasAntiPermAppointmentsManagement = r.includes(
        PermissionSlugs.BLOCK_APPOINTMENT_MANAGEMENT
      );
    });
    this.permissionSlugsService.loaderListener.subscribe((r) => {
      this.isLoadingPermissions = r;
    });
    this.appointmentsDatasource.sort = this.sort;
    this.displayedHeader =
      this.screenWidth > 600 ? this.displayedColumns : this.HeaderMobile;
  }

  public LoadAppointments() {
    let clinicId = this.User.clinicId;
    if (this.ViewAs) {
      clinicId = this.ViewAs.clinicId;
    }
    this._unfilteredAppointments = null;
    this.appointmentsDatasource = new MatTableDataSource([]);
    const _date = moment(this.appointmentsDate).format('YYYY-MM-DD');

    this.isLoadingAppointments = true;
    this.selection.clear();

    this.API.GetAllAppointments(clinicId, _date).subscribe(
      (result) => {
        const rems = result as IAppointmentDTO[];
        this._unfilteredAppointments = rems;
        rems.forEach((e) => {
          e._formatedTime = moment(e.appointmentDate).format('hh:mm A');
          e.callStatusName = e.status.callStatusName;
        });
        this.isLoadingAppointments = false;
        this.appointmentsDatasource = new MatTableDataSource(rems);
        this.recordsCount = rems.length;
        this.appointmentsDatasource.sort = this.sort;
      },
      (e) => {
        this.isLoadingAppointments = false;

        alert('Ha ocurrido un error');
        console.error('ERROR', e);
      }
    );
  }

  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
    this.FetchDoctors('');
    this.FetchReminderSets('');
    this.LoadAppointments();
    this._unfilteredAppointments = null;
    this.appointmentsDatasource = new MatTableDataSource([]);
  }

  public onDoctorFilterStringChange(filter: string) {
    if (!filter) {
      this.Doctors = deepCopy<Doctor[]>(this._unFiltered_Doctors);
    }
    filter = filter.toLowerCase();
    const filteredDoctors = new Array<Doctor>();
    for (let i = 0; i < this._unFiltered_Doctors.length; i++) {
      const option = this._unFiltered_Doctors[i];
      if (
        (option.doctorId + ' - ' + option.doctorName)
          .toLowerCase()
          .indexOf(filter) >= 0
      ) {
        filteredDoctors.push(deepCopy<Doctor>(option));
      }
    }
    this.Doctors = filteredDoctors;
  }

  public onRemindersetFilterStringChange(filter: string) {
    if (!filter) {
      this.ReminderSets = deepCopy<ReminderSetDto[]>(
        this._unFiltered_ReminderSets
      );
    }
    filter = filter.toLowerCase();
    const filteredReminderSets = new Array<ReminderSetDto>();
    for (let i = 0; i < this._unFiltered_ReminderSets.length; i++) {
      const option = this._unFiltered_ReminderSets[i];
      if (
        (option.reminderSetId + ' - ' + option.reminderSetName)
          .toLowerCase()
          .indexOf(filter) >= 0
      ) {
        filteredReminderSets.push(deepCopy<ReminderSetDto>(option));
      }
    }
    this.ReminderSets = filteredReminderSets;
  }

  openAppointmentsDatePicker() {
    this.AppointmentsDatePicker.open();
  }

  public FetchDoctors(searchTerm: string) {
    this.isLoadingDoctors = true;
    let clinicId = this.User.clinicId;

    if (this.ViewAs) {
      clinicId = this.ViewAs.clinicId;
    }

    this.API.GetAllDoctors(clinicId).subscribe(
      (result) => {
        const res = result as Doctor[];
        this._unFiltered_Doctors = res;
        this.Doctors = deepCopy<Doctor[]>(this._unFiltered_Doctors);
        this.isLoadingDoctors = false;
      },
      (error) => console.error(error)
    );
  }
  SetIsAudioPlaying(val: boolean) {
    this.isPlayingAudio = val;
  }
  PlayAudioChain(file_names: string[]) {
    if (file_names.length) {
      this.isPlayingAudio = true;
      const arg = {
        src: file_names[0],
        onend: () => {
          file_names.shift();
          if (file_names.length > 0) {
            this.isPlayingAudio = true;
            this.PlayAudioChain(file_names);
          } else {
            this.isPlayingAudio = false;
          }
        },
      };
      this.current_Audio_Hawl = new Howl(arg);
      this.current_Audio_Hawl.play();
    } else {
      this.isPlayingAudio = false;
    }
  }
  StopAudio() {
    this.current_Audio_Hawl.stop();
    this.isPlayingAudio = false;
  }

  public playReminderSetAudios(remset: ReminderSetDto) {
    const links = remset.audios
      .filter((a) => a.messageOrPath.indexOf('http') > -1)
      .map((e) => e.messageOrPath);
    this.PlayAudioChain(links);
  }
  public FetchReminderSets(searchTerm: string) {
    this.isLoadingReminderSets = true;
    let clinicId = this.User.clinicId;
    if (this.ViewAs) {
      clinicId = this.ViewAs.clinicId;
    }

    this.API.GetAllReminderSet(clinicId).subscribe(
      (result) => {
        const res = result as ReminderSetDto[];
        this.ReminderSets = res;
        this._unFiltered_ReminderSets = res;
        this.isLoadingReminderSets = false;
      },
      (error) => console.error(error)
    );
  }

  SubmitSpecialAnnouncement() {
    const dto = new SpecialAnnouncementCreateDTO();
    dto.CallAttempts = this.maxAttempts;
    dto.appointmentsIds = this.selection.selected.map((a) => a.appointmentId);
    dto.callDate = this.callDate;
    dto.callTime = this.callTime;
    dto.clinicId = this.User.clinicId;
    if (this.ViewAs) {
      dto.clinicId = this.ViewAs.clinicId;
    }
    dto.reminderSetId = this.selectedReminderSet.reminderSetId;

    const message = `¿Estas seguro de querer enviar la llamada especial a los ${dto.appointmentsIds.length} pacientes seleccionados?`;

    const dialogData = new ConfirmDialogModel(
      'Confirmación',
      message,
      'Si',
      'Cancelar'
    );

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((userHasAccepted) => {
      if (userHasAccepted) {
        const LoadingDialog = this.dialog.open(LoadingDialogComponent, {
          maxWidth: '400px',
          data: new LoadingDialogModel('Cargando', '', false),
          disableClose: true,
        });
        this.API.SendSpecialAnnouncement(dto).subscribe(
          () => {
            this._snackBar.open(
              '¡Listo!, la llamada especial ha sido agendada.',
              'ok ',
              {
                duration: 10000,
                horizontalPosition: 'left',
                verticalPosition: 'bottom',
                panelClass: 'success-dialog',
              }
            );
            LoadingDialog.close();
          },
          (error) => {
            LoadingDialog.close();

            alert('Ha ocurrido un error al intentar procesar su solicitud.');
            console.error('Batch Post Error:', error);
          }
        );
      }
    });
  }
}
