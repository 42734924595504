import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { Subject, Subscription } from 'rxjs';
import 'rxjs/add/operator/debounceTime';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AdminViewAsClinicDTO } from '../../DTOS/AdminViewAsClinicDTO';
@Component({
  selector: 'app-master-user-view-as-selector',
  templateUrl: './master-user-view-as-selector.component.html',
  styleUrls: ['./master-user-view-as-selector.component.scss'],
})
/** MasterUserViewAsSelector component*/
export class MasterUserViewAsSelectorComponent implements AfterViewInit {
  constructor(
    http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private router: Router
  ) {
    this.httpClient = http;
    this.baseUrl = baseUrl;
    this.searchTermChanged
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((model) => {
        this.FetchClinics(model);
      });
  }
  fetchRequest: Subscription;

  @Output() messageEvent = new EventEmitter<AdminViewAsClinicDTO>();
  httpClient: HttpClient;
  clinics: AdminViewAsClinicDTO[] = [];
  searchTerm: string;
  searchTermChanged: Subject<string> = new Subject<string>();
  selectedClinic: AdminViewAsClinicDTO;
  baseUrl: string;
  clinicID: number;
  ngAfterViewInit() {
    if (!this.clinics?.length) {
      this.FetchClinics('', true);
    }
  }

  public FetchClinics(searchTerm: string, isInitialLoad?: boolean) {
    const isNumber = !!Number(searchTerm);
    const queryParams = {
      ...(isNumber ? { clinicID: Number(searchTerm) } : { searchTerm }),
    };
    this.fetchRequest = this.httpClient
      .get<AdminViewAsClinicDTO[]>(
        this.baseUrl +
          `Clinics/GetClinics?${new URLSearchParams(
            queryParams as any
          ).toString()}`
      )
      .subscribe(
        (result) => {
          if (isInitialLoad && this.selectedClinic) {
            return;
          }
          const results_ = result;
          this.clinics = _.orderBy(results_, ['isActive'], ['desc']);
        },
        (error) => console.error(error)
      );
  }
  sendMessage(clinicID: number) {
    this.selectedClinic = this.clinics.find((c) => c.clinicId === clinicID);
    this.messageEvent.emit(this.selectedClinic);
  }
  onViewAsFilterStringChange(searchTerm: string) {
    this.searchTermChanged.next(searchTerm);
  }
  public selectValue(clinic: AdminViewAsClinicDTO) {
    if (this.fetchRequest) {
      this.fetchRequest.unsubscribe();
    }
    this.clinics = [clinic];
    this.selectedClinic = this.clinics[0];
    this.clinicID = this.selectedClinic.clinicId;
  }

  public navigateToClinic(clinicId: number) {
    this.router.navigate(['/clinic/' + clinicId]);
  }
}
