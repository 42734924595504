import { Component } from '@angular/core';

@Component({
  selector: 'app-citamed-footer',
  templateUrl: './citamed-footer.component.html',
  styleUrls: ['./citamed-footer.component.scss'],
})
/** CitamedFooter component*/
export class CitamedFooterComponent {
  /** CitamedFooter ctor */
  CurrentYear: number = new Date().getFullYear();
  constructor() {}
}
