import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterContentInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DateSelectArg, FullCalendarComponent } from '@fullcalendar/angular';
import interactionPlugin from '@fullcalendar/interaction'; // for dateClick
import * as introJs from 'intro.js/intro.js';
import * as moment from 'moment';
import { AdminViewAsClinicDTO } from '../../dtos/AdminViewAsClinicDTO';
import {
  IAppointmentListItem,
  HomeViewModel,
  INoCallDate,
  ISummaryCallType,
  system_message,
  IStatusSummary,
} from '../../DTOS/Appointments/AppointmentListItem';
import { CallStatusGET_DTO } from '../../dtos/CallStatus/DTOS';
import { Doctor } from '../../dtos/Doctors/DoctorDTO';
import {
  AddAppointmentDialogData,
  AddAppointmentsDialogComponent,
} from '../../_dialogs/add-appointments-dialog/add-appointments-dialog.component';
import {
  AppointmentDetailsDialogComponent,
  AppointmentDetailsDialogData,
  AppointmentDetails,
} from '../../_dialogs/appointment-details-dialog/appointment-details-dialog.component';
import {
  ReportsViewerComponent,
  reportsViewerData,
} from '../../_dialogs/reports-viewer/reports-viewer.component';
import {
  UpdateAppointmentCallStatusDialogComponent,
  UpdateAppointmentCallStatusDialogData,
} from '../../_dialogs/update-appointment-call-status/update-appointment-call-status.component';
import {
  UpdateAppointmentDialogComponent,
  UpdateAppointmentDialogData,
} from '../../_dialogs/update-appointment/update-appointment.component';
import { User } from '../../_models/user';
import { AuthenticationService } from '../../_services/authentication.service';
import { BalanceDueComponent } from '../../_dialogs/balance-due/balance-due.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSelect } from '@angular/material/select';
import { ApiService } from 'src/app/api.service';
import { ClinicAppointmentType } from 'src/app/DTOS/Appointments-types/ClinicAppointmentType';
import * as _ from 'lodash';
import { PermissionSlugsService } from 'src/app/Services/PermissionSlugsService';
import PermissionSlugs from 'src/app/Constants/PermissionSlugs';
import { ClinicDoctorAssistanceStatusDTO } from 'src/app/DTOS/AssistanceStatus';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterUserViewAsSelectorComponent } from 'src/app/Components/master-user-view-as-selector/master-user-view-as-selector.component';
import esLocale from '@fullcalendar/core/locales/es';
import enLocale from '@fullcalendar/core/locales/en-au';
import { CalendarOptions } from '@fullcalendar/web-component';
import dayGridPlugin from '@fullcalendar/daygrid';
import { Subscription } from 'rxjs';
import { RUN_TUTORIAL_SESSION_KEY } from 'src/app/app-component/app.component';
import { debounce } from 'lodash';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from 'src/app/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class HomeComponent implements AfterContentInit, OnInit {
  appointmentSummarySubscription: Subscription;
  statusSummary: IStatusSummary[];
  summaryCallTypes: ISummaryCallType[];
  isLoadingSummaryCallType: boolean;
  LoadStatusSummariesSuscription: Subscription;
  GetAppointmentSummaryCallTypesSuscription: Subscription;
  constructor(
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private API: ApiService,
    private permissionSlugsService: PermissionSlugsService,
    @Inject('BASE_URL') baseUrl: string,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.baseUrl = baseUrl;
    moment.locale('es');
  }

  public showAppointmentAssistance: boolean = false;
  public pageSize: number = 15;
  public currentPage: number = 1;

  public hasV2Home() {
    const v2Flag = JSON.parse(localStorage.getItem('v2flag'));
    return v2Flag;
  }
  locales = [esLocale, enLocale];
  ngAfterContentInit(): void {
    this.authenticationService.currentUser.subscribe((User) => {
      this.User = User;
      this.clinicNameTitle = `${User.clinicId} - ${User.clinicName} (${User.companyId})`;

      setTimeout(() => {
        this.route.params.subscribe((c) => {
          const clinicID: number = Number(c.clinicID);
          if (clinicID) {
            this.API.GetClinics(null, clinicID).subscribe((clinicsRes) => {
              const selectedClinic = clinicsRes.find(
                (cc) => cc.clinicId == clinicID
              );
              this.onViewAsClinicIdChange(selectedClinic);
              this.viewAsSelector.selectValue(selectedClinic);
              this.ActivateView();
            });
          } else {
            this.ActivateView();
          }
        });
      }, 300);

      this.API.HasTwoWayTextingEnabled(this.User.clinicId).subscribe(
        (r: { hasTwoWayTextingEnabled: boolean }) => {
          this.has2wayTxtEnabled = r.hasTwoWayTextingEnabled;
        }
      );
    });
  }

  public selected_appointmentTypeId: number;
  baseUrl: string;
  ClinicAppointmentTypes: ClinicAppointmentType[] = [];
  public clinicAppointmentType: ClinicAppointmentType;
  public clinicDoctorAssistanceStatusDictionary: any;
  ngOnInit() {
    this.paginator._intl.itemsPerPageLabel = 'Citas por página';
    this.paginator._intl.nextPageLabel = 'Siguiente';
    this.paginator._intl.previousPageLabel = 'Anterior';
  }

  @ViewChild('sms_panel') chatMenuTrigger: MatMenuTrigger;
  @ViewChild('doctorsFilter') doctorsFilterSelect: MatSelect;
  @ViewChild('statusFilter') estatusFilter: MatSelect;
  @ViewChild('viewAsSelector')
  viewAsSelector: MasterUserViewAsSelectorComponent;
  public has2wayTxtEnabled: Boolean = false;
  public view: HomeViewModel = {} as HomeViewModel;
  introJS = introJs();
  public chatAppointment: IAppointmentListItem = null;
  public selectedDoctorsFilter = new UntypedFormControl();
  public selectedStatusFilter = new UntypedFormControl();
  public isLinear: boolean = true;
  public isPreloading: boolean = true;
  expandedElement: IAppointmentListItem | null;
  public NoCallDates: INoCallDate[];
  public selectedSummaryCallType: ISummaryCallType;
  public selectedSummaryCallTypeId: number;
  public appointmentsTotal: number;
  public selectedSummaryCallTypeTabIndex: number = 0;
  public selectedDate: Date = new Date();
  public doctors: Doctor[] = new Array();
  public dateTitle: string = '';
  public lastSyncDate: string = '';
  public lastSyncForDate: string = '';
  public viewIsLoadingAppointments: boolean = true;
  public ViewAs: AdminViewAsClinicDTO;
  public AppointmentListItems: IAppointmentListItem[];
  public User: User;
  public showSmsColumn: boolean = false;
  public IsActivatedView: boolean = false;
  public sys_message_has_been_shown: boolean = false;
  public ShowRecordColumn: boolean = false;
  public callStatuses: CallStatusGET_DTO[];
  public allCallStatuses: CallStatusGET_DTO[];
  private calendar_api: any;
  public clinicNameTitle: string = '';
  selected = new UntypedFormControl(0);
  calendarPlugins = [dayGridPlugin]; // important!
  displayedColumns: string[] = [];
  assistanceData: ClinicDoctorAssistanceStatusDTO[] = [];

  dataSource: MatTableDataSource<IAppointmentListItem>;
  loaderDataSource = new MatTableDataSource<IAppointmentListItem>(
    Array.from(Array(10).keys()).map(() => ({} as any))
  );
  // references the #calendar in the template
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  appointmentsSearchTerm: string = '';
  public isLoadingDoctors: boolean = false;

  ngAfterViewInit() {
    this.getClinicAssistanceStatusesConfig();
    this.permissionSlugsService.fetch();
    this.permissionSlugsService.permissionsListener.subscribe((r) => {
      this.showAppointmentAssistance = r.includes(
        PermissionSlugs.APPOINTMENT_ASSISTANCE
      );
    });
    if (!sessionStorage.getItem('introJsFlag')) {
      this.snackBaTutorial();
      sessionStorage.setItem('introJsFlag', 'introJsFlag');
    }
  }

  public openChat(appointment: IAppointmentListItem) {
    this.chatMenuTrigger.openMenu();
    this.chatAppointment = appointment;
  }

  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
    this.router.navigate(['/admin-view-as/' + this.ViewAs.clinicId]);

    this.clinicNameTitle = `${this.ViewAs.clinicId} - ${this.ViewAs.clinicName} (${this.ViewAs.companyId})`;
  }

  public LoadClinicAppointmentTypes() {
    this.API.GetClinicAppointmentTypes(
      this.ViewAs?.clinicId || this.User.clinicId
    ).subscribe((r: ClinicAppointmentType[]) => {
      this.ClinicAppointmentTypes = r;
    });
  }

  public FetchDoctors() {
    this.isLoadingDoctors = true;
    let clinicid = this.User.clinicId;
    if (this.ViewAs) clinicid = this.ViewAs.clinicId;

    this.API.GetAllDoctors(clinicid).subscribe(
      (result) => {
        var res = result as Doctor[];
        this.doctors = res;
        this.isLoadingDoctors = false;
      },
      (error) => console.error(error)
    );
  }

  handleCalendarDateClick(args: DateSelectArg) {
    this.selectedDate = args.start;
    const calendarApi = args.view.calendar;
    calendarApi.removeAllEvents();
    calendarApi.addEvent({
      start: args.start,
      allDay: true,
    });
    this.LoadAppointments();
  }

  DownloadAppointmentsReport(ExportType: string) {
    let userID = this.User.userId;
    if (this.ViewAs) userID = this.ViewAs.viewAsUserID;

    let link =
      this.baseUrl +
      `Reports/AppointmentsReport?StartDate=${moment(this.selectedDate).format(
        'YYYY-MM-DD'
      )}&EndTime=${moment(this.selectedDate).format('YYYY-MM-DD')}&CallTypeId=${
        this.selectedSummaryCallType.callTypeId
      }&ExportType=${ExportType}&userID=${userID}`;

    if (ExportType === 'PDF') {
      let data: reportsViewerData = {
        link: link,
      };
      this.dialog
        .open(ReportsViewerComponent, {
          data: data,
          width: '80%',
          disableClose: true,
        })
        .afterClosed()
        .subscribe((dialogResult) => {
          let result = dialogResult as boolean;
          if (result) this.LoadAppointments();
        });
    } else {
      window.open(link, '_blank');
    }
  }

  onCallTypesTabChange(TabChangeEvent: any) {
    let selected_summaryCallType = this.summaryCallTypes[TabChangeEvent.index];
    this.selectedSummaryCallType = selected_summaryCallType;
    this.selectedSummaryCallTypeId = selected_summaryCallType.callTypeId;
    this.currentPage = 0;
    this.pageSize = 30;
    if (this.IsActivatedView) {
      this.LoadAppointments();
    }
  }

  UpdateAppointmetCallStatus(apointment: IAppointmentListItem) {
    let data: UpdateAppointmentCallStatusDialogData = {
      appointment: apointment,
      callStatusID: apointment.callStatusId,
      callTypeId: this.view.callTypeId,
      clinicID: this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId,
      callStatuses: this.callStatuses,
    };
    this.dialog
      .open(UpdateAppointmentCallStatusDialogComponent, {
        data: data,
        width: '35%',
        disableClose: true,
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        let result = dialogResult as boolean;
        if (result) this.LoadAppointments();
      });
  }

  OpenAppointmentAddDialog() {
    let data: AddAppointmentDialogData = {
      clinicID: this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId,
      UserId: this.ViewAs ? this.ViewAs.viewAsUserID : this.User.userId,
      ClinicName: this.ViewAs ? this.ViewAs.clinicName : this.User.clinicName,
    };
    this.dialog
      .open(AddAppointmentsDialogComponent, {
        data: data,
        maxHeight: '200.rem',
        maxWidth: '200.rem',
        hasBackdrop: true,
        disableClose: true,
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        let result = dialogResult as boolean;
        if (result) {
          this.LoadAppointments();
        }
      });
  }

  OpenUpdateAppointmetDialog(apointment: IAppointmentListItem) {
    let data: UpdateAppointmentDialogData = {
      appointment: apointment,
      clinicID: this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId,
      callStatuses: this.allCallStatuses,
    };
    this.dialog
      .open(UpdateAppointmentDialogComponent, {
        data: data,
        width: '35%',
        disableClose: true,
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        let result = dialogResult as boolean;
        if (result) this.LoadAppointments();
      });
  }

  public OpenAppointmentsDetailDialog(apointment: IAppointmentListItem) {
    let data: AppointmentDetailsDialogData = { appointmentData: apointment };
    this.dialog
      .open(AppointmentDetailsDialogComponent, {
        data: data,
        width: '40%',
        disableClose: true,
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        let result = dialogResult as boolean;
        if (result) this.LoadAppointments();
      });
  }

  public PauseConfirmations() {
    const clinicId = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    const date = moment(this.selectedDate).format('YYYY-MM-DD');

    this.API.NO_CALL_DATE_PauseAllForClinic(clinicId, date).subscribe(() =>
      this.LoadAppointments()
    );
  }

  public ResumeConfirmations() {
    const clinicId = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    const date = moment(this.selectedDate).format('YYYY-MM-DD');

    this.API.ResumeAllAppointmentsForClinic(clinicId, date).subscribe(() =>
      this.LoadAppointments()
    );
  }

  public LoadDetail(apointment: IAppointmentListItem) {
    apointment._isLoadingDetails = true;
    this.API.GetAppointmentDetails(
      apointment.clinicId,
      apointment.appointmentId
    ).subscribe(
      (result) => {
        var res = result as AppointmentDetails;

        res.details.forEach((e) => {
          e._formatedDate = moment(e.callTime).format(
            'DD MMM [del] YYYY[,] hh:mm A'
          );
        });

        apointment.CallHistory = res.details;

        apointment._callDate = moment(res.attempts.callDate).format(
          'DD MMMM [del] YYYY'
        );

        apointment.timing = res.attempts.timings.filter((e) => {
          if (
            apointment.CallHistory.map((eee) => eee.attempt).indexOf(
              e.callOrder
            ) > -1
          )
            return false;
          else return true;
        });

        apointment.timing.forEach((t) => {
          t.callTimeUnformatted = t.callTime;
          t.callTime = moment(
            '2020-01-01 ' + t.callTime,
            'YYYY-MM-DD HH:mm:ss.SSSS'
          ).format('hh:mm a');
        });
        apointment._isLoadingDetails = false;
      },
      () => {}
    );
  }

  public GetLastSyncDate(userid: number) {
    this.API.GetLastSyncDate(userid).subscribe(
      (res: {
        lastSyncDate: null | undefined | Date;
        appointmentDate: null | undefined | Date;
      }) => {
        if (res.lastSyncDate) {
          this.lastSyncDate = moment(res.lastSyncDate)
            .locale('es')
            .format('DD MMM YYYY');
          this.lastSyncForDate = moment(res.appointmentDate)
            .locale('es')
            .format('DD MMM YYYY');
        } else {
          this.lastSyncDate = null;
          this.lastSyncForDate = null;
        }
      }
    );
  }

  public GetSystemMessage(userid: number) {
    this.API.GetSystemMessage(userid).subscribe(
      (res: { system_Message: system_message }) => {
        if (res.system_Message && !this.sys_message_has_been_shown) {
          let config: MatDialogConfig = {
            minWidth: '100%',
            disableClose: true,
            panelClass: 'due_balance_dialog_container',
            data: res.system_Message,
          };
          this.dialog
            .open(BalanceDueComponent, config)
            .afterClosed()
            .subscribe(() => {
              this.dialog.closeAll();
              this.sys_message_has_been_shown = true;

              this.API.CreateNewViewLogSystemMessage(
                this.User.userId,
                res.system_Message.systemAnnouncementId
              );
            });
        }
      }
    );
  }

  public clearSelectedDoctorsFilter() {
    this.doctorsFilterSelect.options.forEach((o) => o.deselect());
    this.dataSource = new MatTableDataSource<IAppointmentListItem>(
      this.AppointmentListItems
    );
    this.dataSource.sort = this.sort;
  }

  public debouncedRefetchAppointmentsList = debounce(
    () => this.refetchAppointmentsList(),
    1500,
    {}
  );

  public refetchAppointmentsList() {
    let { $date, CallTypeId, viewAsUserID, clinicId } =
      this.getLoadAppointmentsArguments();

    this.LoadAppointmentsSummary($date, CallTypeId, viewAsUserID, clinicId);
  }

  public clearStatusFilter() {
    this.estatusFilter.options.forEach((o) => o.deselect());
    this.dataSource = new MatTableDataSource<IAppointmentListItem>(
      this.AppointmentListItems
    );
  }

  LoadAppointments() {
    let { $date, CallTypeId, viewAsUserID, clinicId } =
      this.getLoadAppointmentsArguments();

    this.LoadAppointmentSummaryCallTypes(
      $date,
      CallTypeId,
      viewAsUserID,
      clinicId
    );
  }

  private getLoadAppointmentsArguments() {
    this.viewIsLoadingAppointments = true;
    let CallTypeId = 1; // default = confirmaciones;

    try {
      if (this.selectedSummaryCallType)
        CallTypeId = this.selectedSummaryCallType.callTypeId;
    } catch (e) {
      CallTypeId = 1; // default = confirmaciones;
    }
    let $date = moment(this.selectedDate).format('YYYY-MM-DD');
    let viewAsUserID = this.User.userId;
    let clinicId = this.User.clinicId;
    if (this.ViewAs) {
      viewAsUserID = this.ViewAs.viewAsUserID;
      clinicId = this.ViewAs.clinicId;
    }
    return { $date, CallTypeId, viewAsUserID, clinicId };
  }

  private LoadAppointmentsSummary(
    $date: string,
    CallTypeId: number,
    viewAsUserID: number,
    clinicId: number
  ) {
    if (
      this.appointmentSummarySubscription &&
      !this.appointmentSummarySubscription.closed
    ) {
      this.appointmentSummarySubscription.unsubscribe();
    }

    const selectedDoctorID =
      this.selectedDoctorsFilter?.value?.doctorId &&
      Number(this.selectedDoctorsFilter?.value?.doctorId);

    const selectedCallStatus =
      this.selectedStatusFilter?.value?.callStatusId &&
      String(this.selectedStatusFilter?.value?.callStatusId);

    this.appointmentSummarySubscription = this.API.GetAppointmentsSummary(
      $date,
      CallTypeId,
      viewAsUserID,
      this.selected_appointmentTypeId,
      this.currentPage,
      this.pageSize,
      clinicId,
      'AppointmentDate',
      'ASC',
      selectedDoctorID,
      this.appointmentsSearchTerm,
      selectedCallStatus
    ).subscribe(
      (result) => {
        var res = result as HomeViewModel;
        this.IsActivatedView = true;

        this.NoCallDates = res.nocallDates;

        const _appointmentsMapped = res.appointments.map((e, index) => {
          const lastCallDate = e.lastCall
            ? moment(e.lastCall).locale('es').format('DD MMMM YYYY')
            : null;

          const lastCallHour = e.lastCall
            ? moment(e.lastCall).locale('es').format('hh:mm A')
            : null;

          e._relativeDate = e.lastCall
            ? moment(e.lastCall).locale('es').fromNow()
            : null;

          return {
            ...e,
            _appointmentDateUnformatted: moment(e.appointmentDate).toDate(),
            _lastCallUnformatted: e.lastCall
              ? moment(e.lastCall).toDate()
              : null,
            appointmentDate: moment(e.appointmentDate).format('hh:mm A'),
            lastCall: e.lastCall
              ? moment(e.lastCall).locale('es').format('DD MMM YYYY hh:mm A')
              : null,
            is_gray: index % 2 == 0,
            lastCallDate,
            lastCallHour,
            ...(e as any).callScheduleData,
          };
        });

        if (res.appointments.some((c) => c.unreadSmsResponsesCount > 0)) {
          const unreadApts = _appointmentsMapped.filter(
            (aa) => aa.unreadSmsResponsesCount > 0
          );

          const nonUnreadApts = _appointmentsMapped.filter(
            (aa) => !aa.unreadSmsResponsesCount
          );

          res.appointments = [
            ..._.orderBy(unreadApts, ['unreadSmsResponsesCount'], 'desc'),
            ...nonUnreadApts,
          ];
        } else {
          res.appointments = _appointmentsMapped;
        }

        this.dateTitle = moment($date)
          .locale('es')
          .format('dddd DD [de] MMMM [del] YYYY');

        this.view = res;

        this.AppointmentListItems = res.appointments;

        this.dataSource = new MatTableDataSource<IAppointmentListItem>(
          this.AppointmentListItems
        );

        this.dataSource.sort = this.sort;
        this.viewIsLoadingAppointments = false;
        this.showSmsColumn = this.view.appointments.some((e) => e.smsResponse);
        this.ShowRecordColumn = this.view.appointments.some(
          (e) => e.patientRecord
        );
        this.isPreloading = false;
        this.SetCalendarSize();
        this.SetTutorialSteps();
      },
      (error) => console.error(error)
    );
  }

  private LoadStatusSummaries(
    $date: string,
    CallTypeId: number,
    viewAsUserID: number
  ) {
    if (
      this.LoadStatusSummariesSuscription &&
      !this.LoadStatusSummariesSuscription.closed
    ) {
      this.LoadStatusSummariesSuscription.unsubscribe();
    }

    this.LoadStatusSummariesSuscription = this.API.GetAppointmentsStatusSummary(
      $date,
      CallTypeId,
      viewAsUserID,
      this.selected_appointmentTypeId
    ).subscribe((res) => {
      this.statusSummary = res.statusSummary;
      this.callStatuses = res.statusSummary.map((s) => {
        return {
          callStatusId: s.callStatusId,
          callStatusName: s.callStatusName,
          smallIconFilename: s.smallIconFilename,
        };
      });
      this.appointmentsTotal = this.statusSummary
        .map((e) => e.statusCount)
        .reduce((a, b) => a + b, 0);
    });
  }

  private LoadAppointmentSummaryCallTypes(
    $date: string,
    CallTypeId: number,
    viewAsUserID: number,
    clinicId: number
  ) {
    if (
      this.GetAppointmentSummaryCallTypesSuscription &&
      !this.GetAppointmentSummaryCallTypesSuscription.closed
    ) {
      this.GetAppointmentSummaryCallTypesSuscription.unsubscribe();
    }
    this.isLoadingSummaryCallType = true;
    this.GetAppointmentSummaryCallTypesSuscription =
      this.API.GetAppointmentSummaryCallTypes(
        $date,
        CallTypeId,
        viewAsUserID,
        this.selected_appointmentTypeId
      ).subscribe((res) => {
        this.summaryCallTypes = res.summaryCallTypes;
        this.selectedSummaryCallType = res.summaryCallTypes.find(
          (c) => c.callTypeId == CallTypeId
        );

        if (res.summaryCallTypes.length && !this.selectedSummaryCallType) {
          this.selectedSummaryCallType = res.summaryCallTypes[0];
          this.selectedSummaryCallTypeId =
            this.selectedSummaryCallType.callTypeId;
        } else {
          if (this.selectedSummaryCallTypeId) {
            this.selectedSummaryCallTypeTabIndex =
              this.summaryCallTypes.indexOf(this.selectedSummaryCallType);
          }

          let selected_summaryCallType = this.summaryCallTypes.filter(
            (eee) => eee.callTypeId == CallTypeId
          )[0];

          selected_summaryCallType = selected_summaryCallType || {
            callTypeId: 1,
            callTypeName: 'Confirmation',
            callTypeAlias: 'Confirmación',
            description: 'Ver resumen de estatus de llamadas de confirmación.',
          };

          this.selectedSummaryCallType = selected_summaryCallType;
          this.selectedSummaryCallTypeId = selected_summaryCallType.callTypeId;
        }

        this.isLoadingSummaryCallType = false;

        this.LoadStatusSummaries(
          $date,
          this.selectedSummaryCallType.callTypeId,
          viewAsUserID
        );
        this.LoadAppointmentsSummary(
          $date,
          this.selectedSummaryCallType.callTypeId,
          viewAsUserID,
          clinicId
        );
      });
  }
  public handlePage(e: PageEvent) {
    this.currentPage = e.pageIndex + 1;
    this.pageSize = e.pageSize;
    let { $date, CallTypeId, viewAsUserID, clinicId } =
      this.getLoadAppointmentsArguments();

    this.LoadAppointmentsSummary($date, CallTypeId, viewAsUserID, clinicId);
  }
  getDisplayedColumns() {
    let columns = [
      'patientName',
      'Telefono',
      'reminderSetName',
      'appointmentDate',
      this.showSmsColumn ? 'smsResponse' : null,
      'callStatusName',
      this.showAppointmentAssistance ? 'assistanceStatusId' : null,
      'Acciones',
    ].filter((e) => e);

    return columns;
  }

  LoadCallStatuses() {
    let clinicID = this.User.clinicId;
    if (this.ViewAs) clinicID = this.ViewAs.clinicId;
    this.API.GetAllCallStatus(clinicID).subscribe((result) => {
      this.allCallStatuses = result as CallStatusGET_DTO[];
    });
  }

  StartDemo() {
    this.introJS.start();
  }

  calendarOptions: CalendarOptions = {
    editable: true,
    selectable: true,
    select: this.handleCalendarDateClick.bind(this),
    height: '380px',
    locale: 'es',
    locales: this.locales,
    plugins: [dayGridPlugin, interactionPlugin],
  };

  SetCalendarComponent() {
    let calendarApi = this.calendarComponent.getApi();
    this.calendar_api = calendarApi;
    this.calendar_api.setOption('aspectRatio', 1);
    this.calendar_api.select(this.selectedDate);

    this.calendarComponent.getApi().setOption('selectable', true); // = true;
    this.SetCalendarSize();
  }

  public SetCalendarSize() {}

  SetTutorialSteps() {
    const stepsWhenTheUserHasAppointments = [
      {
        element: '#step4',
        intro:
          'Aquí puedes filtar por el tipo de llamada (Confirmacion de Citas, llamada especial, Recordatorios etc...)',
        position: 'right',
      },
      {
        element: '#step5',
        intro:
          'Si necesitas descargar el reporte de citas, haz clic en este botón y selecciona el formato deseado',
        position: 'right',
      },
      {
        element: '#intro-step-search-appointment',
        intro:
          'Si quieres buscar una cita en especifico puedes filtrarla en este campo, utilizando el nombre del paciente, su teléfono, número de record o nombre del doctor asignado a la cita.',
        position: 'right',
      },
      {
        element: '#intro-step-filter-provider',
        intro:
          'Puedes filtrar las citas por el libro de citas (proveedor/doctor).',
        position: 'right',
      },
      {
        element: '#intro-step-filter-estatus',
        intro:
          'Puedes filtrar las citas por el estatus de la cita (confirmadas, canceladas...)',
        position: 'right',
      },
      {
        element: '#step11',
        intro: 'Haz clic aquí para añadir una nueva cita.',
        position: 'right',
      },
      {
        element: '#intro-step-refresh',
        intro: 'Haz clic aquí para volver a cargar el listado de citas.',
        position: 'right',
      },
      {
        element: '#intro-step-tutorial',
        intro:
          'Puedes volver a correr este tutorial, haciendo clic en este botón.',
        position: 'right',
      },

      {
        element: '#step6',
        intro:
          'Aquí podrás ver el listado de pacientes para fecha y tipo de llamada seleccionadas.',
        position: 'right',
      },
      {
        element: '#step8',
        intro:
          'Haz clic en el icono del estatus en esta columna para editar el estatus de la cita (confirmarla/cancelarla, etc...)',
        position: 'right',
      },
      {
        element: '#step9',
        intro: 'Haz clic en el lápiz de una cita para editarla.',
        position: 'right',
      },
      {
        element: '#intro-step-details',
        intro:
          'Para ver el detalle de intentos, haz clic en este botón para expandir el detalle.',
        position: 'right',
      },
      {
        element: '#step10',
        intro:
          'Haz clic en la cabecera de la columna para organizar las citas por ellas.',
        position: 'right',
      },
    ];
    const stepsWhenTheUserDOESNOTHaveAppointments = [
      {
        element: '#intro-step-no-appointments-add-new-button',
        intro:
          'Como no tienes citas agendadas, puedes registrar una nueva cita haciendo clic en este botón',
        position: 'bottom',
      },
      {
        element: '#intro-step-no-appointments-refresh-button',
        intro: 'Puedes volver a cargar el listado, haciendo clic en este botón',
        position: 'bottom',
      },
      {
        element: '#intro-step-no-appointments-nocalldate-button',
        intro:
          'Con este botón, puedes controlar si las citas son enviadas o no para el día seleccionado.',
        position: 'bottom',
      },
      {
        element: '#v2-go-back-button',
        intro:
          'Si quieres volver a la versión anterior de citamed, puedes hacer clic en este botón.',
        position: 'bottom',
      },
    ];

    const additionalSteps = this.AppointmentListItems?.length
      ? stepsWhenTheUserHasAppointments
      : stepsWhenTheUserDOESNOTHaveAppointments;

    this.introJS.setOptions({
      steps: [
        {
          element: '#navbarLogo',
          intro:
            '¡Citamed tiene una nueva imagen! más amigable y fácil de usar, adaptable a laptops, tablets y móviles.',
          position: 'bottom',
        },
        {
          element: '#intro-step-sidebar',
          intro:
            'Puedes acceder a las funcionalidades del sistema desde nuestro nuevo menú',
          position: 'bottom',
        },
        {
          element: '#intro-step-confirmation-ribbon',
          intro:
            'Para navegar en los listados de día de confirmaciones, puedes utilizar esta barra de navegación.',
          position: 'right',
        },
        {
          element: '#date-selection-ribbon-prevBtn',
          intro: 'Haz click aquí, para ir al día anterior.',
          position: 'right',
        },
        {
          element: '#date-selection-ribbon-date-calendar',
          intro:
            'Aquí mostramos el día actual, si haces clic acá, se mostrará un calendario en el cual podrás navegar a un día especifico.',
          position: 'right',
        },
        {
          element: '#date-selection-ribbon-nextBtn',
          intro: 'Haz clic aquí, para ir al día siguiente.',
          position: 'right',
        },
        ...additionalSteps,
        {
          element: '.chat',
          intro:
            'Recuerda, si necesitas ayuda puedes escribirnos en el recuadro verde al fondo de la pantalla llamado "Soporte Técnico".',
          position: 'bottom',
        },
      ],
      doneLabel: 'Listo',
      skipLabel: 'Saltar',
      prevLabel: 'Atrás',
      nextLabel: 'Siguiente',
      showProgress: true,
    });

    if (window.sessionStorage.getItem(RUN_TUTORIAL_SESSION_KEY) === 'true') {
      window.sessionStorage.removeItem(RUN_TUTORIAL_SESSION_KEY);
      setTimeout(() => {
        this.StartDemo();
      }, 3000);
    }
  }
  ActivateView() {
    this.viewIsLoadingAppointments = true;

    if (this.calendarComponent) {
      this.SetCalendarComponent();
    }

    this.LoadAppointments();
    this.LoadCallStatuses();

    this.viewIsLoadingAppointments = false;
    this.FetchDoctors();
    this.LoadClinicAppointmentTypes();

    let viewAsUserID = this.User.userId;
    if (this.ViewAs) viewAsUserID = this.ViewAs.viewAsUserID;

    this.GetLastSyncDate(viewAsUserID);
    this.GetSystemMessage(viewAsUserID);
  }
  snackBaTutorial() {
    let snackBar = this._snackBar.open(
      '¡Bienvenido a Citamed!, haz click en este botón para aprender a utilizar el sistema',
      'Ver tutorial ',
      {
        duration: 5000,
        horizontalPosition: 'left',
        verticalPosition: 'bottom',
        panelClass: 'success-dialog',
      }
    );

    snackBar.onAction().subscribe(() => {
      this.StartDemo();
    });
  }

  public deleteAppointment(appointmentId: number) {
    let confirmed = window.confirm(
      '¿Estás segur@ de querer eliminar esta entrada?'
    );
    if (confirmed) {
      this.isPreloading = true;

      try {
        this.API.DeleteAppointmentCallSchedule(
          appointmentId,
          this.selectedSummaryCallType.callTypeId
        ).subscribe(() => {
          this.isPreloading = false;
          this.LoadAppointments();
        });
      } catch (e) {
        this.isPreloading = false;
        window.alert('Ha ocurrido un error.');
      }
    }
  }

  public getClinicAssistanceStatusesConfig() {
    let clinicID = this.User.clinicId;
    if (this.ViewAs) clinicID = this.ViewAs.clinicId;

    this.API.GetClinicAssistanceConfig(clinicID).subscribe(
      (data: ClinicDoctorAssistanceStatusDTO[]) => {
        this.assistanceData = data;
        const dictionary = {};

        data.forEach(
          (d) => (dictionary[`${d.assistanceStatusID}-${d.doctorId}`] = d)
        );

        this.clinicDoctorAssistanceStatusDictionary = dictionary;
      }
    );
  }

  public openJson(jsonString: string) {
    this.dialog.open(ConfirmDialogComponent, {
      data: new ConfirmDialogModel(
        'PROVIDER API RESPONSE',
        null,
        null,
        'cerrar',
        '',
        `<pre>${JSON.stringify(JSON.parse(jsonString), null, 2)}</pre>`
      ),
    });
  }
  public updateAppointmentAssistanceStatus(
    appointment: IAppointmentListItem,
    statusId: number
  ) {
    const onError = () => {
      alert('An error has occurred.');
    };
    this.API.UpdateAppointmentAssistanceStatus(
      statusId,
      appointment.appointmentId,
      appointment.clinicId
    ).subscribe(() => {
      const message = this.assistanceData.find(
        (c) =>
          c.doctorId == appointment.doctorId && c.assistanceStatusID == statusId
      );
      if (message.messageTemplate?.length) {
        this.API.SendSms({
          AppointmentId: appointment.appointmentId,
          ToNumber: appointment.patientPhoneNumber,
          MessageContent: message.messageTemplate,
        }).subscribe(() => {
          this._snackBar.open(
            `Mensaje de texto enviado a ${appointment.patientName} con el contenido "${message.messageTemplate}"`,
            null,
            {
              duration: 8000,
              horizontalPosition: 'left',
              verticalPosition: 'bottom',
              panelClass: 'success-dialog',
            }
          );
        }, onError);
      }
    }, onError);
  }

  lastSync() {
    if (this.lastSyncDate) {
      return `Fecha de Última sincronización: ${this.lastSyncDate} para las citas del ${this.lastSyncForDate}.`;
    } else {
      return `Fecha de Última sincronización: NA.`;
    }
  }
}
