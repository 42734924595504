export interface UserGetDTO {
  emailAddress: string;
  loginname: string;
  username: string;
  isActive: boolean;
  clinicId: number;
  userId: number;
  mustResetPassword: boolean;
  sendAppointmentsReport: boolean;
}
export class UserCreateDTO {
  emailAddress: string;
  loginname: string;
  username: string;
  isAdmin: boolean;
  clinicId: number;
  password: string;
  repeatPassword: string;
  isActive: boolean;
  userId: number | null;
  sendAppointmentsReport: boolean;
}
export class UserEditDTO {
  clinicID: number;
  username: string;
  loginname: string;
  emailAddress: string;
  newPassword: string;
  newPasswordRepeat: string;
  isActive: boolean;
  userID: number;
  mustResetPassword: boolean;
  sendAppointmentsReport: boolean;
}
export class PasswordChangeDTO {
  clinicID: number;
  userID: number;
  newPassword: string;
  oldPassword: string;
  identifier: string;
}
