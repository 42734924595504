import { AfterViewInit, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/api.service';
import { User } from '../../_models/user';
import { AuthenticationService } from '../../_services/authentication.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from '../../confirm-dialog/confirm-dialog.component';
import { AdminViewAsClinicDTO } from '../../dtos/AdminViewAsClinicDTO';
import { Doctor } from '../../dtos/Doctors/DoctorDTO';
import {
  ReminderSetDto,
  ReminderSetReminderSet,
} from '../../dtos/ReminderSets/ReminderSetDto';
import {
  LoadingDialogComponent,
  LoadingDialogModel,
} from '../../loading-dialog/loading-dialog.component';
@Component({
  selector: 'app-reminderset-reminderset',
  templateUrl: './reminderset-reminderset.component.html',
  styleUrls: ['./reminderset-reminderset.component.scss'],
})
export class RemindersetRemindersetComponent implements AfterViewInit {
  constructor(
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private API: ApiService
  ) {}
  public ViewAs: AdminViewAsClinicDTO;
  public User: User;
  public isLoading: boolean;
  selectedInitialReminderSet: ReminderSetDto;
  selectedFinalReminderSet: ReminderSetDto;
  loadingRef: MatDialogRef<LoadingDialogComponent>;

  public ReminderSets: ReminderSetDto[] = new Array<ReminderSetDto>();
  public remsets_remsets: ReminderSetReminderSet[];
  public doctors: Doctor[];
  public selectedDoctor: Doctor;
  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
    this.loadAll();
  }
  public loadAll() {
    this.LoadReminderSetReminderSet();
    this.FetchReminderSets();
    this.FetchDoctors();
  }

  ngAfterViewInit() {
    this.User = this.authenticationService.currentUserValue;
    this.loadAll();
  }

  public LoadReminderSetReminderSet() {
    let clinicId = this.User.clinicId;
    if (this.ViewAs) {
      clinicId = this.ViewAs.clinicId;
    }

    this.API.GetReminderSetReminderSet(clinicId).subscribe((result) => {
      const r = result as ReminderSetReminderSet[];
      this.remsets_remsets = r;
    });
  }
  public FetchDoctors() {
    let clinicId = this.User.clinicId;
    if (this.ViewAs) {
      clinicId = this.ViewAs.clinicId;
    }

    this.API.GetAllDoctors(clinicId).subscribe(
      (result) => {
        const res = result as Doctor[];
        this.doctors = res;
      },
      (error) => {
        console.error(error);
      }
    );
  }
  public FetchReminderSets() {
    let clinicId = this.User.clinicId;
    if (this.ViewAs) {
      clinicId = this.ViewAs.clinicId;
    }

    this.API.GetAllReminderSet(clinicId).subscribe(
      (result) => {
        const res = result as ReminderSetDto[];
        this.ReminderSets = res;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  public deleteReminderSetReminderSet(item: ReminderSetReminderSet) {
    const alertConfirm = window.confirm(
      '¿Estás segur@ de querer eliminar este registro?'
    );
    if (alertConfirm) {
      this.ShowLoading();

      this.API.DeleteReminderSetReminderSet(
        item.reminderSetReminderSetId
      ).subscribe(
        () => {
          this.hideLoading();
          this.LoadReminderSetReminderSet();
          this.dialog.open(ConfirmDialogComponent, {
            maxWidth: '600px',
            data: new ConfirmDialogModel(
              'Listo',
              'Se ha eliminado correctamente.',
              'Okay',
              null
            ),
          });
        },
        (error) => {
          console.error(error);
          this.hideLoading();
          this.showError(error);
        }
      );
    }
  }

  public SaveChanges() {
    try {
      const alertConfirm = window.confirm(
        '¿Estás segur@ de querer crear este registro?'
      );
      if (alertConfirm) {
        this.ShowLoading();
        let clinicId = this.User.clinicId;
        if (this.ViewAs) {
          clinicId = this.ViewAs.clinicId;
        }
        const dto = {
          clinicId,
          finalReminderSetId: this.selectedFinalReminderSet.reminderSetId,
          InitialReminderID: this.selectedInitialReminderSet.reminderSetId,
          DoctorID: this.selectedDoctor.doctorId,
        };

        this.API.CreateReminderSetReminderSet(dto).subscribe(
          () => {
            this.hideLoading();
            this.dialog.open(ConfirmDialogComponent, {
              maxWidth: '600px',
              data: new ConfirmDialogModel(
                'Listo',
                'Se ha registrado correctamente.',
                'Okay',
                null
              ),
            });
            this.LoadReminderSetReminderSet();
            this.clearCreationForm();
          },
          (error) => {
            console.error(error);
            this.showError(error);
          }
        );
      }
    } catch (e) {
      this.showError(e);
      this.hideLoading();
    }
  }
  public hideLoading() {
    if (this.loadingRef) {
      this.loadingRef.close();
    }
    this.isLoading = false;
  }
  public ShowLoading() {
    const loading_dialog = this.dialog.open(LoadingDialogComponent, {
      maxWidth: '400px',
      data: new LoadingDialogModel('Cargando', '', false),
    });
    loading_dialog.disableClose = true;
    this.loadingRef = loading_dialog;
    this.isLoading = true;
  }
  private showError(error: any) {
    console.error('CITAMED ERROR', error);
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: new ConfirmDialogModel('Ha ocurrido un error', '', 'Okay', null),
    });
  }
  public clearCreationForm() {
    const alertConfirm = window.confirm(
      '¿Estás segur@ de querer limpiar el formulario?'
    );
    if (alertConfirm) {
      this.selectedDoctor = null;
      this.selectedFinalReminderSet = null;
      this.selectedInitialReminderSet = null;
    }
  }
}
