import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bookings-new-booking',
  templateUrl: './bookings-new-booking.component.html',
  styleUrls: ['./bookings-new-booking.component.scss'],
})
export class BookingsNewBookingComponent {
  constructor() {}
}
