import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { User } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ApiService } from 'src/app/api.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from 'src/app/confirm-dialog/confirm-dialog.component';
import { AdminViewAsClinicDTO } from 'src/app/dtos/AdminViewAsClinicDTO';
import {
  Gender,
  PatientDetail,
  PatientList,
} from 'src/app/DTOS/Patient/Patient-Detail';
import {
  LoadingDialogComponent,
  LoadingDialogModel,
} from 'src/app/loading-dialog/loading-dialog.component';

@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.scss'],
})
export class PatientListComponent implements OnInit {
  isLoading = false;
  @Input() public isStandalone: boolean;
  @Input() public ViewAs: AdminViewAsClinicDTO;
  public User: User;
  dataSource: MatTableDataSource<PatientDetail>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  loadingRef: MatDialogRef<LoadingDialogComponent>;
  displayedColumns: string[] = [
    'patientRecordId',
    'patientFirstName',
    'patientSecondName',
    'patientFirstLastName',
    'patientSecondLastName',
    'patientRecordNumber',
    'patientGender',
    'Edit',
  ];
  public pageSize = 10;
  public currentPage = 1;
  public clinicId: number;
  searchTerm = '';
  PatientList: PatientList;
  listItems: PatientDetail[];
  public totalSize = 0;
  public PatientGender: Gender;
  public patientInsurancePlan = '';

  constructor(
    private authenticationService: AuthenticationService,
    private API: ApiService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe((user) => {
      this.User = user;
    });
    this.LoadPatients();
  }

  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
    this.LoadPatients();
  }

  public LoadPatients() {
    const clinicID = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    this.clinicId = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    this.currentPage = this.currentPage === 0 ? 1 : this.currentPage;
    if (this.isStandalone && !this.ViewAs?.clinicId) {
      return;
    }
    // this.ShowLoading();
    this.API.GetPatients(
      this.currentPage,
      this.pageSize,
      clinicID,
      this.searchTerm
    ).subscribe(
      (r) => {
        const res = r as PatientList;
        this.dataSource = new MatTableDataSource<PatientDetail>(res.items);
        this.PatientList = res;
        this.dataSource.paginator = this.paginator;
        this.totalSize = this.PatientList.totalCount;
        this.pageSize = this.PatientList.pageSize;
        this.currentPage = this.PatientList.pageIndex;
        this.listItems = this.PatientList.items;
        this.isLoading = false;
        // this.hideLoading();
      },
      (error) => {
        console.error(error);
        this.hideLoading();
        this.showError(error);
      }
    );
  }

  public ShowLoading() {
    const loading_dialog = this.dialog.open(LoadingDialogComponent, {
      maxWidth: '400px',
      data: new LoadingDialogModel('Cargando', '', false),
    });
    loading_dialog.disableClose = true;
    this.loadingRef = loading_dialog;
  }

  public hideLoading() {
    if (this.loadingRef) {
      this.loadingRef.close();
    }
  }

  private showError(error: any) {
    console.error('CITAMED ERROR', error);
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: new ConfirmDialogModel('Ha ocurrido un error', '', 'Okay', null),
    });
  }

  public convertPatientGender(gender: Gender) {
    switch (gender) {
      case 1:
        return 'M';
      case 2:
        return 'F';
      case 3:
        return 'Other';
    }
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex + 1;
    this.pageSize = e.pageSize;
    this.LoadPatients();
  }
}
