export class WaitingListPatient {
  patientId: number;
  clinicId: number;
  doctorId: number;
  createdByUserId: number;
  internalIdentifier: string;
  patientName: string;
  patientPhoneNumber: string;
  patientEmailAddress: string;
  patientAltPhoneNumber: string;
  patientRecord: string;
  otherInfo: string;
  createDate: string;
  appointmentDate: string;
  deleteDate: string | null;
  listOrder: number | null;
  appointmentId: number | null;
  boardColumnId: number;
  appointmentTime: any;
}
export interface _Map<T> {
  [K: number]: T;
}
