import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { ApiService } from 'src/app/api.service';
import {
  CallAgainSchedule,
  Doctor,
} from 'src/app/DTOS/Call-Again-Schedules/CallAgainDTO';
import { CallStatusGET_DTO } from 'src/app/dtos/CallStatus/DTOS';
import { CalltimingSet } from 'src/app/DTOS/CalltimingSet/CallTimingSetGetDTO';
import { CallType } from 'src/app/DTOS/CallType/CallType';
import { ReminderSetDto } from 'src/app/dtos/ReminderSets/ReminderSetDto';
import { User } from '../../_models/user';
import { AuthenticationService } from '../../_services/authentication.service';

import { AdminViewAsClinicDTO } from '../../dtos/AdminViewAsClinicDTO';

@Component({
  selector: 'app-call-again-schedules',
  templateUrl: './call-again-schedules.component.html',
  styleUrls: ['./call-again-schedules.component.scss'],
})
export class CallAgainSchedulesComponent implements AfterViewInit {
  httpClient: HttpClient;
  baseUrl: string;
  public User: User;

  public schedules: CallAgainSchedule[];
  isLoading: boolean;
  callTypes: CallType[];
  ViewAs: any;
  doctors: Doctor[];
  callTimingSets: CalltimingSet[];
  ReminderSets: ReminderSetDto[];
  callStatuses: CallStatusGET_DTO[];
  constructor(
    http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private API: ApiService
  ) {
    this.httpClient = http;
    this.baseUrl = baseUrl;
  }
  ngAfterViewInit() {
    this.authenticationService.currentUser.subscribe((u) => {
      this.User = u;
      this.LoadAll();
    });
  }

  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
    this.LoadAll();
  }

  LoadAll() {
    this.FetchReminderSets();
    this.FetchDoctors();
    this.fetchSchedules();
    this.LoadCallTimings();
    this.FetchCallTypes();
    this.LoadCallStatuses();
  }
  fetchSchedules() {
    const clinicid = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    this.API.GetClinicCallAgainSchedules(clinicid).subscribe((r) => {
      this.schedules = (r as CallAgainSchedule[]).map((rr) => ({
        ...rr,
        callStatusId: rr.callStatusId.toLowerCase(),
      }));
    });
  }
  public tConvert(_time) {
    // Check correct time format and split into components
    let time = _time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [_time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  public getCallTimings(callTimingSetId: number) {
    return this.callTimingSets.find(
      (c) => c.callTimingSetId === callTimingSetId
    )?.timings;
  }
  sortbyStringKey(k: string) {}

  public LoadCallTimings() {
    const ClinicID = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    this.API.GetAllCalltimings(ClinicID).subscribe((r: CalltimingSet[]) => {
      this.callTimingSets = r;
    });
  }

  public LoadCallStatuses() {
    this.httpClient
      .get<CallStatusGET_DTO[]>(this.baseUrl + 'CallStatus/Get')
      .subscribe((result) => {
        this.callStatuses = (result as CallStatusGET_DTO[]).map((r) => ({
          ...r,
          callStatusId: r.callStatusId.toLowerCase(),
        }));
      });
  }

  public FetchCallTypes() {
    this.isLoading = true;

    this.API.GetAllCallTypes().subscribe(
      (result) => {
        const res = result as CallType[];
        this.callTypes = res;
        this.isLoading = false;
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
      }
    );
  }

  public FetchDoctors() {
    this.isLoading = true;
    const ClinicID = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;

    this.API.FetchDoctors(ClinicID).subscribe(
      (result) => {
        const res = result as Doctor[];
        this.doctors = res;
        this.isLoading = false;
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
      }
    );
  }
  public FetchReminderSets() {
    this.isLoading = true;
    const ClinicID = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;

    this.API.GetAllReminderSet(ClinicID).subscribe(
      (result) => {
        const res = result as ReminderSetDto[];
        this.ReminderSets = res;
        this.isLoading = false;
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
      }
    );
  }
}
