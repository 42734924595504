import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { ApiService } from '../api.service';

@Injectable()
export class PermissionSlugsService {
  permissionsListener: Subject<string[]> = new Subject<string[]>();
  loaderListener: Subject<boolean> = new Subject<boolean>();

  constructor(private API: ApiService) {}
  permissionsListChange(data: string[]) {
    this.permissionsListener.next(data);
  }
  fetch() {
    this.loaderListener.next(true);
    this.API.GetUserPermissions().subscribe((r: string[]) => {
      this.permissionsListener.next(r);
      this.loaderListener.next(false);
    });
  }
}
