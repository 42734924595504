import { AfterViewInit, Input, ViewChild } from '@angular/core';
import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import {
  AddAppointmentDialogData,
  AddAppointmentsDialogComponent,
} from '../../_dialogs/add-appointments-dialog/add-appointments-dialog.component';
import { User } from '../../_models/user';
import { AuthenticationService } from '../../_services';
import { ApiService } from '../../api.service';
import { AdminViewAsClinicDTO } from '../../dtos/AdminViewAsClinicDTO';
import {
  AppointmentsRequestdDTO,
  AppointmentsRequestResultDTO,
} from '../../DTOS/Appointments/AppointmentsRequest';

@Component({
  selector: 'app-appointments-request-list',
  templateUrl: './appointments-request-list.html',
  styleUrls: ['./appointments-request-list.scss'],
})
export class AppointmentsRequestListComponent implements AfterViewInit {
  User: User;
  isLoading = false;
  isPlayingAudio = false;
  searchTearm = '';
  public displayedColumns = ['', '', '', '', ''];
  public dataSource: any;
  public pageSize = 10;
  public currentPage = 1;
  public totalSize = 0;
  AppointmentsRequest: AppointmentsRequestResultDTO;
  listItems: AppointmentsRequestdDTO[];
  from: Date = new Date();
  to: Date = new Date();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private API: ApiService,
    private _snackBar: MatSnackBar
  ) {}

  @Input() public ViewAs: AdminViewAsClinicDTO;
  @Input() public isStandalone: boolean;
  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
  }

  ngAfterViewInit() {
    this.User = this.authenticationService.currentUserValue;
  }

  public async LoadAppointmentsRequest() {
    this.isLoading = true;
    if (this.isStandalone && !this.ViewAs?.clinicId) {
      return;
    }
    const clinicID = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    const toFormat = moment(this.to).format('YYYY-MM-DD');
    const fromFormat = moment(this.from).format('YYYY-MM-DD');
    this.currentPage = this.currentPage === 0 ? 1 : this.currentPage;
    this.API.GetAppointmentsRequest(
      clinicID,
      this.currentPage,
      this.pageSize,
      toFormat,
      fromFormat,
      this.searchTearm
    ).subscribe(
      (result) => {
        const res = result as AppointmentsRequestResultDTO;
        this.dataSource = new MatTableDataSource<AppointmentsRequestdDTO>(
          res.items
        );
        this.dataSource.paginator = this.paginator;
        this.AppointmentsRequest = res;
        this.totalSize = this.AppointmentsRequest.totalCount;
        this.pageSize = this.AppointmentsRequest.pageSize;
        this.currentPage = this.AppointmentsRequest.pageIndex;
        this.listItems = this.AppointmentsRequest.items;

        for (let i = 0; i < this.listItems.length; i++) {
          this.listItems[i].createDate = moment(
            this.listItems[i].createDate
          ).format('DD MMMM [del] YYYY');
          this.listItems[i].appointmentDateTime = moment(
            this.listItems[i].createDate
          ).format('DD MMMM [del] YYYY');
        }

        if (this.totalSize > 0) {
          this.iterator();
        }
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
      }
    );
  }

  getStatusColor(status) {
    switch (status) {
      case 1:
        return 'green';
      case 2:
        return 'blue';
      case 3:
        return 'red';
    }
  }
  OpenAppointmentAddDialog() {
    const data: AddAppointmentDialogData = {
      clinicID: this.User.clinicId,
      UserId: this.User.userId,
      ClinicName: this.User.clinicName,
    };
    this.dialog
      .open(AddAppointmentsDialogComponent, {
        data,
        width: '35%',
        disableClose: true,
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        const result = dialogResult as boolean;
        if (result) {
          const snackBar = this._snackBar.open(
            '¡Listo! La cita ha sido añadida correctamente.',
            'ok ',
            {
              duration: 8000,
              horizontalPosition: 'left',
              verticalPosition: 'bottom',
              panelClass: 'success-dialog',
            }
          );
        }
      });
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.LoadAppointmentsRequest();
  }
  private iterator() {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    const part = this.listItems.slice(start, end);
    this.dataSource = part;
  }

  public clearFilter() {
    this.searchTearm = '';
    this.from = new Date();
    this.to = new Date();
  }
}
