import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ApiService } from 'src/app/api.service';
import AppointmentTypeDto from 'src/app/DTOS/Appointments-types/appointmentType';

@Component({
  selector: 'app-appointment-types',
  templateUrl: './appointment-types.component.html',
  styleUrls: ['./appointment-types.component.scss'],
})
export class AppointmentTypesComponent implements OnInit {
  constructor(private API: ApiService) {}

  public list_AppointmentTypeDto: AppointmentTypeDto[];
  public sidepanelAppointmentType: AppointmentTypeDto = {} as any;
  public slidePanelOpen: Boolean = false;
  public isSubmitting: Boolean = false;

  ngOnInit() {
    this.LoadAppointmentTypes();
  }

  public save() {
    const onDone = () => {
      this.LoadAppointmentTypes();
      this.closeAddNewSidePanel();
    };

    if (this.sidepanelAppointmentType.appointmentTypeId) {
      this.API.UpdateAppointmentType(this.sidepanelAppointmentType).subscribe(
        onDone
      );
    } else {
      this.API.CreateAppointmentType(this.sidepanelAppointmentType).subscribe(
        onDone
      );
    }
  }
  public copy(obj: any) {
    return _.cloneDeep(obj);
  }

  public LoadAppointmentTypes() {
    this.API.GetAppointmentTypes().subscribe((r) => {
      this.list_AppointmentTypeDto = r as AppointmentTypeDto[];
    });
  }
  public OpenAddNewSidePanel() {
    this.slidePanelOpen = true;
  }
  public closeAddNewSidePanel() {
    this.sidepanelAppointmentType = {} as any;
    this.slidePanelOpen = false;
  }
}
