import { Component } from '@angular/core';

@Component({
  selector: 'app-system-messages',
  templateUrl: './system-messages.component.html',
  styleUrls: ['./system-messages.component.scss'],
})
export class SystemMessagesComponent {
  constructor() {}
}
