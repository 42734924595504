import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from 'src/app/api.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from 'src/app/confirm-dialog/confirm-dialog.component';
import { AdminViewAsClinicDTO } from 'src/app/dtos/AdminViewAsClinicDTO';
import {
  BookingAppointments,
  BookingAppointmentsData,
  BookingAppointmentsList,
  GetBookingStatus,
} from 'src/app/DTOS/Bookings/BookingAppointmentsDTO';
import { BookingModel } from 'src/app/DTOS/Bookings/BookingDTO';
import { BookingService } from 'src/app/DTOS/Bookings/BookingServiceDTO';
import { Doctor } from 'src/app/Dtos/Doctors/DoctorDTO';
import {
  LoadingDialogComponent,
  LoadingDialogModel,
} from 'src/app/loading-dialog/loading-dialog.component';
import { BookingsDialogComponent } from 'src/app/_dialogs/bookings-dialog/bookings-dialog.component';
import { User } from 'src/app/_models';
import { AuthenticationService } from 'src/app/_services';
import * as _ from 'lodash';
@Component({
  selector: 'app-bookings-appointments-table',
  templateUrl: './bookings-appointments-table.component.html',
  styleUrls: ['./bookings-appointments-table.component.scss'],
})
export class BookingsAppointmentsTableComponent implements OnInit, OnChanges {
  @Input() public ViewAs: AdminViewAsClinicDTO;
  @Input() public from: Date;
  @Input() public to: Date;
  @Input() public patientRecordId: number;
  @Input() public bookingServiceId: number;
  @Input() public bookingStatusId: number;
  @Input() public doctorId: number;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public selectedDoctorsFilter = new UntypedFormControl();
  public selectedStatusFilter = new UntypedFormControl();
  public selectedServicesFilter = new UntypedFormControl();
  public User: User;
  public pageSize = 10;
  public currentPage = 1;
  public totalSize = 0;
  public clinicId: number;
  public services: BookingService[] = new Array();
  public statuses: GetBookingStatus[] = new Array();
  public bookingStatus: GetBookingStatus[];
  public doctors: Doctor[] = new Array();
  public isLoadingServices: boolean = false;
  public isLoadingDoctors: boolean = false;
  public isLoadingStatuses: boolean = false;
  isLoading: boolean = false;
  dataSource: MatTableDataSource<BookingAppointmentsData>;
  loadingRef: MatDialogRef<LoadingDialogComponent>;
  BookingAppointmentsList: BookingAppointmentsList;
  listItems: BookingAppointments[];
  ServiceListItems: BookingAppointmentsData[];
  slideEditPanelOpen: Boolean = false;
  sidepanelBooking: BookingModel = {} as any;
  displayedColumns: string[] = [
    'appointmentDate',
    'appointmentDuration',
    'doctorName',
    'serviceName',
    'bookingStatus',
    'notes',
    'Edit',
  ];

  constructor(
    private authenticationService: AuthenticationService,
    private _snackBar: MatSnackBar,
    private API: ApiService,
    public dialog: MatDialog
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.patientRecordId.currentValue) {
      this.patientRecordId = changes.patientRecordId.currentValue;
      this.loadData();
    }
  }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe((user) => {
      this.User = user;
    });
  }

  public async loadData() {
    await Promise.all([
      this.FetchDoctors(),
      this.FetchServices(),
      this.FetchStatuses(),
    ]);
    this.LoadAppointments();
  }

  public LoadAppointments() {
    this.clinicId = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    this.currentPage = this.currentPage === 0 ? 1 : this.currentPage;
    let toDay = new Date();
    this.from = _.isEmpty(this.from) == true ? new Date() : this.from;
    this.to =
      _.isEmpty(this.from) == true
        ? new Date(toDay.setDate(toDay.getDate() + 6))
        : this.to;
    const selectedStatusesIds = this.selectedStatusFilter?.value?.map(
      (st: GetBookingStatus) => st.bookingStatusId
    );
    const selectedDoctorsIds = this.selectedDoctorsFilter.value?.map(
      (d: Doctor) => d.doctorId
    );
    const selectedServicesIds = this.selectedServicesFilter.value?.map(
      (sv: BookingService) => sv.bookingServiceId
    );

    this.API.GetBookingAppointments(
      this.clinicId,
      this.currentPage,
      this.pageSize,
      [this.patientRecordId],
      this.from,
      this.to,
      selectedServicesIds,
      selectedStatusesIds,
      selectedDoctorsIds
    ).then(
      (r) => {
        const data = r.items.map((appointment) => {
          const apt: BookingAppointmentsData = {
            ...appointment,
            doctor: this.doctors.find(
              (d) => d.doctorId === appointment.doctorId
            ),
            service: this.services.find(
              (s) => s.bookingServiceId === appointment.bookingServiceId
            ),
            status: this.statuses.find(
              (st) => st.bookingStatusId === appointment.bookingStatusId
            ),
          };
          return apt;
        });
        this.dataSource = new MatTableDataSource<BookingAppointmentsData>(data);
        this.BookingAppointmentsList = r;
        this.dataSource.paginator = this.paginator;
        this.totalSize = this.BookingAppointmentsList.totalCount;
        this.pageSize = this.BookingAppointmentsList.pageSize;
        this.currentPage = this.BookingAppointmentsList.pageIndex;
        this.listItems = this.BookingAppointmentsList.items;
        this.isLoading = false;
      },
      (error) => {
        console.error(error);
        this.hideLoading();
        this.showError(error);
      }
    );
  }

  public FetchDoctors() {
    this.isLoadingDoctors = true;
    let clinicid = this.User.clinicId;
    if (this.ViewAs) clinicid = this.ViewAs.clinicId;
    return new Promise((resolve, reject) => {
      this.API.GetAllDoctors(clinicid).subscribe(
        (result) => {
          const res = result as Doctor[];
          this.doctors = res;
          this.isLoadingDoctors = false;
          resolve(res);
        },
        (error) => {
          console.error(error);
          reject();
        }
      );
    });
  }

  public FetchStatuses() {
    this.isLoadingStatuses = true;
    let clinicid = this.User.clinicId;
    if (this.ViewAs) clinicid = this.ViewAs.clinicId;
    return new Promise((resolve, reject) => {
      this.API.GetBookingStatuses().subscribe(
        (result) => {
          const res = result as GetBookingStatus[];
          this.statuses = res;
          this.isLoadingStatuses = false;
          resolve(res);
        },
        (error) => {
          console.error(error);
          reject();
        }
      );
    });
  }

  public FetchServices() {
    this.isLoadingServices = true;
    let clinicid = this.User.clinicId;
    if (this.ViewAs) clinicid = this.ViewAs.clinicId;
    return new Promise((resolve, reject) => {
      this.API.GetBookingServices(clinicid).subscribe(
        (result) => {
          var res = result as BookingService[];
          this.services = res;
          this.isLoadingServices = false;
          resolve(res);
        },
        (error) => {
          console.error(error);
          reject();
        }
      );
    });
  }

  public ShowLoading() {
    const loading_dialog = this.dialog.open(LoadingDialogComponent, {
      maxWidth: '400px',
      data: new LoadingDialogModel('Cargando', '', false),
    });
    loading_dialog.disableClose = true;
    this.loadingRef = loading_dialog;
  }

  public hideLoading() {
    if (this.loadingRef) this.loadingRef.close();
  }

  private showError(error: any) {
    console.error('CITAMED ERROR', error);
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: new ConfirmDialogModel('Ha ocurrido un error', '', 'Okay', null),
    });
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex + 1;
    this.pageSize = e.pageSize;
    this.LoadAppointments();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  OpenImportAppointmentDialog() {
    this.dialog
      .open(BookingsDialogComponent, {
        maxWidth: '39.5rem',
        panelClass: 'full-with-dialog',
        disableClose: false,
        hasBackdrop: true,
        autoFocus: true,
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        const result = dialogResult as boolean;
        if (result) {
          this._snackBar.open(
            '¡Listo! La cita ha sido agendada correctamente.',
            'ok ',
            {
              duration: 8000,
              horizontalPosition: 'left',
              verticalPosition: 'bottom',
              panelClass: 'success-dialog',
            }
          );
          this.LoadAppointments();
        }
      });
  }

  public openAddNewEditSidePanel(booking: BookingModel) {
    this.slideEditPanelOpen = true;
    this.sidepanelBooking = booking as BookingModel;
  }

  public closeAddNewEditSidePanel(booking: BookingModel) {
    this.slideEditPanelOpen = false;
    this.sidepanelBooking = booking as BookingModel;
    this.LoadAppointments();
  }
}
