import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { AuthenticationService } from '../_services';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private _snackBar: MatSnackBar
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          if (!window.localStorage['hasShownExpiredMessage']) {
            window.localStorage['hasShownExpiredMessage'] = true;
            alert('Tu sesión ha expirado.');
          }
          // auto logout if 401 response returned from api
          this.authenticationService.logout();
          window.localStorage['hasShownExpiredMessage'] = false;

          location.reload();
        }
        const error = err.error || err.statusText;
        return throwError(error);
      })
    );
  }
}
