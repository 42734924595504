import { AfterViewInit, ViewChild } from '@angular/core';
import { Inject } from '@angular/core';
import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {
  PermisionDialogData,
  PermissionDialogComponent,
} from '../../_dialogs/permission-dialog/permission-dialog.component';
import { User } from '../../_models/user';
import { AuthenticationService } from '../../_services';
import { ApiService } from '../../api.service';
import { AdminViewAsClinicDTO } from '../../dtos/AdminViewAsClinicDTO';
import { PermissionDetailDTO } from '../../DTOS/Permission/PermissionDTO';
import { LoadingDialogComponent } from '../../loading-dialog/loading-dialog.component';

@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.scss'],
})
export class PermissionComponent implements AfterViewInit {
  isLoading: boolean;
  public showPermissionActive: boolean;

  loadingRef: MatDialogRef<LoadingDialogComponent>;
  Permissions: PermissionDetailDTO[];
  baseUrl: string;
  public User: User;
  dataSource: MatTableDataSource<PermissionDetailDTO>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns: string[] = [
    'permissionId',
    'slug',
    'permissionName',
    'description',
    'IsActive',
    'Actions',
  ];
  searchTearm: string;

  constructor(
    private API: ApiService,
    @Inject('BASE_URL') baseUrl: string,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog
  ) {
    this.baseUrl = baseUrl;
  }
  public ViewAs: AdminViewAsClinicDTO;

  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
  }
  ngAfterViewInit() {
    this.User = this.authenticationService.currentUserValue;
    this.showPermissionActive = true;
    this.FetchPermissions(this.showPermissionActive);
  }
  OpenPermisssionDialog(permission: PermissionDetailDTO) {
    if (permission !== null) {
      permission.IsActive = permission.deleteDate === null ? true : false;
    }
    const data: PermisionDialogData = {
      permission,
      title: permission ? 'Editar Permiso' : 'Añadir Permiso',
    };

    this.dialog
      .open(PermissionDialogComponent, {
        data,
        width: '50%',
        height: '400px',
        disableClose: true,
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        const result = dialogResult as boolean;
        if (result) {
          this.FetchPermissions(this.showPermissionActive);
        }
      });
  }

  public FetchPermissions(showPermissionActive: boolean) {
    this.isLoading = true;
    this.API.GetAllPermissions(!showPermissionActive).subscribe(
      (result) => {
        const res = result as PermissionDetailDTO[];
        this.Permissions = res;
        this.dataSource = new MatTableDataSource<PermissionDetailDTO>(res);
        this.dataSource.paginator = this.paginator;
        this.isLoading = false;
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
      }
    );
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onToggle(ev) {
    this.showPermissionActive = ev.checked;
    this.FetchPermissions(ev.checked);
  }
}
