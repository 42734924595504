import { HttpClient } from '@angular/common/http';
import {
  Component,
  Inject,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmationService } from 'primeng/api';
import { User } from 'src/app/_models';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ApiService } from 'src/app/api.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from 'src/app/confirm-dialog/confirm-dialog.component';
import { AdminViewAsClinicDTO } from 'src/app/dtos/AdminViewAsClinicDTO';
import { BookingService } from 'src/app/DTOS/Bookings/BookingServiceDTO';
import { ClinicDetails } from 'src/app/DTOS/Clinic/clinic-details';
import {
  LoadingDialogComponent,
  LoadingDialogModel,
} from 'src/app/loading-dialog/loading-dialog.component';
import { PermissionSlugsService } from 'src/app/Services/PermissionSlugsService';

@Component({
  selector: 'app-bookings-configuration',
  templateUrl: './bookings-configuration.component.html',
  styleUrls: ['./bookings-configuration.component.scss'],
})
export class BookingsConfigurationComponent implements OnInit, OnChanges {
  isLoading = false;
  @Input() public isStandalone: boolean;
  @Input() public ViewAs: AdminViewAsClinicDTO;
  public bookingService: BookingService;
  public User: User;
  public clinicId: number;
  public clinic: ClinicDetails = {} as any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  loadingRef: MatDialogRef<LoadingDialogComponent>;
  dataSource: MatTableDataSource<BookingService>;
  displayedColumns: string[] = [
    'bookingServiceId',
    'bookingServiceName',
    'bookingServiceDescription',
    'serviceDurationInMinutes',
    'clinicId',
    'Edit',
  ];

  // Variables de Side Panel
  sidePanelBookingService: BookingService = {} as any;
  sidePanelBookingServiceBackup: BookingService = {} as any;
  slidePanelOpen: Boolean = false;
  slideEditPanelOpen: Boolean = false;
  isSubmitting: Boolean = false;
  public permissions: string[] = [];

  constructor(
    private permissionSlugsService: PermissionSlugsService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private API: ApiService,
    private _snackBar: MatSnackBar
  ) {
    if (this.sidePanelBookingService) {
      this.setData(this.sidePanelBookingService);
    }
    this.permissionSlugsService.fetch();
    this.permissionSlugsService.permissionsListener.subscribe((r) => {
      this.permissions = r;
    });
    this.authenticationService.currentUser.subscribe((u) => (this.User = u));
  }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe((user) => {
      this.User = user;
      this.LoadBookingServices();
    });
  }
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  ngOnChanges(): void {
    this.LoadBookingServices();
  }

  public setData(service: BookingService) {
    this.sidePanelBookingService = {
      bookingServiceId: service.bookingServiceId,
      bookingServiceName: service.bookingServiceName,
      bookingServiceDescription: service.bookingServiceDescription,
      serviceDurationInMinutes: service.serviceDurationInMinutes,
      clinicId: service.clinicId,
    } as BookingService;
  }

  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
    this.LoadBookingServices();
  }

  LoadBookingServices() {
    this.clinicId = this.ViewAs ? this.ViewAs.clinicId : this.User.clinicId;
    this.ShowLoading();
    this.API.GetBookingServices(this.clinicId).subscribe(
      (r) => {
        this.dataSource = new MatTableDataSource<BookingService>(r);
        this.dataSource.paginator = this.paginator;
        this.isLoading = false;
        this.hideLoading();
      },
      (error) => {
        console.error(error);
        this.hideLoading();
        this.showError(error);
      }
    );
  }

  public ShowLoading() {
    const loading_dialog = this.dialog.open(LoadingDialogComponent, {
      maxWidth: '400px',
      data: new LoadingDialogModel('Cargando', '', false),
    });
    loading_dialog.disableClose = true;
    this.loadingRef = loading_dialog;
  }

  public hideLoading() {
    if (this.loadingRef) {
      this.loadingRef.close();
    }
  }

  private showError(error: any) {
    console.error('CITAMED ERROR', error);
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: new ConfirmDialogModel('Ha ocurrido un error', '', 'Okay', null),
    });
  }

  public truncateString(stringVal: string) {
    if (!stringVal) {
      return;
    } else if (stringVal.length > 200) {
      const truncate = stringVal.slice(0, 200).concat('...');
      return truncate;
    } else {
      return stringVal;
    }
  }

  public openAddNewSidePanel() {
    this.slidePanelOpen = true;
    this.sidePanelBookingService = {} as any;
  }

  public openAddNewEditSidePanel(service: BookingService) {
    this.slideEditPanelOpen = true;
    this.sidePanelBookingService = service as BookingService;
  }

  public closeAddNewSidePanel() {
    this.slidePanelOpen = false;
    this.sidePanelBookingService = {} as any;
  }

  public closeAddNewEditSidePanel() {
    this.slideEditPanelOpen = false;
    this.sidePanelBookingService = {} as BookingService;
  }

  public saveService(service: BookingService) {
    service.clinicId = this.clinicId;
    this.isSubmitting = true;
    this.API.CreateBookingService(service).subscribe(() => {
      this.LoadBookingServices();
      this.closeAddNewSidePanel();
      this.isSubmitting = false;
      this._snackBar.open(
        `¡Listo!, se ha creado el servicio ${service.bookingServiceName}.`,
        'OK ',
        {
          duration: 10000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
          panelClass: 'success-dialog',
        }
      );
    });
    this.closeAddNewSidePanel();
  }

  public async updateService(service: BookingService) {
    this.API.UpdateBookingService(service).subscribe(
      () => {
        this._snackBar.open(
          `¡Listo!, se ha actualizado el servicio ${service.bookingServiceName}.`,
          'OK ',
          {
            duration: 10000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: 'success-dialog',
          }
        );
      },
      (f) => {
        this.isLoading = false;
        window.alert('Lo sentimos, ha ocurrido un error.');
        console.error('ERROR AL actualizar el servicio', f);
      }
    );
    this.closeAddNewEditSidePanel();
  }

  public deleteService(service: BookingService) {
    const confirm = window.confirm(
      `¿Estás segur@ de querer eliminar el servicio ${service.bookingServiceName}?`
    );
    if (confirm) {
      this.isSubmitting = true;
      this.API.DeleteBookingService(service).subscribe(() => {
        this.LoadBookingServices();
        this.closeAddNewSidePanel();
        this.isSubmitting = false;
        this._snackBar.open(
          `¡Listo!, se ha eliminado el servicio ${service.bookingServiceName}.`,
          'OK ',
          {
            duration: 10000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: 'success-dialog',
          }
        );
      });
    }
    this.closeAddNewEditSidePanel();
  }
}
