import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/api.service';
import { WhiteListedPhoneNumbers } from 'src/app/DTOS/Clinic/WhiteListedPhoneNumbersDTO';
import { User } from 'src/app/_models';
import { AuthenticationService } from 'src/app/_services';

export interface WhiteListedPhoneDialogData {
  whiteListedPhoneNumber: WhiteListedPhoneNumbers;
}

@Component({
  selector: 'app-clinic-new-whitelisted-phone-dialog',
  templateUrl: './clinic-new-whitelisted-phone-dialog.component.html',
  styleUrls: ['./clinic-new-whitelisted-phone-dialog.component.scss'],
})
export class ClinicNewWhitelistedPhoneDialogComponent implements AfterViewInit {
  User: User;
  public whiteListedPhoneNumber = {} as WhiteListedPhoneNumbers;
  public isLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: WhiteListedPhoneDialogData,
    public dialogRef: MatDialogRef<ClinicNewWhitelistedPhoneDialogComponent>,
    private _snackBar: MatSnackBar,
    private API: ApiService,
    private authenticationService: AuthenticationService
  ) {}

  ngAfterViewInit() {
    this.whiteListedPhoneNumber = this.data.whiteListedPhoneNumber;
    this.authenticationService.currentUser.subscribe((u) => {
      this.User = u;
    });
  }

  public close() {
    this.dialogRef.close();
  }

  public async save() {
    const confirm = window.confirm(
      `¿Estás segur@ de querer cambiar el Identificador Interno?`
    );
    if (!confirm) {
      return;
    }
    this.CreateWhiteListedPhone(this.whiteListedPhoneNumber);
  }

  public CreateWhiteListedPhone(phoneNumber: WhiteListedPhoneNumbers) {
    this.API.CreateWhiteListedPhone(phoneNumber).subscribe(
      () => {
        this.isLoading = false;
        this.dialogRef.close(true);
        this._snackBar.open('¡Listo!, se ha creado el numero.', 'OK ', {
          duration: 10000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
          panelClass: 'success-dialog',
        });
      },
      (f) => {
        this.isLoading = false;
        window.alert('Lo sentimos, ha ocurrido un error.');
        console.error('ERROR AL actualizar Identificador Interno', f);
      }
    );
  }
}
