import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  title: string;
  message: string;
  yesBtnLabel: string;
  noBtnLabel: string;
  displayMessage: string;
  htmlContent: string;
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // Update view with given values
    const modalData = data as ConfirmDialogModel;
    this.title = modalData.title;
    this.message = modalData.message;

    this.yesBtnLabel = modalData.yesBtnLabel || this.yesBtnLabel;
    this.noBtnLabel = modalData.noBtnLabel || this.noBtnLabel;
    this.displayMessage = modalData.displayMessage || '';
    this.htmlContent = modalData.htmlContent || '';
  }
  onConfirm(): void {
    this.dialogRef.close(true);
  }
  onDismiss(): void {
    this.dialogRef.close(false);
  }
}

export class ConfirmDialogModel {
  constructor(
    public title: string,
    public message: string,
    public yesBtnLabel: string,
    public noBtnLabel: string,
    public displayMessage?: string,
    public htmlContent?: string,
    public containerClassName?: string
  ) {}
}
