import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { AdminViewAsClinicDTO } from 'src/app/dtos/AdminViewAsClinicDTO';

@Component({
  selector: 'app-twilio-caller-id-activator',
  templateUrl: './twilio-caller-id-activator.component.html',
  styleUrls: ['./twilio-caller-id-activator.component.scss'],
})
export class TwilioCallerIdActivatorComponent {
  ViewAs: AdminViewAsClinicDTO;
  constructor(private API: ApiService) {}
  public phoneNumber: string;
  public friendlyName: string;
  public validationCode: string;
  public validationError: string;
  public extension: string = null;

  onViewAsClinicIdChange($event) {
    this.ViewAs = $event as AdminViewAsClinicDTO;
    this.fethClinicPhoneNumber(this.ViewAs.clinicId);
  }

  public fethClinicPhoneNumber(clinicId: number) {
    this.API.GetClinicPhoneNumber(clinicId).subscribe(
      (r: { phoneNumber: string }) => {
        this.phoneNumber = r.phoneNumber;
      }
    );
  }

  public ActivateT_CallerID() {
    this.API.ActivateT_CallerID(this.ViewAs.clinicId, this.extension).subscribe(
      (r: { success: boolean; output: any }) => {
        if (r.success) {
          this.validationCode = r.output.validationCode;
          this.friendlyName = r.output.validationCode;
        } else {
          this.validationError = r.output;
        }
      }
    );
  }
}
