import { AfterViewInit, Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';
import * as moment from 'moment';
import { AuthenticationService } from 'src/app/_services';
import { ApiService } from 'src/app/api.service';
import { User } from '../../_models/user';
import {
  AddPermissionDTO,
  PermissionDetailDTO,
} from '../../DTOS/Permission/PermissionDTO';
export interface PermisionDialogData {
  permission: PermissionDetailDTO;
  title: String;
}

@Component({
  selector: 'app-permission-dialog',
  templateUrl: './permission-dialog.component.html',
  styleUrls: ['./permission-dialog.component.scss'],
})
/** add-appointments-dialog component*/
export class PermissionDialogComponent implements AfterViewInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PermisionDialogData,
    public dialogRef: MatDialogRef<PermissionDialogComponent>,
    private _snackBar: MatSnackBar,
    @Inject('BASE_URL') baseUrl: string,
    private API: ApiService,
    private authenticationService: AuthenticationService
  ) {
    if (this.data.permission) {
      this.setData(this.data.permission);
    } else {
      this.setAsNewRecord();
    }
  }
  User: User;

  public permission: AddPermissionDTO;
  public _isActive_updt: boolean;

  isLoading = false;
  baseUrl: string;

  ngAfterViewInit() {
    this.User = this.authenticationService.currentUserValue;
  }

  public setData(permission: PermissionDetailDTO) {
    this.permission = {
      slug: permission.slug,
      permissionName: permission.permissionName,
      description: permission.description,
      isEnabledByDefault: permission.isEnabledByDefault,
      permissionId: permission.permissionId,
      IsActive: permission.IsActive,
    } as AddPermissionDTO;
  }

  public formatDate = (d: Date) =>
    moment(d).format('MMM DD, [del] YYYY HH:mm a');

  public setAsNewRecord() {
    this.permission = new AddPermissionDTO();
  }

  public close() {
    this.dialogRef.close();
  }

  public onDismiss() {
    this.dialogRef.close(false);
  }

  public async Save() {
    const confirm = window.confirm(
      `¿Estás segur@ de querer ${
        this.permission.permissionId ? 'actualizar' : 'crear'
      } este permiso?`
    );
    if (!confirm) {
      return;
    }

    if (this.permission.permissionId) {
      this.UpdatePermission(this.permission);
    } else {
      this.CreatePermission(this.permission);
    }
  }

  private CreatePermission(permission: AddPermissionDTO) {
    const permissionDTO = _.cloneDeep(permission);
    this.API.CreatePermission(permissionDTO).subscribe(
      () => {
        this.isLoading = false;
        this.dialogRef.close(true);
        this._snackBar.open('¡Listo!, se ha creado el permiso.', 'OK ', {
          duration: 10000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
          panelClass: 'success-dialog',
        });
      },
      (f) => {
        this.isLoading = false;
        window.alert('Lo sentimos, ha ocurrido un error.');
        console.error('ERROR AL CREAR PERMISO', f);
      }
    );
  }

  private UpdatePermission(_permission: AddPermissionDTO) {
    const permission = { ..._permission };

    this.API.UpdatePermission(permission).subscribe(
      () => {
        this.isLoading = false;
        this.dialogRef.close(true);
        this._snackBar.open('¡Listo!, se ha actualizado el permiso.', 'OK ', {
          duration: 10000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
          panelClass: 'success-dialog',
        });
      },
      (f) => {
        this.isLoading = false;
        window.alert('Lo sentimos, ha ocurrido un error.');
        console.error('ERROR AL actualizar permiso', f);
      }
    );
  }
}
